import { useNavigate } from "react-router-dom";
import Brand from "../brand/brand";
import SocialMediaIcons from "../social-icons/social-icons-group";
import { MdEmail } from "react-icons/md";
import { useSelector } from "react-redux";
import { useContext, useEffect } from "react";
import ScrollInto from "react-scroll-into-view";
import { GlobalState } from "../../data/Context";
import $ from "jquery";


$(document).ready(function () {
	$("#google_translate_element").bind("DOMNodeInserted", function (event) {
	  $(".goog-te-menu-value span:first").html("LANGUAGE");
	  $(".goog-te-menu-frame.skiptranslate").load(function () {
		setTimeout(function () {
		  $(".goog-te-menu-frame.skiptranslate")
			.contents()
			.find(".goog-te-menu2-item-selected .text")
			.html("LANGUAGE");
		}, 100);
	  });
	});
  });

const Footer = () => {
  const navigate = useNavigate();
  let { settings } = useSelector((state) => state);
  let { t } = useContext(GlobalState);

  return (
    <footer className="bg-mainShade mt-28 py-16 text-white relative">
      <div className="absolute bottom-0 inset-x-0">
        <p className="text-center text-white py-2">
          {t("Copyright")} &copy;{" "}
          {`${new Date().getFullYear() !== 2023 ? "2023 - " : ""}`}
          {new Date().getFullYear()} FlashReview. {t("All Rights reserved")}
        </p>
      </div>
      <div className="container mx-auto px-4">
        <div className="grid md:grid-cols-3 gap-12">
          <div className="div">
            <Brand />
            <p>{t(`slogan`)}</p>
          </div>
          <div className="flex justify-between">
            <div>
              <p className="font-bold text-lg">{t(`support`)}</p>
              <ul className="">
                <li className="text-sm font-light">
                  <ScrollInto
                    selector="#top"
                    onClick={() => navigate("/terms-and-condition")}
                  >
                    {t(`Terms and Conditions`)}
                  </ScrollInto>
                </li>
              </ul>
            </div>
          </div>
          <div className="grid gap-8 md:justify-center">
            <div>
              <p className="font-bold text-lg mb-2">{t(`contact`)}</p>
              <ul className="grid">
                <li className="text-sm font-light flex items-center gap-2">
                  <span>
                    <MdEmail />
                  </span>
                  <span>
                    {settings?.data?.contactEmail || `flashreview@outlook.com`}
                  </span>
                </li>
              </ul>
            </div>
            <div>
              <p className="font-bold text-lg">{t(`quick links`)}</p>
              <ul className="">
                <li className="text-sm font-light">
                  <SocialMediaIcons />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
