import { Link, useNavigate } from "react-router-dom";
import Input from "../components/input/input";
import { loadUser, login, setUserFail } from "../data/Reducers/UserReducer";
import { ReuseBox } from "./otp";
import { Buttons } from "../components/ModalComponents";
import axios from "axios";
import { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { returnErrors } from "../data/Reducers/ErrorReducer";
import { GlobalState } from "../data/Context";

const Login = () => {
  const navigate = useNavigate(),
    init = {
      email: "",
      password: "",
    },
    [state, setState] = useState(init),
    textChange = (e) => {
      let { name, value } = e.target;
      setState({ ...state, [name]: value });
    },
    [loading, setLoading] = useState(false),
    [submit, setSubmit] = useState(false),
    { auth } = useSelector((state) => state),
    dispatch = useDispatch();

  let { t } = useContext(GlobalState);

	useEffect(() => {
		if (auth?.isLoggedIn && submit) {
			navigate("/");
		}
	}, [auth, submit, navigate]);

	const handleSubmit = async e => {
		e?.preventDefault();
		if (!state?.email || !state?.password) return;
		setLoading(true);
		try {
			let res = await axios.post(`/api/v1/user/login`, { ...state });
			console.log({ resp: res?.data });
			toast.success(res?.data?.msg);
			dispatch(login(res?.data));
			dispatch(loadUser());
		} catch (err) {
			if (err?.response?.status === 429 || err?.response?.status === 405)
				toast.error(err?.response?.data ? err?.response?.data : err?.message);
			console.log({ err });
			let error = err.response?.data?.data;
			if (error) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			}
			dispatch(setUserFail());
		}
		setLoading(false);
		setSubmit(true);
	};
	return (
		<ReuseBox>
			<div className="max-w-md mx-auto px-4 self-center">
				<p className="capitalize text-4xl font-thin mb-4">{t(`hello`)}!</p>
				<p className="text-4xl font-extrabold mb-4">{t(`Login to continue`)}</p>
				<form onSubmit={handleSubmit}>
					{/* <!-- Email input --> */}
					<Input
						type={"email"}
						value={state?.email}
						onChange={textChange}
						name="email"
						label={t("Email")}
					/>

					{/* <!-- Password input --> */}
					<Input
						type={"password"}
						// placeholder="**********"
						label={t("password")}
						value={state?.password}
						onChange={textChange}
						name="password"
						password
					/>

					{/* <!-- Remember me checkbox --> */}
					<div class="mb-6 flex">
						{/* <!-- Forgot password link --> */}
						<Link
							to="/forgot-password"
							className="text-[#EF5DA8] ml-auto transition duration-150 ease-in-out hover:text-red-600 focus:text-red-600 active:text-red-700 dark:text-red-400 dark:hover:text-red-500 dark:focus:text-red-500 dark:active:text-red-600 text-right">
							{t(`forgot password?`)}
						</Link>
					</div>

					{/* <!-- Submit button --> */}
					<div className="space-y-4">
						<Buttons
							css="inline-block w-full rounded bg-[#65D133] px-7 pb-2.5 pt-3 text-sm font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-main hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-main focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-main active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
							data-te-ripple-init
							title={t("signin")}
							loading={loading}
							data-te-ripple-color="light"
						/>
						<Buttons
							data-te-ripple-init
							data-te-ripple-color="light"
							onClick={() => navigate("/register")}
							css="inline-block w-full rounded border border-main px-7 pb-2.5 pt-3 text-sm font-medium uppercase leading-normal text-main shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-main hover:text-white hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-main focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-main active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
							title={t("create an account")}
							type={"button"}
						/>
						<div className="flex justify-end text-sm py-2">
							<Link
								to={`/otp`}
								className="text-main text-decoration-none fw-600">
								{t(`activate account`)}
							</Link>{" "}
						</div>
						<div className="flex justify-end text-sm py-2">
							<Link
								to={`/checkout`}
								className="text-main text-decoration-none fw-600">
								{t(`subscribe`)}
							</Link>
						</div>
					</div>
				</form>
			</div>
		</ReuseBox>
	);
};

export default Login;
