import { useState } from "react";
import Button from "../../components/button/button";
import ModalContainer from "../../components/modal-container/modal-container";
import Input from "../../components/input/input";
import { MdFilterList, MdOutlineRefresh } from "react-icons/md";
import CustomerReview from "../../components/customer-review/customer-review";
// import { useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import CardGroup from "../../components/card-group/card-group";
import {
  LoadMore,
  MainPaginate,
  MainRanger,
} from "../../components/ModalComponents";
import { useEffect } from "react";
import { getReviews, resetReview } from "../../data/Reducers/ReviewsReducer";
import {
  loadContactForm,
  resetContactSearch,
} from "../../data/Reducers/CustomizeReducer";
import Profilerounded from "../../components/profile-rounded/profile-rounded";
import { useContext } from "react";
import { GlobalState } from "../../data/Context";

const Home = () => {
  const { reviews } = useSelector((state) => state);
  const [show, setShow] = useState(false),
    { t } = useContext(GlobalState);
  let tabViews = [
    {
      title: "Pending Review",
      amount: reviews?.pending_paginate?.total,
      percent: "+4%",
      icon: "",
    },
    {
      title: "Displayed Review",
      amount: reviews?.display_paginate?.total,
      percent: "+4%",
      icon: "",
    },
    {
      title: "Hiding Review",
      amount: reviews?.hide_paginate?.total,
      percent: "+4%",
      icon: "",
    },
  ];
  let [active, setActive] = useState(tabViews[0]?.title);

  const close = () => {
    setShow(false);
  };
  // const toggleModal = () => {
  // 	setShow(!show);
  // };

  return (
    <div>
      {/* <div className="flex justify-end">
				<Button
					buttonType={"primary"}
					children={"Create new"}
					onClick={toggleModal}
				/>
			</div> */}
      <div className="w-full">
        <CardGroup
          data={tabViews}
          color
          handleclick={(state) => {
            setActive(state);
          }}
        />
      </div>
      <div className="mt-8">
        {active === "Overview" && ""}
        {active === "Pending Review" && (
          <PendingReview
            state={reviews?.isFound ? reviews?.mainSearch : reviews?.pending}
            paginate={
              reviews?.isFound
                ? reviews?.search_paginate
                : reviews?.pending_paginate
            }
            title={active}
            type={"pending"}
          />
        )}
        {active === "Displayed Review" && (
          <PendingReview
            state={reviews?.isFound ? reviews?.mainSearch : reviews?.display}
            paginate={
              reviews?.isFound
                ? reviews?.search_paginate
                : reviews?.display_paginate
            }
            title={active}
            type={"display"}
          />
        )}
        {active === "Hiding Review" && (
          <PendingReview
            state={reviews?.isFound ? reviews?.mainSearch : reviews?.hide}
            paginate={
              reviews?.isFound
                ? reviews?.search_paginate
                : reviews?.hide_paginate
            }
            title={active}
            type={"hide"}
          />
        )}
      </div>

      {/* Modals */}
      <ModalContainer show={show} close={close} title={"Create new review"}>
        <form className="max-w-md mx-auto">
          <Input label={"Review Name"} />
          <div className="flex justify-center mt-8">
            <Button buttonType={"primary"} children={t("Confirm")} />
          </div>
        </form>
      </ModalContainer>
    </div>
  );
};

export default Home;

export const PendingReview = ({ state, paginate, title, type, user }) => {
  let [range, setRange] = useState(50),
    [page, setPage] = useState(1),
    [loading, setLoading] = useState(false),
    [next, setNext] = useState(false),
    dispatch = useDispatch(),
    [platforms, setPlatform] = useState(""),
    [isPlatform, setIsPlatform] = useState(false),
    [search, setSearch] = useState(""),
    { t } = useContext(GlobalState),
    { platform, auth } = useSelector((state) => state);

  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + range;

  useEffect(() => {
    if (["hide", "pending", "display", "feedback"]?.includes(type)) {
      if (paginate?.next) {
        setNext(true);
      } else setNext(false);
    }
  }, [type, paginate]);

  useEffect(() => {
    dispatch(resetContactSearch());
    dispatch(resetReview());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (search) {
      document
        .getElementById("default-search")
        .addEventListener("search", () => {
          dispatch(resetContactSearch());
          dispatch(resetReview());
        });
      let handleSubmit = async () => {
        if (!search) return;

        if (type === "feedback") {
          await dispatch(
            loadContactForm({
              search,
            })
          );
        } else {
          await dispatch(
            getReviews(type, {
              search,
              platform: platforms,
              user,
            })
          );
        }
      };
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (platforms) {
      let handleSubmit = async () => {
        if (!platforms) return;

        if (type !== "feedback") {
          await dispatch(
            getReviews(type, {
              search,
              platform: platforms,
              user,
            })
          );
        }
      };
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platforms]);

  if (!state) return;

  const currentItems = state?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(state?.length / range);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * range) % state?.length;
    setItemOffset(newOffset);
    setPage(1 + event?.selected);
  };

  let handleLoadMore = async () => {
    if (!["hide", "pending", "display", "feedback"]?.includes(type)) return;

    setLoading(true);
    if (type === "feedback") {
      await dispatch(
        loadContactForm({
          limit: Number(paginate?.nextPage * paginate?.limit),
          search,
        })
      );
    } else {
      await dispatch(
        getReviews(type, {
          limit: Number(paginate?.nextPage * paginate?.limit),
          search,
          platform: platforms,
          user,
        })
      );
    }
    setLoading(false);
  };
  return (
    <div className="pb-10">
      <div className="flex justify-between">
        <p className="text-4xl font-light mb-4">{t(title) || `Pending Review`}</p>
        <form className="w-2/3">
          <label
            htmlFor="default-search"
            className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
          >
            {t("Search")}
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
            </div>
            <input
              type="search"
              id="default-search"
              className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              // placeholder="Search Mockups, Logos..."
              required
              onChange={(e) => setSearch(e.target.value)}
              value={search}
            />
            {!search && (
              <button
                type="button"
                onClick={
                  type === "feedback" || auth?.user?.isAdmin
                    ? () => {}
                    : platforms
                    ? () => {
                        setIsPlatform(!isPlatform);
                        setPlatform("");
                        dispatch(resetContactSearch());
                        dispatch(resetReview());
                      }
                    : () => setIsPlatform(!isPlatform)
                }
                className="absolute right-2.5 bottom-2.5  focus:ring-4 focus:outline-none focus:ring-mainShade px-4 py-2"
              >
                {platforms ? (
                  <MdOutlineRefresh size={20} />
                ) : (
                  <MdFilterList size={20} />
                )}
              </button>
            )}
          </div>
          {isPlatform && (
            <div className="flex gap-3 py-3 justify-end">
              {platform?.user?.map((item, i) => (
                <div
                  className="px-3"
                  key={i}
                  onClick={() => {
                    setPlatform(item?.platform?._id);
                    setIsPlatform(!isPlatform);
                  }}
                >
                  <span className="icon myCursor">
                    <Profilerounded sm img={item?.platform?.image?.url} />
                  </span>
                </div>
              ))}
            </div>
          )}
        </form>
      </div>
      {/*  */}
      {state ? (
        <>
          <MainRanger range={range} setRange={setRange} />
          <div className="grid sm:grid-cols-1 md:grid-cols lg:grid-cols-3 gap-4 mt-12">
            {currentItems?.map((item, i) => (
              <CustomerReview
                text={t("An easy-to-use software that serves its purpose")}
                star
                key={i}
                item={item}
                type={type}
              />
            ))}
          </div>
          <MainPaginate
            handlePageClick={handlePageClick}
            pageCount={pageCount}
          />
          {page === pageCount && (
            <>
              <LoadMore
                loading={loading}
                handleLoadMore={handleLoadMore}
                next={next}
              />
            </>
          )}
        </>
      ) : (
        <div className="grid sm:grid-cols-1 md:grid-cols lg:grid-cols-3 gap-4 mt-12">
          <CustomerReview text={t("review1")} star />
          <CustomerReview text={t("review1")} star />
          <CustomerReview text={t("review1")} star />
        </div>
      )}
    </div>
  );
};

export const DisplayedReview = () => {
  const { t } = useContext(GlobalState);
  return (
    <div className="pb-10">
      <div className="flex justify-between">
        <p className="text-4xl font-light mb-4">{t("Displayed Review")}</p>
        <form className="w-2/3">
          <label
            htmlFor="default-search"
            className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
          >
            {t("Search")}
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
            </div>
            <input
              type="search"
              id="default-search"
              className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              // placeholder="Search Mockups, Logos..."
              required
            />
            <button
              type="submit"
              className="absolute right-2.5 bottom-2.5  focus:ring-4 focus:outline-none focus:ring-mainShade px-4 py-2"
            >
              <MdFilterList size={20} />
            </button>
          </div>
        </form>
      </div>
      {/*  */}
      <div className="grid sm:grid-cols-1 md:grid-cols lg:grid-cols-3 gap-4 mt-10">
        <CustomerReview text={t("review1")} star />
        <CustomerReview text={t("review1")} star />
        <CustomerReview text={t("review1")} star />
      </div>
    </div>
  );
};

export const HidingReview = () => {
  const { t } = useContext(GlobalState);
  return (
    <div className="pb-10">
      <div className="flex justify-between">
        <p className="text-4xl font-light mb-4">{t("Hiding Review")}</p>
        <form className="w-2/3">
          <label
            htmlFor="default-search"
            className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
          >
            {t("Search")}
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
            </div>
            <input
              type="search"
              id="default-search"
              className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              // placeholder="Search Mockups, Logos..."
              required
            />
            <button
              type="submit"
              className="absolute right-2.5 bottom-2.5  focus:ring-4 focus:outline-none focus:ring-mainShade px-4 py-2"
            >
              <MdFilterList size={20} />
            </button>
          </div>
        </form>
      </div>
      {/*  */}
      <div className="grid sm:grid-cols-1 md:grid-cols lg:grid-cols-3 gap-4 mt-12">
        <CustomerReview text={t("review1")} star />
        <CustomerReview text={t("review1")} star />
        <CustomerReview text={t("review1")} star />
      </div>
    </div>
  );
};
