import { useContext } from "react";
import AllSection from "../components/all-section/all-section";
import SubscriptionCard from "../components/subscription-card/subscription-card";
import { GlobalState } from "../data/Context";

const Price = () => {
  let { t } = useContext(GlobalState);

  return (
		<div>
			<div className="mt-28 container mx-auto px-4">
				<p className="text-center text-2xl md:text-4xl text-main text-shadow max-w-2xl font-bold mx-auto mb-2 capitalize">
					{t(
						`Harness the power of FlashReview to grow your business organically.`
					)}
				</p>
				<div className="flex justify-center gap-4 mt-28">
					<SubscriptionCard
						price="$39.99/month"
						plan="Basic"
						text="Basic pack to help you to get started"
					/>
					{/* <SubscriptionCard
            price="Free"
            plan="Pro"
            text="More power for small teams create project plans with confidence"
          />
          <SubscriptionCard
            price="Free"
            plan="Business +"
            text="For companies that need to manage work happening across multiple teams."
          />
          <SubscriptionCard
            price="Custom"
            plan="Enterprice"
            text="For enterprise that need additional security, control, and support."
          /> */}
				</div>
			</div>

			<AllSection />
		</div>
	);
};

export default Price;
