// import Benefits from "../benefits/benefits";
import Button from "../button/button";
import ContactForm from "../contact-form/contact-form";

import pat from "../../assets/pattern.png";

const AllSection = () => {
	return (
		<div>
			<div className="container rounded-2xl mx-auto mt-16 p-8 md:py-24 gradient relative">
				<div
					className="absolute inset-0 rounded-2xl"
					style={{
						background: `url(${pat}) no-repeat`,
						backgroundSize: "cover",
					}}></div>
				<div className="relative z-5">
					<p className="text-center text-2xl md:text-4xl text-white text-shadow max-w-2xl font-bold mx-auto mb-2 capitalize">
						Get start Now
					</p>
					<p className="text-center text-sm font-light text-white mb-8 max-w-xl mx-auto">
						Make the most of your online reputation and earn more business from
						people looking for your service.
					</p>
					<div className="flex justify-center mt-8">
						<Button buttonType={"white"} children="Get Started" />
					</div>
				</div>
			</div>
			{/* <section>
        <div className="mt-28 container mx-auto px-4">
          <p className="text-center text-2xl md:text-4xl text-main text-shadow max-w-2xl font-bold mx-auto mb-2 capitalize">
            Frequently Asked Questions
          </p>
          <p className="text-center text-sm font-light mb-8 max-w-xl mx-auto">
            Over 3,600+ businesses use Popup Review everyday to earn customer
            reviews and grow their business online.
          </p>
        </div>
        <div className="grid gap-y-20 px-4">
          <Benefits
            title={"How Real Businesses Benefit From Google Reviews?"}
          />
          <Benefits
            title={"How Real Businesses Benefit From Google Reviews?"}
          />
          <Benefits
            title={"How Real Businesses Benefit From Google Reviews?"}
          />
        </div>
      </section> */}
			<section>
				<div className="mt-28 container mx-auto px-4">
					<p className="text-center text-2xl md:text-4xl text-main text-shadow max-w-2xl font-bold mx-auto mb-6 capitalize">
						Get In Touch Today
					</p>
					{/* <p className="text-center text-sm font-light mb-8 max-w-xl mx-auto">
            Lorem ipsum dolor sit amet consectetur. In donec suspendisse
            ultricies id tortor purus mauris. Ligula molestie sed vivamus odio
          </p> */}
					<ContactForm />
				</div>
			</section>
		</div>
	);
};

export default AllSection;
