import { createSlice } from "@reduxjs/toolkit";
import { clearErrors, returnErrors } from "./ErrorReducer";
import axios from "axios";
import { toast } from "react-toastify";

let initialState = {
	data: null,
	isUpdated: false,
};

export const widgetSlice = createSlice({
	name: "widget",
	initialState,
	reducers: {
		getWidget: (state, { payload }) => {
			state.data = payload?.data || payload;
		},
		updateWidget: (state, { payload }) => {
			state.isUpdated = true;
			state.data = payload?.data || payload || state?.data;
		},
		actionFail: state => {
			state.isUpdated = false;
		},
	},
});
export const { getWidget, updateWidget, actionFail } = widgetSlice.actions;

export const loadWidgetPage = data => async dispatch => {
	dispatch(clearErrors());
	try {
		let res;
		if (!data) {
			res = await axios.get(`/api/v1/review/widget`);
			dispatch(getWidget(res.data));
		} else {
			res = await axios.post(`/api/v1/review/widget`, {
				...data,
			});
			dispatch(updateWidget(res.data));
		}
		if (data) {
			toast.success(res?.data?.msg);
		}
	} catch (err) {
		if (err) console.log(err.response?.data?.data, { err });
		if (err?.response?.status === 429) toast.error(err?.response?.data);
		dispatch(actionFail());
		if (data) {
			let error = err.response?.data?.data;
			if (error) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			}
		}
	}
};
