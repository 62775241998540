import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { fetchMachineTranslation } from "../../i18n";
import { GlobalState } from "../../data/Context";

const BUTTON_TYPES = {
	primary: "bg-main text-white",
	suspended: "bg-[#EF5DA8] text-white",
	secondary: "border border-white text-white hover:bg-main hover:text-white",
	second: "border border-main text-main hover:bg-main hover:text-white",
	black: "border border-black hover:bg-black hover:text-white",
	white: "bg-white text-main",
};

const Button = ({ buttonType, children, width, ...restProps }) => {
	const { t, i18n } = useTranslation();
	let { lang } = useContext(GlobalState);
	useEffect(() => {
		const fetchResources = async () => {
			const translationKeys = [children];

			const translations = await fetchMachineTranslation(
				lang || i18n.language,
				translationKeys
			);

			const delimetedTrans = translations?.[0]?.split("++");

			const translationResources = {};
			for (let i = 0; i < translationKeys.length; i++) {
				translationResources[translationKeys?.[i]] = delimetedTrans?.[i];
			}

			i18n.addResourceBundle(
				i18n.language,
				"translation",
				translationResources
			);
		};

		fetchResources();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [i18n.language, lang]);
	return (
		<button
			className={`h-10 px-4 rounded-md font-semibold text-sm whitespace-nowrap ${width} ${BUTTON_TYPES[buttonType]}`}
			{...restProps}>
			{t(children)}
		</button>
	);
};

export default Button;
