import React, { useContext, useEffect, useState } from "react";
import Input from "../components/input/input";
import { useLocation, useNavigate } from "react-router-dom";
import { ReuseBox } from "./otp";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import {
	useStripe,
	useElements,
	CardNumberElement,
	CardExpiryElement,
	CardCvcElement,
} from "@stripe/react-stripe-js";
import axios from "axios";
import { toast } from "react-toastify";
import { Buttons } from "../components/ModalComponents";
import { GlobalState } from "../data/Context";

const Checkout = () => {
	let navigate = useNavigate(),
		init = { email: "", firstName: "", lastName: "" },
		[state, setState] = useState(init),
		textChange = e => {
			let { name, value } = e.target;
			setState({ ...state, [name]: value });
		},
		[loading, setLoading] = useState(false),
		[secret, setSecret] = useState(""),
		location = useLocation();

	useEffect(() => {
		if (location?.state?.email)
			setState({ ...state, email: location?.state?.email });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location?.state]);

	let { t } = useContext(GlobalState);

	const stripe = useStripe();
	const elements = useElements();

	let handleSubmit = async e => {
		e.preventDefault();
		if (!state.email) return toast.warn("Email required", { autoClose: 5000 });
		console.log({ state });
		setLoading(true);
		try {
			let customerName = `${state.firstName || ""} ${state.lastName || ""}`,
				billing_details = {
					name: customerName,
					email: state.email,
				};
			const result = await stripe.createPaymentMethod({
				type: "card",
				card: elements.getElement(CardNumberElement),
				billing_details,
			});
			console.log({ result });
			if (result.error) {
				console.log(result.error.message);
			} else {
				var res = await axios.put("/api/v1/subscription", {
					email: state.email,
					payment_method: result.paymentMethod.id,
					customerName,
				});

				setSecret(res.data.data);
				if (res.data.status === "requires_action") {
					const { error, paymentIntent } = await stripe.confirmCardPayment(
						secret || res.data.data
					);
					// https://stripe.com/docs/payments/card-element
					if (error) {
						toast.error(`Payment failed: ${error.message}`, {
							autoClose: false,
						});
					} else {
						toast.success(`Payment status: ${paymentIntent.status}`, {
							autoClose: 5000,
						});
						try {
							var res2 = await axios.post("/api/v1/subscription", {
								email: state.email,
								paymentID: paymentIntent.id,
								stripeId: res?.data?.stripeId,
							});
							toast.success(res2.data.msg, { autoClose: 5000 });
							setState(init);
							return navigate("/login");
						} catch (error) {
							if (error?.response.data?.data) {
								error?.response.data?.data.forEach(element => {
									toast.error(element.msg);
								});
							}
						}
					}
				} else {
					toast.success(`Payment status: ${res.data.status}`, {
						autoClose: 5000,
					});
					try {
						let res2 = await axios.post("/api/v1/subscription", {
							email: state.email,
							paymentID: res?.data?.payment_intent?.id,
							stripeId: res?.data?.stripeId,
						});
						toast.success(res2?.data?.msg, { autoClose: 5000 });
						setState(init);
						return navigate("/login", { state });
					} catch (error) {
						if (error?.response.data?.data) {
							error?.response.data?.data.forEach(element => {
								toast.error(element.msg);
							});
						}
					}
				}
			}
		} catch (error) {
			if (error?.response.data?.data) {
				error?.response.data?.data.forEach(element => {
					toast.error(element.msg, { autoClose: false });
				});
			}
		}
		setLoading(false);
	};
	return (
		<ReuseBox>
			<div className="max-w-md px-10 mt-16">
				<div className="bg-main text-white p-2 py-4 mb-4 rounded-md flex items-center justify-between">
					<p className="flex items-center gap-2 text-xs opacity-50">
						<span>
							<BsFillCheckCircleFill />
						</span>{" "}
						{t(`Otp Verification`)}
					</p>
					<HiOutlineArrowNarrowRight width={"50px"} />
					<p className={`flex items-center gap-2 text-xs`}>
						<span>
							<BsFillCheckCircleFill />
						</span>{" "}
						{t(`Card Linking`)}
					</p>
				</div>
				<p className="capitalize text-4xl font-thin mb-4">{`hello!`}</p>
				<div>
					<p className="text-4xl font-extrabold mb-4">
						{t(`Enter Payment Details`)}
					</p>
					<form>
						<div className="grid gap-x-8">
							{!location?.state?.email && (
								<>
									<div className="col-span-2">
										<Input
											label={t("Email")}
											type="email"
											name="email"
											placeholder={"example@xyz.com"}
											value={state?.email}
											onChange={textChange}
										/>
									</div>
									<div className="col-span-2">
										<Input
											onChange={textChange}
											type={"text"}
											name={"firstName"}
											label={t("First Name")}
											value={state?.firstName}
										/>
									</div>
									<div className="col-span-2">
										<Input
											type={"text"}
											onChange={textChange}
											name={"lastName"}
											label={t("Last Name")}
											value={state?.lastName}
										/>
									</div>
								</>
							)}
							<div className="relative mb-4 rounded border border-text col-span-2">
								<CardNumberElement className="min-h-[auto] w-full bg-transparent p-3 leading-[1.6] outline-none placeholder:opacity-60" />
								<label
									className="absolute bg-white -top-2 left-2 text-xs text-text"
									htmlFor={"Card number"}>
									{t(`Card number`)}
								</label>
							</div>
							<div className="relative mb-4 rounded border border-text col-span-2">
								<CardExpiryElement className="min-h-[auto] w-full bg-transparent p-3 leading-[1.6] outline-none placeholder:opacity-60" />
								<label
									className="absolute bg-white -top-2 left-2 text-xs text-text"
									htmlFor={"Expiration Date"}>
									{t(`Expiration Date`)}
								</label>
							</div>
							<div className="relative mb-4 rounded border border-text col-span-2">
								<CardCvcElement
									type="password"
									className="min-h-[auto] w-full bg-transparent p-3 leading-[1.6] outline-none placeholder:opacity-60"
								/>
								<label
									className="absolute bg-white -top-2 left-2 text-xs text-text"
									htmlFor={"Card Security Code"}>
									{t(`Card Security Code`)}
								</label>
							</div>
							{/* <div className="col-span-2">
								<Input type={"select"} label={"Country or Region"} />
							</div> */}
						</div>
						<small className="mb-4 text-sm text-main">
							{t(`Recurring monthly payment`)}
						</small>
						<small className="fw-bold text-sm text-main block mb-2">
							{t(`Billing of $39.99 starts after a 7 days trial`)}
						</small>
						{/* <!-- Submit button --> */}
						<div className="space-y-4">
							<Buttons
								type="submit"
								class="inline-block w-full rounded bg-[#65D133] px-7 pb-2.5 pt-3 text-sm font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-main hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-main focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-main active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
								data-te-ripple-init
								data-te-ripple-color="light"
								onClick={handleSubmit}
								loading={loading}
								title={"Subscribe"}
							/>
							<button
								type="button"
								class="inline-block w-full rounded border border-main px-7 pb-2.5 pt-3 text-sm font-medium uppercase leading-normal text-main shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-main hover:text-white hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-main focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-main active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
								data-te-ripple-init
								data-te-ripple-color="light"
								onClick={() => navigate("/login")}>
								{t(`Login`)}
							</button>
						</div>
					</form>
				</div>
			</div>
		</ReuseBox>
	);
};

export default Checkout;
