import { useContext, useEffect, useState } from "react";
import ExportCard from "../../components/export-card/export-card";
import Input from "../../components/input/input";
// import Button from "../../components/button/button";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import ModalComponents, { Buttons } from "../../components/ModalComponents";
import { IoIosAdd } from "react-icons/io";
import { toast } from "react-toastify";
import {
	addNewPlatform,
	addNewUserPlatform,
} from "../../data/Reducers/PlatformReducer";
// import { HiExternalLink } from "react-icons/hi";
// import { FaTags } from "react-icons/fa";
// import { CgMenuRightAlt } from "react-icons/cg";
import { MdDelete } from "react-icons/md";
import Profilerounded from "../../components/profile-rounded/profile-rounded";
import moment from "moment";
import { returnErrors } from "../../data/Reducers/ErrorReducer";
import { GlobalState } from "../../data/Context";

const Exports = () => {
	const { auth, platform } = useSelector(state => state);
	const [toggle, setToggle] = useState(true),
		[toggle2, setToggle2] = useState(false),
		[platformID, setPlatform] = useState(""),
		[state, setState] = useState(""),
		[dataList, setDataList] = useState([]),
		[userID, setUserID] = useState(""),
		[list, setList] = useState(null),
		[datum, setDatum] = useState(null),
		[platMini, setPlatMini] = useState(null),
		[loading, setLoading] = useState(false),
		[submit, setSubmit] = useState(false),
		dispatch = useDispatch(),
		handleSubmit = async e => {
			e?.preventDefault();
			setLoading(true);
			let dat = {
				platform: datum?._id,
				userLink: userID || state,
				_id: datum?._id,
			};

			let findDat = platform?.user?.find(
				item => item?.platform?._id === datum?._id
			);
			console.log({ findDat, dat });
			await dispatch(addNewUserPlatform(findDat ? "put" : "post", dat));
			setLoading(false);
			setSubmit(true);
		};

	const showexport = () => {
		if (auth.user?.isAdmin) {
			setToggle2(!toggle2);
		} else {
			setToggle(!toggle);
		}
	};

	const close = () => {
		setToggle2(false);
		setDatum(null);
	};

	useEffect(() => {
		setList([...platform?.data]);
	}, [platform?.data]);

	useEffect(() => {
		if (platform?.isAdded && submit) {
			setToggle(!toggle);
			setDataList(null);
			setPlatMini(null);
			setUserID(null);
			setState(null);
		}
		if (platform?.isUpdated && submit) {
			setToggle(!toggle);
			setDataList(null);
			setPlatMini(null);
			setUserID(null);
			setState(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [submit, platform?.isAdded, platform?.isUpdated]);

	useEffect(() => {
		if (platformID?.toLowerCase() === "google" && state)
			getLocation(state, setDataList);
	}, [state, platformID]);

	useEffect(() => {
		if (["google"]?.includes(platformID?.toLowerCase()) && userID) {
			// console.log({ platformID, userID });
			getPlatformMiniDetails(
				{
					datum,
					userID,
				},
				setPlatMini
			);
		}
		if (["yelp"]?.includes(platformID?.toLowerCase()) && state) {
			// console.log({ platformID, userID });
			getPlatformMiniDetails(
				{
					datum,
					userID: state,
				},
				setPlatMini
			);
		}
	}, [userID, platformID, datum, state]);

	useEffect(() => {
		if (state && dataList?.length > 0)
			dataList?.map(
				item => item?.description === state && setUserID(item?.place_id)
			);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state, dataList]);

	let { t } = useContext(GlobalState);

	if (!list) return <></>;

	return (
		<div>
			<p className="my-0 text-xl font-bold">Add new source for reviews</p>
			<div className="grid md:grid-cols-3 gap-4 my-4">
				{list
					?.sort((a, b) => a?.name?.localeCompare(b?.name))
					?.map((item, i) => (
						<ExportCard
							key={i}
							item={item}
							image={`${item?.name?.toLowerCase()}.png`}
							title={item?.name}
							toggle={() => {
								showexport();
								setPlatform(item?.name);
								setDatum(item);
								setDataList(null);
								setPlatMini(null);
								setUserID(null);
								setState(null);
							}}
						/>
					))}
			</div>
			{!auth?.user?.isAdmin && <ReviewSources />}

			{/* drawer */}
			<div>
				{!toggle && (
					<div
						className="bg-main opacity-30 fixed inset-0 z-40"
						onClick={() => {
							setToggle(!toggle);
							setDataList(null);
							setPlatMini(null);
							setUserID(null);
							setState(null);
						}}></div>
				)}
				<div
					className={`fixed top-0 right-0 z-40 h-screen p-4 overflow-y-auto overflow-x-hidden transition-transform ${
						toggle && "translate-x-full"
					}  bg-white w-96`}>
					<h5
						id="drawer-right-label"
						className="inline-flex items-center mb-4 text-base font-semibold text-gray-500 dark:text-gray-400">
						<svg
							className="w-5 h-5 mr-2"
							// aria-hidden="true"
							fill="currentColor"
							viewBox="0 0 20 20"
							xmlns="http://www.w3.org/2000/svg">
							<path
								fill-rule="evenodd"
								d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
								clip-rule="evenodd"></path>
						</svg>
						{t(`Connect`)} {platformID || ""}
					</h5>
					<button
						type="button"
						onClick={() => setToggle(!toggle)}
						className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center">
						<svg
							// aria-hidden="true"
							className="w-5 h-5"
							fill="currentColor"
							viewBox="0 0 20 20"
							xmlns="http://www.w3.org/2000/svg">
							<path
								fill-rule="evenodd"
								d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
								clip-rule="evenodd"></path>
						</svg>
						<span className="sr-only">Close menu</span>
					</button>

					<div className="mt-10">
						<Input
							label={
								platformID?.toLowerCase() === "google"
									? `Business name or Google Maps URL`
									: "Address URL"
							}
							list={"pickup"}
							value={state}
							onChange={e => setState(e?.target?.value)}
							dataList={dataList}
						/>
						{/* <Input type={"select"} label={"Address Url"} /> */}
						{platMini?.total && (
							<div className="bg-[#51ff0024] rounded py-3 overflow-x-hidden mb-3">
								<p className="my-0 text-sm font-medium p-2">
									{t(`Found`)} {platMini?.total} review(s) {t(`for`)}{" "}
									{platMini?.name}
								</p>
							</div>
						)}
						{platformID && (
							<div className="bg-gray-100 rounded py-3 overflow-x-hidden mb-3">
								<p className="my-0 text-sm font-medium p-2">
									{platformID?.toLowerCase() === "tripadvisor"
										? `${t(
												`As input we need review page URL from Tripadvisor. URL should look like following`
										  )}:
								https://www.tripadvisor.com/Restaurant_Review-g189942-d12981622-Reviews-Restaurant_Papa_s-Jyvaskyla_Central_Finland.html`
										: platformID?.toLowerCase() === `yelp`
										? `
							${t(
								`As input we need review page URL from Yelp. URL should look like following`
							)}: https://www.yelp.com/biz/cafetoria-helsinki-2`
										: platformID?.toLowerCase() === `facebook`
										? `
							${t(
								`As input we need review page URL from Facebook. URL should look like following`
							)}: https://wwww.facebook.com/sqicict`
										: platformID?.toLowerCase() === `google`
										? `
							${t(
								"Find your business by searching it using the field above and selecting it from the list of suggestions."
							)}`
										: ""}
								</p>
							</div>
						)}
						<div className="flex justify-end">
							{/* <Button buttonType={"primary"} children={"Connect"} /> */}
							<Buttons
								loading={loading}
								onClick={handleSubmit}
								title={"Connect"}
								type={"submit"}
							/>
						</div>
					</div>
				</div>
			</div>
			<ModifyPlatformDetails isOpen={toggle2} toggle={close} datum={datum} />
			{/* modal */}
		</div>
	);
};

export default Exports;

export let getLocation = async (input, setState) => {
	try {
		let res = await axios.get(
			`/api/v1/platform/get-address-suggestion?search=${input}`
		);
		setState(res.data?.data);
	} catch (error) {
		console.log({ error });
	}
};

export let getPlatformMiniDetails = async (data, setState) => {
	try {
		let datum = {
			userLink: data?.userID,
			platform: data?.datum?._id,
		};
		let res = await axios.post(`/api/v1/platform/retrieve-platform-details`, {
			...datum,
		});
		setState(res?.data?.data);
		console.log({ platMini: res?.data?.data });
	} catch (error) {
		console.log({ error });
	}
};

const ModifyPlatformDetails = ({ isOpen, toggle, setIsOpen, datum }) => {
	let init = {
			image: null,
			name: "",
			limit: 100,
			refreshRate: 2,
		},
		{ platform } = useSelector(state => state),
		{t} = useContext(GlobalState),
		dispatch = useDispatch(),
		[state, setState] = useState(init),
		textChange = e => {
			let { value, name } = e.target;
			setState({ ...state, [name]: value });
		},
		[loading, setLoading] = useState(false),
		[submit, setSubmit] = useState(false),
		handleSubmit = async e => {
			e?.preventDefault();
			if (!state?.image || !state?.image) return;
			setLoading(true);
			await dispatch(addNewPlatform(datum ? "put" : "post", state));
			setLoading(false);
			setSubmit(true);
		};

	useEffect(() => {
		if (datum) setState(datum);
	}, [datum]);

	useEffect(() => {
		if (submit && platform?.isAdded) {
			setState(init);
			toggle();
			setSubmit(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [submit, platform?.isAdded]);

	useEffect(() => {
		if (submit && platform?.isUpdated) {
			setState(init);
			toggle();
			setSubmit(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [submit, platform?.isUpdated]);

	let handleChangeImage = name => e => {
		const file = e.target.files[0];
		let err = "";
		if (!file) return (err = `File, ${file?.name} does not exist`);
		if (err) {
			return toast.error(err);
		} else {
			setState({ ...state, [name || "image"]: file });
		}
	};
	return (
		<>
			<ModalComponents
				isOpen={isOpen}
				toggle={toggle}
				title={t("Review Source Platform")}>
				<form className="max-w-md mx-auto">
					<Input
						label={t("Name")}
						onChange={textChange}
						value={state?.name}
						name="name"
						readOnly={datum ? true : false}
					/>
					<Input
						label={t("Review Import limit")}
						name="limit"
						onChange={textChange}
						value={state?.limit}
						type={"number"}
					/>
					<Input
						label={t("Number of daily refresh limit")}
						name="refreshRate"
						onChange={textChange}
						value={state?.refreshRate}
						type={"number"}
					/>
					<div>
						<div className="rounded-4xl border border-dashed h-2/4 flex flex-col justify-center items-center text-black w-full file_upload">
							{state?.image ? (
								<img
									src={
										state?.image?.url && typeof state?.image?.url === "string"
											? state?.image?.url
											: URL.createObjectURL(state?.image)
									}
									alt="Course"
									className="img-fluid w-full h-full"
									style={{
										objectFit: "contain",
									}}
								/>
							) : (
								<>
									<IoIosAdd size="200px" />
									<p className="text-center fs-4">{t("Add photo")}</p>
								</>
							)}
							<input
								title="Upload file"
								type="file"
								name="file"
								id="file"
								className="myCursor"
								accept="image/*"
								onChange={handleChangeImage("image")}
							/>
						</div>
					</div>
					<div className="flex justify-center mt-8">
						<Buttons
							loading={loading}
							onClick={handleSubmit}
							title={datum ? "Update" : "Add"}
						/>
					</div>
				</form>
			</ModalComponents>
		</>
	);
};

export const ReviewSources = () => {
	let { platform } = useSelector(state => state),
		[loading, setLoading] = useState(null),
		dispatch = useDispatch(),
		handleSubmit = (id, type) => async e => {
			e?.preventDefault();
			setLoading({ load: id?._id, type });
			if (type === "delete") {
				await dispatch(addNewUserPlatform(type, id));
			} else if (type === "get") {
				try {
					let res = await axios.put(
						`/api/v1/platform/refresh-reviews/${id?.platform?._id}`
					);
					toast.success(res?.data?.msg);
				} catch (err) {
					if (err) console.log(err.response?.data?.data, { err });
					if (err?.response?.status === 429) toast.error(err?.response?.data);
					let error = err.response?.data?.data;
					if (error) {
						dispatch(returnErrors({ error, status: err?.response?.status }));
					}
				}
			}
			setLoading(null);
		};

	let { t } = useContext(GlobalState);

	return (
		<div>
			<p className="my-0 text-xl font-bold">{t(`Active Review Sources`)}</p>
			{platform?.user?.map((item, i) => (
				<div
					key={i}
					className="block my-6 bg-white border border-gray-200 rounded-lg shadow">
					<div className="flex items-center justify-between border-b border-gray p-6">
						<div className="flex gap-2 items-center">
							{/* <span>icon</span> */}
							<span className="text-sm font-semibold">
								{item?.userID ||
									item?.userLink ||
									`Lorem ipsum dolor sit amet consectetur.`}
							</span>
							<span className="icon">
								<Profilerounded sm img={item?.platform?.image?.url} />
							</span>
							{/* <span>
								<HiExternalLink />
							</span> */}
							<span>
								<span className="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded border border-green-400">
									{t("Active")}{" "}
								</span>
							</span>
						</div>
						{/* <div className="flex items-center gap-2">
							<span className="text-sm font-semibold">Lorem, ipsum dolor.</span>
							<span>
								<label className="relative inline-flex items-center cursor-pointer">
									<input type="checkbox" value="" className="sr-only peer" />
									<div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
								</label>
							</span>
						</div> */}
					</div>
					<div className="flex items-top gap-6 border-b border-gray p-6">
						<div>
							<p className="text-sm font-semibold">{t(`Reviews Total`)}</p>
							<p className="text-xs text-text font-medium">
								{item?.reviewsTotal}
							</p>
						</div>
						<div>
							<p className="text-sm font-semibold">{t(`Reviews Published`)}</p>
							<p className="text-xs text-text font-medium">
								{item?.reviewsPublished}
							</p>
						</div>
						<div>
							<p className="text-sm font-semibold">{t(`Last import`)}</p>
							<p className="text-xs text-text font-medium">
								{item?.prevInvocation
									? moment(item?.prevInvocation).format("MMMM DD, YYYY hh:mm A")
									: null}
							</p>
						</div>
						<div>
							<p className="text-sm font-semibold">
								{t(`Next scheduled import`)}
							</p>
							<p className="text-xs text-text font-medium">
								{/* July 11, 2023 8:16 AM */}
								{item?.nextInvocation
									? moment(item?.nextInvocation).format("MMMM DD, YYYY hh:mm A")
									: null}
							</p>
						</div>
						{/* <div>
							<p className="text-sm font-semibold">Language</p>
							<p className="text-xs text-text font-medium">English</p>
						</div> */}
					</div>
					{/* <div className="flex items-center gap-6 border-b border-gray p-6">
						<p className="text-sm font-semibold">Tags</p>

						<span>
							<FaTags />
						</span>
						<input
							type="text"
							className="w-full border-b-2 border-text bg-none"
							placeholder="Start typing to add tags"
						/>
					</div>
					<div className="flex items-center gap-6 border-b border-gray p-6">
						<p className="text-sm font-semibold">Custom review fields</p>

						<span>
							<CgMenuRightAlt />
						</span>
						<p className="text-sm font-semibold">Manage Fields</p>
					</div> */}
					<div className="p-6">
						<div className="flex items-center justify-between mb-4">
							{/* <div>
								<span className="bg-gray-100 text-gray-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded mr-2 dark:bg-gray-700 dark:text-gray-400 border border-gray-500">
									<svg
										className="w-2.5 h-2.5 mr-1.5"
										aria-hidden="true"
										xmlns="http://www.w3.org/2000/svg"
										fill="currentColor"
										viewBox="0 0 20 20">
										<path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm3.982 13.982a1 1 0 0 1-1.414 0l-3.274-3.274A1.012 1.012 0 0 1 9 10V6a1 1 0 0 1 2 0v3.586l2.982 2.982a1 1 0 0 1 0 1.414Z" />
									</svg>
									Get reviews
								</span>
							</div> */}
							<Buttons
								title={" "}
								css="text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded mr-2 border border-gray-500"
								loading={loading?.load === item?._id && loading?.type === "get"}
								noDefault
								bg={"bg-grey-100"}
								loadCss={"bg-grey-100"}
								color="dark:bg-gray-700 dark:text-gray-400 text-gray-800"
								onClick={handleSubmit(item, "get")}>
								<svg
									className="w-2.5 h-2.5 mr-1.5"
									aria-hidden="true"
									xmlns="http://www.w3.org/2000/svg"
									fill="currentColor"
									viewBox="0 0 20 20">
									<path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm3.982 13.982a1 1 0 0 1-1.414 0l-3.274-3.274A1.012 1.012 0 0 1 9 10V6a1 1 0 0 1 2 0v3.586l2.982 2.982a1 1 0 0 1 0 1.414Z" />
								</svg>
								{t(`Get reviews`)}
							</Buttons>
							<Buttons
								title={" "}
								noDefault
								color={"text-red-700"}
								bg="border-red-700"
								css="flex items-center gap-2"
								loading={
									loading?.load === item?._id && loading?.type === "delete"
								}
								loadCss={"bg-red-700"}
								onClick={handleSubmit(item, "delete")}>
								<span>
									<MdDelete />
								</span>
								<span>{t(`Delete source`)}</span>
							</Buttons>
							{/* <div className="text-red-700 flex items-center gap-2">
							</div> */}
						</div>
						<div
							className="flex items-center p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400"
							role="alert">
							<svg
								className="flex-shrink-0 inline w-4 h-4 mr-3"
								aria-hidden="true"
								xmlns="http://www.w3.org/2000/svg"
								fill="currentColor"
								viewBox="0 0 20 20">
								<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
							</svg>
							<span className="sr-only">{t(`Fetching reviews`)}</span>
							<div>
								{t("Fetching reviews text")}
							</div>
						</div>
					</div>
				</div>
			))}
		</div>
	);
};
