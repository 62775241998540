import { createSlice } from "@reduxjs/toolkit";
import { DeleteData, EditData, imageUpload } from "./UserReducer";
import { clearErrors, returnErrors } from "./ErrorReducer";
import axios from "axios";
import { toast } from "react-toastify";
import { getReviews } from "./ReviewsReducer";

let initialState = {
	data: [],
	isAdded: false,
	isUpdated: false,
	isDeleted: false,
	user: [],
};

export const platformSlice = createSlice({
	name: "platform",
	initialState,
	reducers: {
		getUserPlatform: (state, { payload }) => {
			state.user = payload?.data || payload;
		},
		addUserPlatform: (state, { payload }) => {
			let data = payload?.data || payload;
			state.isAdded = true;
			state.user = [data, ...state?.user];
		},
		updateUserPlatform: (state, { payload }) => {
			let data = payload?.data || payload;
			state.isUpdated = true;
			state.user = EditData(state?.user, data);
		},
		deleteUserPlatform: (state, { payload }) => {
			let data = payload?.data || payload;
			state.isDeleted = true;
			state.user = DeleteData(state?.user, data);
		},
		getPlatform: (state, { payload }) => {
			state.data = payload?.data || payload;
		},
		addPlatform: (state, { payload }) => {
			let data = payload?.data || payload;
			state.isAdded = true;
			state.data = [data, ...state?.data];
		},
		updatePlatform: (state, { payload }) => {
			let data = payload?.data || payload;
			state.isUpdated = true;
			state.data = EditData(state?.data, data);
		},
		platformFail: state => {
			state.isDeleted = false;
			state.isAdded = false;
			state.isUpdated = false;
			state.isFound = false;
		},
	},
});
export const {
	getPlatform,
	getUserPlatform,
	addPlatform,
	addUserPlatform,
	updatePlatform,
	updateUserPlatform,
	deleteUserPlatform,
	platformFail,
} = platformSlice.actions;

export const addNewPlatform = (type, data) => async dispatch => {
	dispatch(clearErrors());
	try {
		let res;
		let image = data?.image;
		if (type !== "get" && data?.image && !data?.image?._id) {
			let media = await imageUpload([data.image]);
			image = media[0];
		}

		if (type === "get") {
			res = await axios.get(`/api/v1/platform`);
			dispatch(getPlatform(res?.data));
		}
		if (type === "post") {
			res = await axios.post(`/api/v1/platform`, { ...data, image });
			dispatch(addPlatform(res?.data));
		}
		if (type === "put") {
			res = await axios.put(`/api/v1/platform?platform=${data?._id}`, {
				...data,
				image,
			});
			dispatch(updatePlatform(res?.data));
		}

		if (type !== "get") toast.success(res?.data?.msg);
	} catch (err) {
		if (err) console.log(err.response?.data?.data, { err });
		if (err?.response?.status === 429) toast.error(err?.response?.data);
		dispatch(platformFail());
		if (type && type !== "get") {
			let error = err.response?.data?.data;
			if (error) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			}
		}
	}
};

export const addNewUserPlatform = (type, data) => async dispatch => {
	dispatch(clearErrors());
	try {
		let res;

		if (type === "get") {
			res = await axios.get(`/api/v1/platform/organization`);
			dispatch(getUserPlatform(res?.data));
		}
		if (type === "post") {
			res = await axios.post(`/api/v1/platform/organization`, { ...data });
			dispatch(addUserPlatform(res?.data));
		}
		if (type === "put") {
			res = await axios.put(
				`/api/v1/platform/organization?platform=${data?._id}`,
				{ ...data }
			);
			dispatch(updateUserPlatform(res?.data));
		}
		if (type === "delete") {
			res = await axios.delete(
				`/api/v1/platform/organization?platform=${data?.platform?._id}`
			);
			dispatch(deleteUserPlatform(data));
		}
		if (type !== "get") {
			dispatch(getReviews("pending"));
			dispatch(getReviews("hide"));
			dispatch(getReviews("display"));
			toast.success(res?.data?.msg);
		}
	} catch (err) {
		if (err) console.log(err.response?.data?.data, { err });
		if (err?.response?.status === 429) toast.error(err?.response?.data);
		dispatch(platformFail());
		if (type && type !== "get") {
			let error = err.response?.data?.data;
			if (error) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			}
		}
	}
};
