import React, { useState } from "react";
import Input from "../../components/input/input";
import Brand from "../../components/brand/brand";

const NewPassword = () => {
	const [formValue, setFormValue] = useState("");

	const handleChange = e => {
		const { name, value } = e.target;
		setFormValue({ ...formValue, [name]: value });
	};

	const handleSubmit = e => {
		e.preventDefault();
		console.log("submit,", formValue);
	};

	return (
		<div className="h-screen">
			<div className="grid grid-cols-2">
				<div className="bg-mai bg-gray-200 h-screen text-whit flex items-center justify-center">
					<div className="max-w-xl">
						<div className="mb-4 flex justify-center">
							<Brand />
						</div>
						<p className="text-2xl font-bold mb-8">
							Where compelling stories and conversation changes the world
						</p>
						<ul>
							<li className="mt-4 flex gap-2">
								<span className="text-2xl">✨</span>
								<span className="text-xs">
									<span className="font-semibold">
										Make meaningful impact in the life of people.
									</span>{" "}
									thousands of people are changing the world through meaningful
									conversations
								</span>
							</li>
							<li className="mt-4 flex gap-2">
								<span className="text-2xl">❤️</span>
								<span className="text-xs">
									<span className="font-semibold">
										Meet, connect and collaborate
									</span>{" "}
									Access to a community with incredible and diverse experience
									people around the world our and get that provide valuable
									support
								</span>
							</li>
							<li className="mt-4 flex gap-2">
								<span className="text-2xl">💫</span>
								<span className="text-xs">
									<span className="font-semibold">
										A community tailored for growth
									</span>{" "}
									Mentorship is a two way street, you wont ust be supporting
									mentees, you'll be having a real say in everything. You speak,
									we listen
								</span>
							</li>
						</ul>
					</div>
				</div>
				<div className="self-center">
					<div className="text-center mb-8">
						<p className="text-2xl font-semibold">Hey, Welcome back</p>
						<p className="text-sm text-main">
							Learn and gain access to the best in the industry
						</p>
					</div>
					<form onSubmit={handleSubmit} className="max-w-md mx-auto">
						{/* <!-- Password input --> */}
						<Input
							placeholder="use a strong password"
							type={"password"}
							label={"New password"}
							onChange={handleChange}
							name="password"
							value={formValue.password}
							password
						/>
						<Input
							placeholder="use a strong password"
							type={"password"}
							label={"Confirm password"}
							onChange={handleChange}
							name="password"
							value={formValue.password}
							password
						/>
						{/* <!-- Submit button --> */}
						<div className="space-y-4 flex justify-end">
							<button
								type="submit"
								class="inline-block w-40 ml-auto rounded bg-main px-7 pb-2.5 pt-3 text-sm font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-main hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-main focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-main active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
								Continue
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default NewPassword;
