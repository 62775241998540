import React, { useContext } from "react";
import { GlobalState } from "../../data/Context";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../data/Reducers/UserReducer";
import Brand from "../brand/brand";
// import DefaultHeader from "../default-header/default-header";
import { BiLogIn } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { fetchMachineTranslation } from "../../i18n";
// import { useState } from "react";

const Sidebar = ({ auth, children }) => {
  const { sidebarList, nav, toggleNav, lang, t } = useContext(GlobalState);
	const location = useLocation(),
		navigate = useNavigate(),
		dispatch = useDispatch();

	return (
		<div className={auth?.isAuth && "bg-secondary-100 min-h-screen"}>
			{auth?.isAuth && (
				<button
					data-drawer-target="logo-sidebar"
					data-drawer-toggle="logo-sidebar"
					aria-controls="logo-sidebar"
					type="button"
					className={`inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600`}
					onClick={toggleNav}>
					<span className="sr-only">Open sidebar</span>
					<svg
						className="w-6 h-6"
						aria-hidden="true"
						fill="currentColor"
						viewBox="0 0 20 20"
						xmlns="http://www.w3.org/2000/svg">
						<path
							clip-rule="evenodd"
							fill-rule="evenodd"
							d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
					</svg>
				</button>
			)}
			{auth?.isAuth &&
				(nav ? (
					<aside
						id="logo-sidebar"
						className={`fixed top-0 left-0 z-50 w-64 h-screen transition-transform   ${
							nav ? "translate-x-0" : "-translate-x-full md:translate-x-0"
						}`}
						aria-label="Sidebar">
						<div class="h-full px-3 py-4 overflow-y-auto gradient2">
							<div className="py-8 mb-4 flex items-center justify-between">
								<Brand />
								<button
									data-drawer-target="logo-sidebar"
									data-drawer-toggle="logo-sidebar"
									aria-controls="logo-sidebar"
									type="button"
									className={`inline-flex items-center p-2 mt-2 ml-3 text-sm text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-200`}
									onClick={toggleNav}>
									<span className="sr-only">Open sidebar</span>
									<svg
										className="w-6 h-6"
										aria-hidden="true"
										fill="currentColor"
										viewBox="0 0 20 20"
										xmlns="http://www.w3.org/2000/svg">
										<path
											clip-rule="evenodd"
											fill-rule="evenodd"
											d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
									</svg>
								</button>
							</div>
							<ul className="space-y-2 font-medium">
								{sidebarList.map(
									list =>
										list.permission.includes(auth.user?.privilege) && (
											<li
												className={`text-sm capitalize text-white py-1 px-2 whitespace-nowrap ${
													location.pathname?.includes(list?.url)
														? "active2"
														: ""
												}`}
												onClick={() => {
													if (document.body.clientWidth < 992 && nav)
														toggleNav();
												}}>
												<Link
													to={list.url}
													className={`flex items-center p-2 rounded-lg text-white hover:bg-white hover:text-black`}>
													<span>{list.icon}</span>
													<span className="flex-1 ml-3 whitespace-nowrap text-xl">
														{t(list.name)}
													</span>
													<span className="inline-flex items-center justify-center px-2 ml-3 text-sm font-medium text-gray-800 bg-gray-200 rounded-full dark:bg-gray-700 dark:text-gray-300">
														{}
													</span>
												</Link>
											</li>
										)
								)}
								<li
									className="mt-8 px-2"
									title="Logout"
									onClick={() => {
										dispatch(logout());
										navigate("/");
									}}>
									<Link
										to="#"
										className="flex items-center gap-4 p-1.5 text-white mt-8">
										<BiLogIn className="icon" size={24} />
										<span className="text nav-text">{t(`Logout`)}</span>
									</Link>
								</li>
							</ul>
						</div>
					</aside>
				) : (
					<aside
						id="logo-sidebar"
						className={`fixed top-0 left-0 z-40 w-20 h-screen transition-transform   ${
							nav ? "translate-x-0" : "-translate-x-full md:translate-x-0"
						}`}
						aria-label="Sidebar">
						<div class="h-full px-3 py-4 overflow-y-auto gradient2">
							<div className="py-8 mb-4">
								<button
									data-drawer-target="logo-sidebar"
									data-drawer-toggle="logo-sidebar"
									aria-controls="logo-sidebar"
									type="button"
									className={`inline-flex items-center p-2 mt-2 ml-3 text-sm text-white rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200`}
									onClick={toggleNav}>
									<span className="sr-only">Open sidebar</span>
									<svg
										className="w-6 h-6"
										aria-hidden="true"
										fill="currentColor"
										viewBox="0 0 20 20"
										xmlns="http://www.w3.org/2000/svg">
										<path
											clip-rule="evenodd"
											fill-rule="evenodd"
											d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
									</svg>
								</button>
							</div>
							<ul className="space-y-2 font-medium">
								{sidebarList.map(
									list =>
										list.permission.includes(auth.user?.privilege) && (
											<li
												className={`text-sm capitalize text-white py-1 px-2 whitespace-nowrap ${
													location.pathname?.includes(list?.url)
														? "active2"
														: ""
												}`}
												onClick={() => {
													if (document.body.clientWidth < 992 && nav)
														toggleNav();
												}}>
												<Link
													to={list.url}
													className={`flex items-center p-2 rounded-lg text-white hover:bg-white hover:text-black`}>
													<span>{list.icon}</span>
												</Link>
											</li>
										)
								)}
								<li
									className="mt-8 px-2"
									title="Logout"
									onClick={() => {
										dispatch(logout());
										navigate("/");
									}}>
									<Link
										to="#"
										className="flex items-center gap-4 p-1.5 text-white mt-8">
										<BiLogIn className="icon" size={24} />
									</Link>
								</li>
							</ul>
						</div>
					</aside>
				))}
			<div
				class={`${
					auth?.isAuth &&
					(nav ? "md:ml-64 mx-auto px-4" : "md:ml-20 mx-auto px-4")
				} min-h-screen`}
				onClick={() => document.body.clientWidth < 992 && nav && toggleNav()}>
				{children}
				{/* <div class={`${auth?.isAuth && "container mx-auto px-4"} max-h-screen overflow-auto`}>
				</div> */}
			</div>
		</div>
	);
};

export default Sidebar;
