// import Benefits from "../components/benefits/benefits";
import Button from "../components/button/button";
import ContactForm from "../components/contact-form/contact-form";
// import CustomerReview from "../components/customer-review/customer-review";
import ScrollIntoView from "react-scroll-into-view";

import pat from "../assets/pattern.png";
import SubscriptionCard from "../components/subscription-card/subscription-card";
import FAQ from "../components/faq/faq";
import { useContext } from "react";
import { GlobalState } from "../data/Context";

const Home = () => {
  let { t } = useContext(GlobalState);

  return (
    <div className="overflow-x-hidden">
      <header className="gradient relative pt-20">
        <div
          className="absolute inset-0 -top-20"
          style={{
            background: `url(${pat}) no-repeat`,
            backgroundSize: "cover",
          }}
        ></div>
        <div className="md:container mx-auto px-4 py-10 relative z-10">
          <div className="grid md:grid-cols-2 gap-8">
            <div
              className="text-white self-center"
              data-aos="fade-right"
              data-aos-duration="3000"
              data-aos-easing="ease-in-sine"
            >
              <p className="text-5xl font-bold mb-4 text-shadow">
                {t("hero title")}
              </p>
              <p className="text-lg font-light mb-8">
                {t(
                  "hero subtext"
                )}
              </p>
              <div>
                <ScrollIntoView selector={"#price"}>
                  <Button buttonType={"white"} children={t("Get Started")} />
                </ScrollIntoView>
              </div>
            </div>
            <div
              className="relative"
              data-aos="fade-left"
              data-aos-duration="3000"
              data-aos-easing="ease-in-sine"
            >
              <img
                src={require("../assets/new-hero.png")}
                className="h-full"
                alt=""
              />
              <img
                src={require("../assets/star.png")}
                className="absolute w-20 right-0 -md:right-20 bottom-0"
                alt=""
              />
            </div>
          </div>
        </div>
      </header>
      <div className="max-w-xl mx-auto relative z-40">
        <img
          src={require("../assets/link-image.png")}
          className="w-full -mt-14"
          alt=""
        />
      </div>
      <section id="our-services">
        <div className="mt-28 container mx-auto px-4">
          <p className="text-center text-4xl text-main text-shadow max-w-2xl font-bold mx-auto mb-2 capitalize">
            {t(`earn customer reviews`)}
          </p>
          <p className="text-center text-lg font-light mb-8 max-w-xl mx-auto">
            {t("earn subtitle")}
          </p>
          <div className="grid md:grid-cols-2 gap-8 mb-8">
            <div
              className=""
              data-aos="fade-right"
              data-aos-duration="2000"
              data-aos-easing="ease-in-sine"
            >
              <img
                src={require("../assets/customer-review.png")}
                className="w-full md:w-auto md:h-full"
                alt=""
              />
            </div>
            <div
              className="self-center"
              data-aos="fade-left"
              data-aos-duration="2000"
              data-aos-easing="ease-in-sine"
            >
              <p className="text-4xl text-main text-shadow font-bold mb-2 capitalize">
                {t(`point one`)}
              </p>
              <p className="text-lg font-light mb-8 max-w-lg">
                {t(
                  `point one subtext`
                )}
              </p>
            </div>
          </div>
          <div className="grid md:grid-cols-2 gap-8 mb-8">
            <div
              className="self-center order-2 md:order-1"
              data-aos="fade-right"
              data-aos-duration="2000"
              data-aos-easing="ease-in-sine"
            >
              <p className="text-4xl text-main text-shadow font-bold mb-2 capitalize">
                {t(`point two`)}
              </p>
              <p className="text-lg font-light mb-8 max-w-lg">
                {t(
                  `point two subtext`
                )}
              </p>
            </div>
            <div
              className="md:order-2 order-1"
              data-aos="fade-left"
              data-aos-duration="2000"
              data-aos-easing="ease-in-sine"
            >
              <img
                src={require("../assets/google-review.png")}
                className="w-full md:w-auto md:h-full"
                alt=""
              />
            </div>
          </div>
          <div className="grid md:grid-cols-2 gap-8 mb-8">
            <div
              className=""
              data-aos="fade-right"
              data-aos-duration="3000"
              data-aos-easing="ease-in-sine"
            >
              <img
                src={require("../assets/star-rating.png")}
                className="w-full md:w-auto md:h-full"
                alt=""
              />
            </div>
            <div
              className="self-center"
              data-aos="fade-left"
              data-aos-duration="3000"
              data-aos-easing="ease-in-sine"
            >
              <p className="text-4xl text-main text-shadow font-bold mb-2 capitalize">
                {t(`point three`)}
              </p>
              <p className="text-lg font-light mb-8 max-w-lg">
                {t(
                  `point three subtext`
                )}
              </p>
            </div>
          </div>
          <div className="grid md:grid-cols-2 gap-8 mb-8">
            <div
              className="self-center order-2 md:order-1"
              data-aos="fade-right"
              data-aos-duration="3000"
              data-aos-easing="ease-in-sine"
            >
              <p className="text-4xl text-main text-shadow font-bold mb-2 capitalize">
                {t(`point four`)}
              </p>
              <p className="text-lg font-light mb-8 max-w-lg">
                {t(
                  `point four subtext`
                )}
              </p>
            </div>
            <div
              className="md:order-2 order-1"
              data-aos="fade-left"
              data-aos-duration="3000"
              data-aos-easing="ease-in-sine"
            >
              <img
                src={require("../assets/facts.png")}
                className="w-full md:w-auto md:h-full"
                alt=""
              />
            </div>
          </div>
          <div></div>
        </div>
      </section>
      <section id="how-it-works">
        <div className="mt-216 container mx-auto px-4">
          <p className="text-center text-2xl md:text-4xl text-main text-shadow max-w-2xl font-bold mx-auto mb-2 capitalize">
            {t(`how it works`)}
          </p>
          <p className="text-center text-lg font-light mb-8 max-w-xl mx-auto">
            {t(
              `how it works subtitle`
            )}
          </p>
        </div>
        <div className="container mx-auto px-4 space-y-10">
          <div className="bg-mai rounded-xl text-whit">
            <p className="text-4xl mb-8 font-semibold text-main">
              {t(`option one`)}
            </p>
            <p className="text-lg font-light mb-2">
              {t(`option one subtext`)}
            </p>
            <p className="text-lg font-bold mb-4">
              {t(
                `option one tip`
              )}
            </p>
          </div>

          <div className="bg-mai rounded-xl text-whit">
            <p className="text-4xl mb-8 font-semibold text-main">
              {t(`option two`)}
            </p>
            <p className="text-lg font-light">
              {t(
                `option two subtext one`)}
            </p>
            <p className="text-lg font-light mb-4">
              {t(
                `option two subtext two`)}
            </p>
          </div>
          <div></div>
        </div>
      </section>
      <section id="price">
        <div className="container mx-auto px-4">
          <p className="text-center text-2xl md:text-4xl text-main text-shadow max-w-2xl font-bold mx-auto mb-2 capitalize">
            {t(
              `subscription heading`
            )}
          </p>
          <div className="flex justify-center gap-4 mt-10">
            <SubscriptionCard
              price={t(`plan one price`)}
              plan={t(`Basic`)}
              text={t(`plan title`)}
            />
          </div>
        </div>
      </section>
      <section id="faq" className="bg-main py-10 mt-12 px-4">
        <FAQ />
      </section>
      <section id="contact">
        <div className="mt-28 md:container mx-auto px-4">
          <p className="text-center text-2xl md:text-4xl text-main text-shadow max-w-2xl font-bold mx-auto mb-6 capitalize">
            {t(`get in touch`)}
          </p>
          <ContactForm />
        </div>
      </section>
      {/* <Footer /> */}
    </div>
  );
};

export default Home;
