const ReviewButton = ({
	bgColor,
	fontColor,
	fontSize,
	borderRadius,
	title,
}) => {
	return (
		<button
			className={`bg-[${bgColor}] text-[${fontColor}] text-[${fontSize}px] h-10 px-8`}
			style={{ borderRadius: `${borderRadius}px` }}>
			{title || `Submit`}
		</button>
	);
};

export default ReviewButton;
