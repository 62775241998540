import { createSlice } from "@reduxjs/toolkit";
import { DeleteData, EditData } from "./UserReducer";
import { clearErrors } from "./ErrorReducer";
import axios from "axios";
import { toast } from "react-toastify";

let initialState = {
	user: [],
	user_paginate: null,
	subscriber: [],
	subscriber_paginate: null,
	organisation: [],
	organisation_paginate: null,
	admin: [],
	admin_paginate: null,
	isAdded: false,
	isDeleted: null,
	isUpdated: null,
	search: "",
	mainSearch: [],
	search_paginate: null,
	isFound: null,
};

export const detailsSlice = createSlice({
	name: "details",
	initialState,
	reducers: {
		getSearchUser: (state, { payload }) => {
			state.mainSearch =
				payload?.search === state?.search
					? payload?.data || payload
					: state?.mainSearch;
			state.search_paginate =
				payload?.search === state?.search
					? payload?.paginate || null
					: state?.search_paginate;
			state.isFound = true;
		},
		getSearch: (state, { payload }) => {
			state.search = payload?.search || payload;
		},
		resetUserSearch: state => {
			state.search = "";
			state.mainSearch = [];
			state.search_paginate = null;
			state.isFound = false;
		},
		getOrg: (state, { payload }) => {
			state.organisation = payload?.data || payload;
			state.organisation_paginate = payload?.paginate;
		},
		getAdmins: (state, { payload }) => {
			state.admin = payload?.data || payload;
			state.admin_paginate = payload?.paginate;
		},
		getOtherUsers: (state, { payload }) => {
			state.user = payload?.data || payload;
			state.user_paginate = payload?.paginate;
		},
		getSubscriber: (state, { payload }) => {
			state.subscriber = payload?.data || payload;
			state.subscriber_paginate = payload?.paginate;
		},
		addAdmin: (state, { payload }) => {
			let data = payload?.data || payload;
			state.isAdded = true;
			state.admin = [data, ...state?.admin];
		},
		deleteAdmin: (state, { payload }) => {
			let data = payload?.data || payload;
			state.isDeleted = true;
			state.admin = DeleteData(state?.admin, data);
		},
		updateUserData: (state, { payload }) => {
			let data = payload?.data || payload;
			state.isUpdated = true;
			state.admin = EditData(state?.admin, data);
			state.subscriber = EditData(state?.subscriber, data);
			state.organisation = EditData(state?.organisation, data);
			state.user = EditData(state?.user, data);
		},
		detailsFail: state => {
			state.isDeleted = false;
			state.isAdded = false;
			state.isFound = false;
			state.isUpdated = false;
		},
	},
});
export const {
	detailsFail,
	getOrg,
	getAdmins,
	getOtherUsers,
	deleteAdmin,
	addAdmin,
	getSearchUser,
	getSubscriber,
	getSearch,
	resetUserSearch,
	updateUserData,
} = detailsSlice.actions;

export const loadDetailsUsers = data => async dispatch => {
	dispatch(clearErrors());
	try {
		if (data?.search) dispatch(getSearch({ search: data?.search }));
		let res = await axios.get(
			`/api/v1/user/manage-users?type=${data?.type || "user"}${
				data?.search ? `&search=${data?.search}` : ""
			}
			${data?.subscriber ? `&subscriber=${data?.subscriber}` : ""}
			${data?.limit ? `&limit=${data?.limit}` : ""}
			`
		);
		if (data?.search) dispatch(getSearchUser(res.data));
		else if (data?.subscriber) dispatch(getSubscriber(res.data));
		else if (data?.type === "superadmin") dispatch(getAdmins(res.data));
		else if (data?.type === "user") dispatch(getOtherUsers(res.data));
		else if (data?.type === "organisation") dispatch(getOrg(res.data));
	} catch (err) {
		if (err) console.log(err.response?.data?.data, { err });
		if (err?.response?.status === 429) toast.error(err?.response?.data);
		dispatch(detailsFail());
	}
};
