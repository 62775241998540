import { useContext, useEffect, useState } from "react";
import Button from "../../components/button/button";
// import DashboardCard from "../../components/dashboard-card/dashboard-card";
import ModalContainer from "../../components/modal-container/modal-container";
import CardGroup from "../../components/card-group/card-group";
import { PendingReview } from "../home";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import moment from "moment";
import {
	getReviews,
	reviewsUserFail,
} from "../../data/Reducers/ReviewsReducer";
import { toast } from "react-toastify";
import {
	detailsFail,
	updateUserData,
} from "../../data/Reducers/DetailsReducer";
import { returnErrors } from "../../data/Reducers/ErrorReducer";
import ModalComponents, { Buttons } from "../../components/ModalComponents";
import { GlobalState } from "../../data/Context";

const Review = () => {
	const [show, setShow] = useState(false);
	const { reviews } = useSelector(state => state),
		dispatch = useDispatch();
	let tabViews = [
		{
			title: "Pending Review",
			amount: reviews?.user_pending_paginate?.total,
			percent: "+4%",
			icon: "",
		},
		{
			title: "Displayed Review",
			amount: reviews?.user_display_paginate?.total,
			percent: "+4%",
			icon: "",
		},
		{
			title: "Hiding Review",
			amount: reviews?.user_hide_paginate?.total,
			percent: "+4%",
			icon: "",
		},
	];
	let [active, setActive] = useState(tabViews[0]?.title);
	const close = () => {
		setShow(false);
	};
	const toggleModal = () => {
		setShow(!show);
	};

	const location = useLocation(),
		{ details } = useSelector(state => state),
		[data, setData] = useState(null),
		[org, setOrg] = useState(null),
		[loading, setLoading] = useState(null),
		[isUpdate, setIsUpdate] = useState(null);

	useEffect(() => {
		if (location?.state) {
			details?.organisation?.map(
				item => item?._id === location?.state && setData(item)
			);
		}
	}, [location?.state, details?.organisation]);

	useEffect(() => {
		dispatch(reviewsUserFail());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (location?.state) {
			let item = details?.user?.filter(item => item?._id === location?.state);
			setOrg(item);
		}
	}, [location?.state, details?.user]);

	let getUsers = async () => {
		try {
			let res = await axios.get(`/api/v1/user/manage-users/${location?.state}`);
			setOrg(res?.data?.data);
		} catch (error) {
			console.log({ err: error?.message });
		}
		dispatch(getReviews("pending", { user: location?.state }));
		dispatch(getReviews("hide", { user: location?.state }));
		dispatch(getReviews("display", { user: location?.state }));
	};
	useEffect(() => {
		if (location?.state) {
			getUsers();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location?.state, dispatch]);

	const handleSubmit = async e => {
		e?.preventDefault();
		if (!isUpdate) return;
		setLoading("update");
		try {
			let res = await axios.post(
				`/api/v1/user/manage-users/${isUpdate?._id}?type=${
					isUpdate?.status ? "suspend" : "activate"
				}`
			);
			console.log({ resp: res?.data });
			toast.success(res?.data?.msg);
			dispatch(updateUserData(res?.data));
			setIsUpdate(null);
			getUsers();
		} catch (err) {
			if (err?.response?.status === 429 || err?.response?.status === 405)
				toast.error(err?.response?.data ? err?.response?.data : err?.message);
			console.log({ err });
			let error = err.response?.data?.data;
			if (error) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			}
			dispatch(detailsFail());
		}
		setLoading(false);
	};

	if (!data) return <></>;

	return (
		<div>
			<div className="flex justify-end">
				<Button
					buttonType={"primary"}
					children={"View User & Sub User"}
					onClick={toggleModal}
				/>
			</div>
			<div className="w-full">
				<CardGroup
					data={tabViews}
					color
					handleclick={state => {
						setActive(state);
					}}
				/>
			</div>
			<div className="mt-8">
				{active === "Overview" && ""}
				{active === "Pending Review" && (
					<PendingReview
						state={reviews?.user_pending}
						paginate={reviews?.user_pending_paginate}
						title={active}
						type={"pending"}
						user={data?._id}
					/>
				)}
				{active === "Displayed Review" && (
					<PendingReview
						state={reviews?.user_display}
						paginate={reviews?.user_display_paginate}
						title={active}
						type={"display"}
						user={data?._id}
					/>
				)}
				{active === "Hiding Review" && (
					<PendingReview
						state={reviews?.user_hide}
						paginate={reviews?.user_hide_paginate}
						title={active}
						type={"hide"}
						user={data?._id}
					/>
				)}
			</div>

			{/* Modals */}
			<ModalContainer
				show={show}
				close={close}
				title={"Sub-User"}
				width={"max-w-6xl"}>
				<div>
					<form className="w-2/3">
						<label
							for="default-search"
							className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">
							Search
						</label>
						<div className="relative">
							<div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
								<svg
									aria-hidden="true"
									className="w-5 h-5 text-gray-500 dark:text-gray-400"
									fill="none"
									stroke="currentColor"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg">
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
								</svg>
							</div>
							<input
								type="search"
								id="default-search"
								className="block w-full p-4 pl-10 text-sm text-gray-900 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
								// placeholder="Search Mockups, Logos..."
								required
							/>
							{/* <button
                type="submit"
                className="absolute right-2.5 bottom-2.5  focus:ring-4 focus:outline-none focus:ring-mainShade px-4 py-2"
              >
                <MdFilterList size={20} />
              </button> */}
						</div>
					</form>
				</div>
				<OrgUserAll setIsUpdate={setIsUpdate} state={org} />
			</ModalContainer>
			<ModalComponents
				isOpen={isUpdate ? true : false}
				title={`Cancel Subscription`}
				toggle={() => setIsUpdate(null)}>
				<div className="mx-20">
					<form className="space-y-4">
						<div className="my-auto w-100">
							<p className="text2 Lexend text-center">
								Do you want to {isUpdate?.status ? "Suspend" : "Activate"}{" "}
								{isUpdate?.privilege === "organisation" ? (
									<>{isUpdate?.businessName} </>
								) : (
									<>
										{isUpdate?.firstName} {isUpdate?.lastName}{" "}
									</>
								)}
								account?
							</p>
							<div className="pt-4 flex justify-center">
								<Buttons
									buttonType={"primary"}
									title={"Yes"}
									type="button"
									width={"w-fit me-2"}
									loading={loading === "update"}
									onClick={handleSubmit}
								/>
								<Button
									buttonType={"suspended"}
									children={"No"}
									type="button"
									width={"w-fit ms-2"}
									onClick={() => setIsUpdate(null)}
								/>
							</div>
						</div>
					</form>
				</div>
			</ModalComponents>
		</div>
	);
};

export default Review;

export const OrgUserAll = ({ state, setIsUpdate }) => {
	let { t } = useContext(GlobalState);

	return (
		<div className="relative overflow-x-auto sm:rounded-lg">
			<table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
				<thead className="text-sm text-white capitalize bg-[#65D133]">
					<tr>
						<th scope="col" className="px-6 py-3">
							{t(`Name`)}
						</th>
						<th scope="col" className="px-6 py-3">
							{t(`Email`)}
						</th>
						<th scope="col" className="px-6 py-3">
							{t(`Role`)}
						</th>
						<th scope="col" className="px-6 py-3">
							{t(`Company`)}
						</th>
						<th scope="col" className="px-6 py-3">
							{t("Date")}
						</th>
						<th scope="col" className="px-6 py-3 text-center">
							{t("Status")}
						</th>
					</tr>
				</thead>
				<tbody>
					{state?.map((item, i) => (
						<tr
							key={i}
							className="bg-white dark:bg-gray-800 hover:bg-gray-50 text-xs">
							<th
								scope="row"
								className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
								{item?.lastName} {item?.firstName}
							</th>
							<td className="px-6 py-4">{item?.email}</td>
							<td className="px-6 py-4 capitalize">{item?.privilege}</td>
							<td className="px-6 py-4 capitalize">
								{item?.businessName || item?.organisation?.businessName}
							</td>
							<td className="px-6 py-4">
								{moment(item?.createdAt).format("DD/MM/YYYY hh:mm A")}
							</td>
							<td className="px-6 py-4 text-right">
								<Button
									buttonType={item?.status ? "primary" : "suspended"}
									children={
										item?.status ? "Active" : item?.statusText || "inactive"
									}
									width={"capitalize w-32"}
									onClick={setIsUpdate ? () => setIsUpdate(item) : null}
								/>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};
