import { IoIosClose,IoIosAdd } from "react-icons/io";
import { useState } from "react";

const Benefits = ({ title }) => {
	// eslint-disable-next-line no-unused-vars
	const [show, setShow] = useState(false);

	return (
		<div
			data-aos="zoom-in-up"
			data-aos-duration="2000"
			className="flex justify-between gap-8 items-center container py-4 px-8 text-dark bg-white border border-main mx-auto rounded-md mb-4">
			<div className="flex gap-8">
				<article className="">
					<p className="text-sm">{title}</p>
					<p
						className={`text-sm mt-4 ${
							!show && "hidden"
						}`}>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam,
						purus sit amet luctus venenatis, lectus magna fringilla urna,
						porttitor
					</p>
				</article>
			</div>
			<span className="cursor-pointer text-tetiary text-main" onClick={() => setShow(!show)}>
				{show ? <IoIosClose /> : <IoIosAdd />}
			</span>
		</div>
	);
};

export default Benefits;
