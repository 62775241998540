import { useContext, useState } from "react";
import { Buttons } from "../ModalComponents";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { returnErrors } from "../../data/Reducers/ErrorReducer";
import { useTranslation } from "react-i18next";
import { fetchMachineTranslation } from "../../i18n";
import { useEffect } from "react";
import { GlobalState } from "../../data/Context";

const ContactForm = () => {
	let { t } = useContext(GlobalState);

	let init = { lastName: "", firstName: "", email: "", message: "" },
		[state, setState] = useState(init),
		[loading, setLoading] = useState(false),
		textChange = e => {
			let { value, name } = e.target;
			setState({ ...state, [name]: value });
		},
		dispatch = useDispatch(),
		handleSubmit = async e => {
			e?.preventDefault();
			if (
				!state?.email ||
				!state?.email ||
				!state?.firstName ||
				!state?.message
			)
				return toast.info("Please fill out all fields");

			setLoading(true);
			try {
				let res = await axios.put(`/api/v1/customize/feedback`, {
					...state,
				});
				toast.success(res?.data?.msg);
				setState(init);
			} catch (err) {
				if (err) console.log(err.response?.data?.data, { err });
				if (err?.response?.status === 429) toast.error(err?.response?.data);
				let error = err.response?.data?.data;
				if (error) {
					dispatch(returnErrors({ error, status: err?.response?.status }));
				}
			}
			setLoading(false);
		};

	return (
		<div className="max-w-xl mx-auto p-4 rounded-lg bg-[#F5F6FB]">
			<form className="max-w-lg mx-auto">
				<div className="grid grid-cols-2 gap-x-8 gap-y-4">
					<div>
						<label className="text-[#2B3E39] font-light text-sm">
							{t(`First Name`)}
						</label>
						<input
							type="text"
							className="w-full h-10 md:h-12 px-4 rounded-lg bg-[#D1E5FF]"
							placeholder="John"
							value={state?.firstName}
							onChange={textChange}
							name="firstName"
						/>
					</div>
					<div>
						<label className="text-[#2B3E39] font-light text-sm">
							{t(`Last Name`)}
						</label>
						<input
							type="text"
							className="w-full h-10 md:h-12 px-4 rounded-lg bg-[#D1E5FF]"
							placeholder="Smith"
							value={state?.lastName}
							onChange={textChange}
							name="lastName"
						/>
					</div>
					<div className="col-span-2">
						<label className="text-[#2B3E39] font-light text-sm">
							{t(`Email`)}
						</label>
						<input
							type="email"
							className="w-full h-10 md:h-12 px-4 rounded-lg bg-[#D1E5FF]"
							placeholder="johnsmith@gmail.com"
							value={state?.email}
							onChange={textChange}
							name="email"
						/>
					</div>
					<div className="col-span-2">
						<label className="text-[#2B3E39] font-light text-sm">
							{t(`Message`)}
						</label>
						<textarea
							rows={2}
							className="w-full p-4 rounded-lg bg-[#D1E5FF]"
							placeholder={t("Please type your message here...")}
							value={state?.message}
							onChange={textChange}
							name="message"
							style={{
								resize: "none",
								height: "10rem",
							}}
						/>
					</div>
				</div>
				<div className="mt-8">
					{/* <button
						className={`h-12 px-4 rounded-md font-semibold text-sm whitespace-nowrap bg-[#2B3E39] text-white`}>
						Contact Us
					</button> */}
					<Buttons
						bg="bg-[#2B3E39]"
						css={`h-12 px-4 rounded-md font-semibold text-sm whitespace-nowrap bg-[#2B3E39] text-white`}
						onClick={handleSubmit}
						loading={loading}
						title={t("Contact Us")}
						width={"auto"}
					/>
				</div>
			</form>
		</div>
	);
};

export default ContactForm;
