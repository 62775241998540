// import Benefits from "../components/benefits/benefits";
import Button from "../components/button/button";
import ContactForm from "../components/contact-form/contact-form";
// import CustomerReview from "../components/customer-review/customer-review";

import pat from "../assets/pattern.png";

const Home = () => {
  return (
    <div className="overflow-x-hidden">
      <header className="gradient relative">
        <div
          className="absolute inset-0 -top-20"
          style={{
            background: `url(${pat}) no-repeat`,
            backgroundSize: "cover",
          }}
        ></div>
        <div className="container mx-auto px-4 py-10 relative z-10">
          <div className="grid md:grid-cols-2 gap-8">
            <div
              className="text-white self-center"
              data-aos="fade-right"
              data-aos-duration="3000"
              data-aos-easing="ease-in-sine"
            >
              <p className="text-5xl font-bold mb-4 text-shadow">
                Flash review your number one Business Review Solution
              </p>
              <p className="text-lg font-light mb-8">
                Add reviews to your website, Flash Review makes it easy to
                display reviews on your website and manage all your social proof
                on one simple platform.
              </p>
              <div>
                <Button buttonType={"white"} children={"Get Started"} />
              </div>
            </div>
            <div
              className="relative"
              data-aos="fade-left"
              data-aos-duration="3000"
              data-aos-easing="ease-in-sine"
            >
              <img
                src={require("../assets/hero-img.png")}
                className="h-full"
                alt=""
              />
              <img
                src={require("../assets/star.png")}
                className="absolute w-20 right-0 -md:right-20 bottom-0"
                alt=""
              />
            </div>
          </div>
        </div>
      </header>
      <div className="max-w-xl mx-auto relative z-5">
        <img
          src={require("../assets/link-image.png")}
          className="w-full -mt-14"
          alt=""
        />
      </div>
      {/* <section>
        <div className="text-center mt-28">
          <p className="text-3xl text-main text-shadow max-w-2xl font-bold mx-auto mb-2 capitalize">
            Trusted by +10,000 users from +100 countries
          </p>
          <p className="text-md font-light mb-8 max-w-xl mx-auto">
            Lorem ipsum dolor sit amet consectetur. In donec suspendisse
            ultricies id tortor purus mauris. Ligula molestie sed vivamus odio
          </p>
        </div>
        <div className="container ml-auto px-4">
          <marquee behavior="" direction="left">
            <img
              src={require("../assets/ico-1.png")}
              className="h-8 inline-block mr-20"
              alt=""
            />
            <img
              src={require("../assets/ico-2.png")}
              className="h-8 inline-block mr-20"
              alt=""
            />
            <img
              src={require("../assets/ico-3.png")}
              className="h-8 inline-block mr-20"
              alt=""
            />
            <img
              src={require("../assets/ico-4.png")}
              className="h-8 inline-block mr-20"
              alt=""
            />
            <img
              src={require("../assets/ico-5.png")}
              className="h-8 inline-block mr-20"
              alt=""
            />
            <img
              src={require("../assets/ico-6.png")}
              className="h-8 inline-block mr-20"
              alt=""
            />
            <img
              src={require("../assets/ico-7.png")}
              className="h-8 inline-block mr-20"
              alt=""
            />
            <img
              src={require("../assets/ico-8.png")}
              className="h-8 inline-block mr-20"
              alt=""
            />
          </marquee>
        </div>
      </section> */}
      <section>
        <div className="mt-28 container mx-auto px-4">
          <p className="text-center text-3xl text-main text-shadow max-w-2xl font-bold mx-auto mb-2 capitalize">
            earn customer reviews
          </p>
          <p className="text-center text-md font-light mb-8 max-w-xl mx-auto">
            Over 1,000 businesses use Flash Review everyday to earn customer
            reviews and grow their business online.
          </p>
          <div className="grid md:grid-cols-2 gap-8 mb-12">
            <div
              className=""
              data-aos="fade-right"
              data-aos-duration="2000"
              data-aos-easing="ease-in-sine"
            >
              <img
                src={require("../assets/customer-review.png")}
                className="w-full md:w-auto md:h-full"
                alt=""
              />
            </div>
            <div
              className="self-center"
              data-aos="fade-left"
              data-aos-duration="2000"
              data-aos-easing="ease-in-sine"
            >
              <p className="text-4xl text-main text-shadow font-bold mb-2 capitalize">
                easiest marketing strategy
              </p>
              <p className="text-lg font-light mb-8 max-w-lg">
                Customer reviews are the single most effective bit of marketing
                you can do for your company. 88% of consumers trust online
                reviews as much as a personal recommendation, and help them
                determine the quality of a local business.
              </p>
            </div>
          </div>
          <div className="grid md:grid-cols-2 gap-8 mb-12">
            <div
              className="self-center order-2 md:order-1"
              data-aos="fade-right"
              data-aos-duration="2000"
              data-aos-easing="ease-in-sine"
            >
              <p className="text-4xl text-main text-shadow font-bold mb-2 capitalize">
                increase trust in brand
              </p>
              <p className="text-lg font-light mb-8 max-w-lg">
                Approximately 93% of consumers report that online reviews impact
                their purchasing decisions. Positive reviews can significantly
                influence a consumer's decision to buy a product or service.
              </p>
            </div>
            <div
              className="md:order-2 order-1"
              data-aos="fade-left"
              data-aos-duration="2000"
              data-aos-easing="ease-in-sine"
            >
              <img
                src={require("../assets/google-review.png")}
                className="w-full md:w-auto md:h-full"
                alt=""
              />
            </div>
          </div>
          <div className="grid md:grid-cols-2 gap-8 mb-12">
            <div
              className=""
              data-aos="fade-right"
              data-aos-duration="3000"
              data-aos-easing="ease-in-sine"
            >
              <img
                src={require("../assets/star-rating.png")}
                className="w-full md:w-auto md:h-full"
                alt=""
              />
            </div>
            <div
              className="self-center"
              data-aos="fade-left"
              data-aos-duration="3000"
              data-aos-easing="ease-in-sine"
            >
              <p className="text-4xl text-main text-shadow font-bold mb-2 capitalize">
                reaching new customer
              </p>
              <p className="text-lg font-light mb-8 max-w-lg">
                91% of consumers are more likely to use a business with a 4-star
                rating or higher. Maintaining a high average star rating is
                crucial for attracting customers.
              </p>
            </div>
          </div>
          <div className="grid md:grid-cols-2 gap-8 mb-12">
            <div
              className="self-center order-2 md:order-1"
              data-aos="fade-right"
              data-aos-duration="3000"
              data-aos-easing="ease-in-sine"
            >
              <p className="text-4xl text-main text-shadow font-bold mb-2 capitalize">
                Boost your sales
              </p>
              <p className="text-lg font-light mb-8 max-w-lg">
                Businesses with positive reviews see a boost in sales. In fact,
                a study found that just a one-star increase in a business's Yelp
                rating can lead to a 5-9% increase in revenue.
              </p>
            </div>
            <div
              className="md:order-2 order-1"
              data-aos="fade-left"
              data-aos-duration="3000"
              data-aos-easing="ease-in-sine"
            >
              <img
                src={require("../assets/facts.png")}
                className="w-full md:w-auto md:h-full"
                alt=""
              />
            </div>
          </div>
          {/* <div className="grid md:grid-cols-5 gap-8 mb-12">
            <div
              className="col-span-2"
              data-aos="fade-right"
              data-aos-duration="3000"
              data-aos-easing="ease-in-sine"
            >
              <img
                src={require("../assets/rank-higher.png")}
                className="w-full md:w-auto md:h-full"
                alt=""
              />
            </div>
            <div
              className="col-span-3 self-center"
              data-aos="fade-left"
              data-aos-duration="3000"
              data-aos-easing="ease-in-sine"
            >
              <p className="text-4xl text-main text-shadow font-bold mb-2 capitalize">
                customer reviews
              </p>
              <p className="text-lg font-light mb-8 max-w-lg">
                Customer reviews are the single most effective bit of marketing
                you can do for your company. 88% of consumers trust online
                reviews as much as a personal recommendation, and help them
                determine the quality of a local business.
              </p>
            </div>
          </div> */}
        </div>
      </section>
      {/* <section>
        <div className="mt-28 container mx-auto px-4">
          <p className="text-center text-3xl text-main text-shadow max-w-2xl font-bold mx-auto mb-2 capitalize">
            Frequently Asked Questions
          </p>
          <p className="text-center text-md font-light mb-8 max-w-xl mx-auto">
            Over 3,600+ businesses use Flash Review everyday to earn customer
            reviews and grow their business online.
          </p>
        </div>
        <div className="grid gap-y-8 px-4">
          <Benefits
            title={"How Real Businesses Benefit From Google Reviews?"}
          />
          <Benefits
            title={"How Real Businesses Benefit From Google Reviews?"}
          />
          <Benefits
            title={"How Real Businesses Benefit From Google Reviews?"}
          />
        </div>
      </section> */}
      {/* <section>
        <div className="mt-28 container mx-auto px-4">
          <p className="text-center text-3xl text-main text-shadow max-w-2xl font-bold mx-auto mb-2 capitalize">
            Here’s what our customers say
          </p>
          <p className="text-center text-lg font-light mb-8 max-w-xl mx-auto">
            This widget is made with our app.
          </p>
        </div>
        <div className="flex justify-center mt-8">
          <Button buttonType={"second"} children="Get Started" />
        </div>
        <div className="container ml-auto pl-4 overflow-x-scroll ">
          <div className="flex mt-16 gap-x-9 p-4 w-[1500px]">
            <CustomerReview
              text={"An easy-to-use software that serves its purpose."}
            />
            <CustomerReview
              text={"It was very easy to use and works perfectly"}
            />
            <CustomerReview
              text={
                "The user interface is simple to use and works as promised. Trustmary’s team has a customer-oriented approach in everything they do. Thank you for the great collaboration!"
              }
            />
            <CustomerReview
              text={
                "The user interface is simple to use and works as promised. Trustmary’s team has a customer-oriented approach in everything they do. Thank you for the great collaboration!"
              }
            />
            <CustomerReview
              text={
                "The user interface is simple to use and works as promised. Trustmary’s team has a customer-oriented approach in everything they do. Thank you for the great collaboration!"
              }
            />
          </div>
        </div>
        <div className="container rounded-2xl mx-auto mt-16 p-8 md:py-24 gradient relative">
          <div
            className="absolute inset-0 rounded-2xl"
            style={{
              background: `url(${pat}) no-repeat`,
              backgroundSize: "cover",
            }}
          ></div>
          <div className="relative z-5">
            <p className="text-center text-2xl md:text-4xl text-white text-shadow max-w-2xl font-bold mx-auto mb-2 capitalize">
              Get start Now
            </p>
            <p className="text-center text-sm font-light text-white mb-8 max-w-xl mx-auto">
              Make the most of your online reputation and earn more business
              from people looking for your service.
            </p>
            <div className="flex justify-center mt-8">
              <Button buttonType={"white"} children="Get Started" />
            </div>
          </div>
        </div>
      </section> */}
      <section>
        <div className="mt-28 container mx-auto px-4">
          <p className="text-center text-2xl md:text-4xl text-main text-shadow max-w-2xl font-bold mx-auto mb-6 capitalize">
            Get In Touch Today
          </p>
          {/* <p className="text-center text-sm font-light mb-8 max-w-xl mx-auto">
            Lorem ipsum dolor sit amet consectetur. In donec suspendisse
            ultricies id tortor purus mauris. Ligula molestie sed vivamus odio
          </p> */}
          <ContactForm />
        </div>
      </section>
      {/* <Footer /> */}
    </div>
  );
};

export default Home;
