import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import en from "./translations/en";
// import fr from "./translations/frn";
// import LanguageDetector from "i18next-browser-languagedetector";
// import axios from "axios";

export const detectLanguage = () => {
  const userLang = navigator.language || navigator.userLanguage;
  return userLang.startsWith("fr") ? "fr" : "en";
};

export const fetchMachineTranslation = async (language, keys) => {
// 	try {
// 		// if (process.env.NODE_ENV !== "production") {
// 		// 	const response = await axios.post(
// 		// 		`https://translation.googleapis.com/language/translate/v2?key=${process.env.REACT_APP_GOOGLE_API_KEY}`,
// 		// 		{
// 		// 			q: keys.join("++"),
// 		// 			target: language,
// 		// 		},
// 		// 		{ baseURL: null }
// 		// 	);

// 		// 	const data = await response.data;

// 		// 	return data?.data?.translations?.map(translation =>
// 		// 		translation?.translatedText
// 		// 			?.replace(/&#39;/g, `'`)
// 		// 			?.replace(/&quot;/g, `"`)
// 		// 	);
// 		// } else {
// 		let res = await axios.post("/translate", { language, keys });

// 		return res?.data?.data;
// 		// }
// 	} catch (error) {
// 		console.log({ error });
// 		return [keys?.join("++")];
// 	}
};

i18n
  .use({
    type: "languageDetector",
    detect: detectLanguage,
    init: () => {},
    cacheUserLanguage: () => {},
  })
  .use(initReactI18next)
  .init({
    lng: detectLanguage(),
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: require("./translations/en/global.json"),
      },
      fr: {
        translation: require("./translations/frn/global.json"),
      },
    }, // We'll fetch and provide resources dynamically
  });

export default i18n;
