import { ClipLoader } from "react-spinners";
import OtpInput from "react18-otp-input";
import ReactPaginate from "react-paginate";
import { useTranslation } from "react-i18next";
import { fetchMachineTranslation } from "../i18n";
import { useContext, useEffect } from "react";
import { GlobalState } from "../data/Context";

const ModalComponents = ({ isOpen, toggle, title, children }) => {
	const { t, i18n } = useTranslation();
	let { lang } = useContext(GlobalState);

	useEffect(() => {
		const fetchResources = async () => {
			const translationKeys = [title];

			const translations = await fetchMachineTranslation(
				lang || i18n.language,
				translationKeys
			);

			const delimetedTrans = translations?.[0]?.split("++");

			const translationResources = {};
			for (let i = 0; i < translationKeys.length; i++) {
				translationResources[translationKeys?.[i]] = delimetedTrans?.[i];
			}

			i18n.addResourceBundle(
				i18n.language,
				"translation",
				translationResources
			);
		};

		fetchResources();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [i18n.language, lang]);

	return (
		<>
			<div
				id="demo-modal"
				className={`modal ${isOpen ? "open" : ""}`}
				style={{
					zIndex: "999",
				}}>
				<div className="modal__content">
					<h3 className="mb-2 modal__title text-main">{t(title)}</h3>
					<div
						className="overflow-y-auto p-2"
						style={{
							maxHeight: "75vh",
						}}>
						{children}
					</div>
					<span className="modal__close" onClick={toggle}>
						&times;
					</span>
				</div>
			</div>
		</>
	);
};

export default ModalComponents;

export const Buttons = ({
	type,
	loading,
	width,
	css,
	title,
	children,
	onClick,
	loadCss,
	style,
	disabled,
	...restProps
}) => {
	
	const { t, i18n } = useTranslation();

	let {lang} =useContext(GlobalState)

	useEffect(() => {
		const fetchResources = async () => {
			const translationKeys = [`log in`, title];

			const translations = await fetchMachineTranslation(
				lang || i18n.language,
				translationKeys
			);

			const delimetedTrans = translations?.[0]?.split("++");

			const translationResources = {};
			for (let i = 0; i < translationKeys.length; i++) {
				translationResources[translationKeys?.[i]] = delimetedTrans?.[i];
			}

			i18n.addResourceBundle(
				i18n.language,
				"translation",
				translationResources
			);
		};

		fetchResources();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [i18n.language, lang]);

	return (
		<button
			disabled={loading || disabled}
			type={type || "submit"}
			style={style || {}}
			onClick={onClick ? onClick : () => {}}
			className={`${css || ""} capitalize
			 flex items-center justify-center ${
					width || "w-100"
				} inline-block w-60 rounded ${
				restProps?.bg ||
				" hover:bg-main bg-[#65D133] focus:bg-main active:bg-main"
			} ${
				restProps?.color || " text-white"
			} px-7 pb-2.5 pt-3 text-sm font-medium uppercase leading-normal shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]`}
			{...restProps}>
			{children}
			<span className={loading ? "me-2" : ""}>{t(title) || t("log in")}</span>
			{loading && <ClipLoader color={loadCss || "white"} size={16} />}
		</button>
	);
};

export const OtpComponent = ({
  stateData,
  textChange,
  numInputs,
  separator,
  css,
  loading,
}) => {
  return (
    <>
      <OtpInput
        value={stateData}
        onChange={(otp) => textChange(otp)}
        numInputs={numInputs || 6}
        separator={separator || <span>-</span>}
        inputStyle={`${css} otp-code__input`}
        isDisabled={loading}
        shouldAutoFocus={true}
        isInputNumber={true}
      />
    </>
  );
};

export const LoadMore = ({ handleLoadMore, next, loading }) => {
  return (
    <>
      {!next ? (
        ""
      ) : (
        <Buttons
          onClick={handleLoadMore}
          title={loading ? "Loading..." : "Load More"}
          loading={loading}
          css="mx-auto"
          type={"button"}
        />
      )}
    </>
  );
};

export const MainPaginate = ({ handlePageClick, pageCount }) => (
  <ReactPaginate
    breakLabel="..."
    nextLabel=">"
    onPageChange={handlePageClick}
    pageRangeDisplayed={5}
    pageCount={pageCount}
    previousLabel="<"
    renderOnZeroPageCount={null}
    className="list-none flex items-center justify-end p-3"
    pageClassName="mx-1 p-2 border rounded text-capitalize no-underline"
    previousClassName="no-underline hidden"
    nextClassName="no-underline hidden"
    activeClassName="bg-[#51ff0024]"
  />
);

export const MainRanger = ({ range, setRange }) => {
  let rangeArr = [50, 100, 200, 500, 1000];

  return (
    <div className="py-3">
      <div className="col-3 col-md-1">
        <select
          className="form-control py-2 form-select rounded min-h-[auto] p-3 leading-[1.6] outline-none placeholder:opacity-60"
          name="range"
          value={range}
          onChange={(e) => {
            setRange(Number(e.target.value));
          }}
        >
          {rangeArr?.map((item, i) => (
            <option key={i} value={item}>
              {item}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
