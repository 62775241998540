import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Input from "../../components/input/input";
import { Buttons } from "../../components/ModalComponents";
import { GlobalState } from "../../data/Context";
import axios from "axios";
import { toast } from "react-toastify";
import { ReuseBox } from "../otp";

const ForgotPassword = () => {
	const { returnErrors } = useContext(GlobalState);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [formValue, setFormValue] = useState(""),
		[loading, setLoading] = useState(false);

	const handleChange = e => {
		const { name, value } = e.target;
		setFormValue({ ...formValue, [name]: value });
	};

	let { t } = useContext(GlobalState);

	const handleSubmit = async e => {
		e?.preventDefault();
		if (!formValue?.email) return;
		setLoading(true);
		try {
			let res = await axios.put(`/api/v1/user/reset-password`, {
				...formValue,
			});

			setLoading(false);
			toast.success(res?.data?.msg);
			navigate(`/forgot-password/verify`, {
				state: { email: formValue?.email },
			});
		} catch (err) {
			if (err) console.log(err.response?.data?.data, { err });
			if (err?.response?.status === 429) toast.error(err?.response?.data);
			let error = err.response?.data?.data;
			dispatch(returnErrors({ error, status: err?.response?.status }));
			setLoading(false);
		}
		setLoading(false);
	};

	return (
		<ReuseBox>
			<div className="max-w-md mx-auto px-4 self-center">
				<div className="text-center mb-8 max-w-md mx-auto">
					<p className="text-2xl font-semibold">{t(`Forgot password?`)}</p>
					<p className="text-sm text-main">
						{t(
							`Enter your email address and we'll send you a link to reset your password.`
						)}
					</p>
				</div>
				<form onSubmit={handleSubmit} className="max-w-md mx-auto">
					{/* <!-- Email input --> */}
					<Input
						type={"email"}
						label={"E-mail"}
						onChange={handleChange}
						name="email"
						value={formValue.email}
					/>
					{/* <!-- Submit button --> */}
					<div className="space-y-4">
						<Buttons
							onClick={handleSubmit}
							title={"Send Mail"}
							type={"submit"}
							loading={loading}
							css="inline-block w-full rounded bg-main px-7 pb-2.5 pt-3 text-sm font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-main hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-main focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-main active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
						/>
					</div>
					<p className="text-xs text-center mt-4">
						<Link to="/login" className="">
							{t(`Back to login`)}
						</Link>
					</p>
				</form>
			</div>
		</ReuseBox>
	);
};

export default ForgotPassword;
