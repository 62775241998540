import { useContext, } from "react";
import AllSection from "../components/all-section/all-section";
import { GlobalState } from "../data/Context";

const HowItWorks = () => {
	let { t } = useContext(GlobalState);

	return (
		<div>
			<div className="mt-28 container mx-auto px-4">
				<p className="text-center text-2xl md:text-4xl text-main text-shadow max-w-2xl font-bold mx-auto mb-2 capitalize">
					{t(`How FlashReview Works`)}
				</p>
				<p className="text-center text-sm font-light mb-8 max-w-xl mx-auto">
					{t(`Simple for you, convenient for your customers.`)}
				</p>
			</div>
			<div className="mt-28 container mx-auto px-4">
				<p className="text-center text-2xl md:text-4xl text-main text-shadow max-w-2xl mx-auto mb-2 capitalize">
					01
				</p>
				<p className="text-center text-sm font-light mb-8 mx-auto">
					{t(
						`FLASHREVIEW provides you with a customized, dedicated business page on our platform with a simple URL link. You can share this link with all of your customers without vetting them first to make sure they were happy or asking them how their experience was before building up the courage to ask them to leave a review (yes, we know the pain). We handle the vetting process so you don't have to.`
					)}
				</p>
			</div>
			<div className="mt-28 container mx-auto px-4">
				<p className="text-center text-2xl md:text-4xl text-main text-shadow max-w-2xl mx-auto mb-2 capitalize">
					02
				</p>
				<p className="text-center text-sm font-light mb-8 mx-auto">
					{t(
						`Once your customer visits your FLASHREVIEW page, they'll be asked if they would recommend you to family or friends. If they choose Yes, then they'll be directed to your review portal where they can choose where to leave your business a review based on the platforms you selected to show on your page. If they choose No, then they'll be directed to a feedback page where they can fill out a form detailing their experience. The form is internal only and submissions will only be seen by you via email.`
					)}
				</p>
			</div>
			<div className="mt-28 container mx-auto px-4">
				<p className="text-center text-2xl md:text-4xl text-main text-shadow max-w-2xl mx-auto mb-2 capitalize">
					03
				</p>
				<p className="text-center text-sm font-light mb-8 mx-auto">
					{t(
						`Do you know the main reason businesses have a hard time convincing customers to leave reviews, even if they had a great experience? It's because people are lazy! By making the reviewing process simple, and effortless, we're effectively encouraging customers to leave your business a review. FLASHREVIEW literally allows you to serve your customers all of your review channels on a golden platter. Once you sign up for FLASHREVIEW, you get to focus on your business (or sit back and relax), while watching your reviews roll in without worrying about negative reviews landing on your business pages. We handle the vetting process, filtering and redirecting of the negatives reviews and only allow satisfied customers the opportunity to leave you positive reviews.`
					)}
				</p>
			</div>
			<AllSection />
		</div>
	);
};

export default HowItWorks;
