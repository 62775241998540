// import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import cardBg from "../../assets/card-bg.png";
import { GlobalState } from "../../data/Context";

const DashboardCard = ({
	amountOne,
	amountTwo,
	title,
	titleTwo,
	subTitle,
	color,
	handleclick,
}) => {
	// const navigate = useNavigate();
	let { numberWithCommas, t } = useContext(GlobalState);
	return (
		<div
			style={{ backgroundImage: `url(${cardBg})` }}
			className={`max-w-md w-full mx-auto p-6 bg-[${
				color || "#E2CC29"
			}] border border-gray-200 rounded-lg shadow text-white cursor-pointer flex flex-col justify-between`}
			onClick={handleclick}>
			<div>
				<p>{t(titleTwo) || ""}</p>
				<p className="font-light text-3xl">{amountOne}</p>
				<p>{t(title) || ""}</p>
				<p>{t(subTitle) || ""}</p>
			</div>
			<div className="mt-4 flex">
				<div>
					<p className="font-light text-3xl">
						{numberWithCommas(amountTwo || 0)}
					</p>
					<p className="text-sm font-light text-white">{t("Total Review")}</p>
				</div>
				<div></div>
			</div>
		</div>
	);
};

export default DashboardCard;
