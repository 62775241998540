import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { loadCustomizedPage } from "../data/Reducers/CustomizeReducer";
import { CustomizedPage } from "../pages/customization";

const Customize = () => {
	let { customize } = useSelector(state => state),
		dispatch = useDispatch(),
		{ id } = useParams(),
		[state, setState] = useState(null);

	useEffect(() => {
		if (id) {
			dispatch(loadCustomizedPage("get", id));
		}
	}, [id, dispatch]);

	useEffect(() => {
		if (customize?.page) setState(customize?.page);
	}, [customize?.page]);

	if (!state) return <></>;

	return (
		<>
			<CustomizedPage state={state} reviews={customize?.reviews} />
		</>
	);
};

export default Customize;
