// import { useNavigate } from "react-router-dom";
import DashboardCard from "../dashboard-card/dashboard-card";
const colors = ["#E2CC29", "#38892D", "#FF6700"];

const CardGroup = ({ data, color, link, handleclick }) => {
	// const navigate = useNavigate();
	return (
		<div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4 mt-4 mb-8">
			{data?.map((item, idx) => (
				<DashboardCard
					amountOne={item.number}
					amountTwo={item.amount}
					title={item.title}
					titleTwo={item.titleTwo}
					subTitle={item.subTitle}
					color={color && colors[idx]}
					handleclick={() => handleclick(item.title)}
				/>
			))}
		</div>
	);
};

export default CardGroup;
