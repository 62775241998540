import { createSlice } from "@reduxjs/toolkit";
import { DeleteData } from "./UserReducer";
import { clearErrors, returnErrors } from "./ErrorReducer";
import axios from "axios";
import { toast } from "react-toastify";
import { addNewUserPlatform } from "./PlatformReducer";

let initialState = {
	display: [],
	hide: [],
	pending: [],
	isUpdated: false,
	isDeleted: false,
	display_paginate: null,
	hide_paginate: null,
	pending_paginate: null,
	user_display: [],
	user_hide: [],
	user_pending: [],
	user_display_paginate: null,
	user_hide_paginate: null,
	user_pending_paginate: null,
	isFound: false,
	mainSearch: [],
	search_paginate: null,
	search: "",
};

export const reviewSlice = createSlice({
	name: "reviews",
	initialState,
	reducers: {
		getOrgSearchReview: (state, { payload }) => {
			state.mainSearch =
				payload?.search === state?.search
					? payload?.data || payload
					: state?.mainSearch;
			state.search_paginate =
				payload?.search === state?.search
					? payload?.paginate || null
					: state?.search_paginate;
			state.isFound = true;
		},
		getSearch: (state, { payload }) => {
			state.search = payload?.search || payload;
		},
		resetReview: state => {
			state.search = "";
			state.mainSearch = [];
			state.search_paginate = null;
			state.isFound = false;
		},
		getPendingReviews: (state, { payload }) => {
			state.pending = payload?.data || payload;
			state.pending_paginate = payload?.paginate;
		},
		getHideReviews: (state, { payload }) => {
			state.hide = payload?.data || payload;
			state.hide_paginate = payload?.paginate;
		},
		getDisplayReviews: (state, { payload }) => {
			state.display = payload?.data || payload;
			state.display_paginate = payload?.paginate;
		},
		getPendingUserReviews: (state, { payload }) => {
			state.user_pending = payload?.data || payload;
			state.user_pending_paginate = payload?.paginate;
		},
		getHideUserReviews: (state, { payload }) => {
			state.user_hide = payload?.data || payload;
			state.user_hide_paginate = payload?.paginate;
		},
		getDisplayUserReviews: (state, { payload }) => {
			state.user_display = payload?.data || payload;
			state.user_display_paginate = payload?.paginate;
		},
		updateReviews: (state, { payload }) => {
			let data = payload?.data || payload;
			if (payload?.from === "pending") {
				state.pending = DeleteData(state?.pending, data);
				state.pending_paginate = {
					...state?.pending_paginate,
					total: state?.pending_paginate?.total
						? state?.pending_paginate?.total - 1
						: 0,
				};
			}
			if (payload?.from === "hide") {
				state.hide = DeleteData(state?.hide, data);
				state.hide_paginate = {
					...state?.hide_paginate,
					total: state?.hide_paginate?.total
						? state?.hide_paginate?.total - 1
						: 0,
				};
			}
			if (payload?.from === "display") {
				state.display = DeleteData(state?.display, data);
				state.display_paginate = {
					...state?.display_paginate,
					total: state?.display_paginate?.total
						? state?.display_paginate?.total - 1
						: 0,
				};
			}
			if (payload?.to === "pending") {
				state.pending = [data, ...state?.pending];
				state.pending_paginate = {
					...state?.pending_paginate,
					total: state?.pending_paginate?.total
						? state?.pending_paginate?.total + 1
						: 1,
				};
			}
			if (payload?.to === "hide") {
				state.hide = [data, ...state?.hide];
				state.hide_paginate = {
					...state?.hide_paginate,
					total: state?.hide_paginate?.total
						? state?.hide_paginate?.total + 1
						: 1,
				};
			}
			if (payload?.to === "display") {
				state.display = [data, ...state?.display];
				state.display_paginate = {
					...state?.display_paginate,
					total: state?.display_paginate?.total
						? state?.display_paginate?.total + 1
						: 1,
				};
			}
			state.isUpdated = true;
		},
		reviewsFail: state => {
			state.isDeleted = false;
			state.isAdded = false;
			state.isUpdated = false;
			state.isFound = false;
		},
		reviewsUserFail: state => {
			state.user_display = [];
			state.user_display_paginate = null;
			state.hide_display = [];
			state.hide_display_paginate = null;
			state.pending_display = [];
			state.pending_display_paginate = null;
		},
	},
});
export const {
	reviewsFail,
	getDisplayReviews,
	getHideReviews,
	getPendingReviews,
	updateReviews,
	getDisplayUserReviews,
	getHideUserReviews,
	getPendingUserReviews,
	reviewsUserFail,
	resetReview,
	getOrgSearchReview,
	getSearch,
} = reviewSlice.actions;

export const getReviews = (type, data) => async dispatch => {
	dispatch(clearErrors());
	try {
		if (data?.search || data?.platform)
			dispatch(getSearch({search:data?.search || data?.platform}));
		let res = await axios.get(
			`/api/v1/review?type=${type}${
				data?.limit ? `&limit=${data?.limit}` : ""
			}${data?.user ? `&user=${data?.user}` : ""}${
				data?.platform ? `&platform=${data?.platform}` : ""
			}${data?.search ? `&search=${data?.search}` : ""}`
		);
		if (data?.search || data?.platform)
			dispatch(
				getOrgSearchReview({
					...res?.data,
					search: data?.search ? res?.data?.search : data?.platform,
				})
			);
		else {
			if (type === "pending") {
				data?.user
					? dispatch(getPendingUserReviews(res?.data))
					: dispatch(getPendingReviews(res?.data));
			}
			if (type === "hide") {
				data?.user
					? dispatch(getHideUserReviews(res?.data))
					: dispatch(getHideReviews(res?.data));
			}
			if (type === "display") {
				data?.user
					? dispatch(getDisplayUserReviews(res?.data))
					: dispatch(getDisplayReviews(res?.data));
			}
		}
	} catch (err) {
		if (err) console.log(err.response?.data?.data, { err });
		if (err?.response?.status === 429) toast.error(err?.response?.data);
		dispatch(reviewsFail());
		if (data?.user) dispatch(reviewsUserFail());
		// if (data) {
		// 	let error = err.response?.data?.data;
		// 	if (error) {
		// 		dispatch(returnErrors({ error, status: err?.response?.status }));
		// 	}
		// }
	}
};

export const updateReviewStates = (type, data, from, to) => async dispatch => {
	dispatch(clearErrors());
	try {
		let res;
		if (type === "update") {
			res = await axios.put(`/api/v1/review?review=${data?._id}&action=${to}`);
			dispatch(updateReviews({ data: res?.data?.data, from, to }));
		}
		dispatch(addNewUserPlatform("get"));
		toast.success(res?.data?.msg);
	} catch (err) {
		if (err) console.log(err.response?.data?.data, { err });
		if (err?.response?.status === 429) toast.error(err?.response?.data);
		dispatch(reviewsFail());
		let error = err.response?.data?.data;
		if (error) {
			dispatch(returnErrors({ error, status: err?.response?.status }));
		}
	}
};
