import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {toast} from 'react-toastify'

let initialState = {
	error: null,
	id: null,
	status: null,
	errorText: "",
};

export const errorSlice = createSlice({
	name: "error",
	initialState,
	reducers: {
		returnErrors: (state, { payload }) => {
			state.error = payload?.error ? payload?.error : payload;
			state.is = payload?.id;
			state.status = payload?.status;
		},
		getErrorText: (state, { payload }) => {
			state.errorText = payload;
		},
		clearErrors: state => {
			state.error = null;
			state.errorText = "";
			state.status = null;
		},
	},
});

let initialState2 = {
	msg: null,
};

export const successSlice = createSlice({
	name: "success",
	initialState: initialState2,
	reducers: {
		setSuccess: (state, { payload }) => {
			state.msg = payload;
		},
		restoreMsg: state => {
			state.msg = "";
		},
	},
});

let initialSettings = {
	data: null,
};

export const settingsSlice = createSlice({
	name: "settings",
	initialState: initialSettings,
	reducers: {
		setSettings: (state, { payload }) => {
			state.data = payload;
		},
	},
});

export const { setSettings} = settingsSlice.actions;
export const { setSuccess, restoreMsg } = successSlice.actions;
export const { returnErrors, getErrorText, clearErrors } = errorSlice.actions;


export const getSettings = data => async dispatch => {
	try {
		let res;
		if (!data) res = await axios.get(`/api/v1/settings`);
		else res = await axios.post(`/api/v1/settings`, { ...data });
		// console.log({ data: res?.data });
		dispatch(setSettings(res?.data?.data));
		if (data) toast.success(res?.data?.msg)
	} catch (err) {
		if (err) console.log(err.response?.data?.data, { err });
		if (err?.response?.status === 429 || err?.response?.status === 405)
			toast.error(err?.response?.data ? err?.response?.data : err?.message);
		let error = err.response?.data?.data;
		if (error) dispatch(returnErrors({ error, status: err?.response?.status }));
	}
};