import React from "react";
import { FaFacebookF, FaTwitter, FaLinkedin, FaYoutube } from "react-icons/fa";
const SocialMediaColored = ({ state }) => {
	return (
		<div className="mt-2 text-center flex justify-center items-center gap-2 w-[250px] mx-auto">
			<span className="s text-primary-700 cursor-pointer">
				{state ? (
					state?.facebook ? (
						<FaFacebookF size={10} />
					) : null
				) : (
					<FaFacebookF size={10} />
				)}
			</span>
			<span className="s text-primary cursor-pointer">
				{state ? (
					state?.twitter ? (
						<FaTwitter size={10} />
					) : null
				) : (
					<FaTwitter size={10} />
				)}
			</span>
			<span className="s text-primary-900 cursor-pointer">
				{state ? (
					state?.linkedIn ? (
						<FaLinkedin size={10} />
					) : null
				) : (
					<FaLinkedin size={10} />
				)}
			</span>
			<span className="s text-danger-600 cursor-pointer">
				{state ? (
					state?.youtube ? (
						<FaYoutube size={10} />
					) : null
				) : (
					<FaYoutube size={10} />
				)}
			</span>
		</div>
	);
};

export default SocialMediaColored;
