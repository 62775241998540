import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { BiSearch, BiArrowBack } from "react-icons/bi";
// import { VscListSelection } from "react-icons/vsc";

import Profilerounded from "../profile-rounded/profile-rounded";
import { useSelector } from "react-redux";
// import Profile from "../profile/profile";
// import Notification from "../notification/notification/notification";

const DefaultHeader = () => {
	const location = useLocation();
	const navigate = useNavigate(),
		{ auth } = useSelector(state => state);

	return (
		<div className="mb-8 p-4">
			<div className="mb-8 flex items-center justify-between">
				<div className="flex items-center gap-4">
					<div className="search flex items-center w-fit px-4 rounded-md">
						<span>
							<BiSearch />
						</span>
						<input
							type="text"
							placeholder="Search"
							className="md:w-80 outline-none bg-transparent border-none font-light text-sm h-10 px-2"
						/>
					</div>
				</div>
				<div className="profile flex items-center gap-4">
					{/* <span>
            <Notification />
          </span> */}
					<div>
						<Profilerounded sm img={auth?.user?.avatar?.url} />
					</div>
				</div>
			</div>
			<p className="flex gap-2 capitalize">
				<div
					className="p-1 bg-gray-400 text-white w-fit cursor-pointer"
					onClick={() => navigate(-1)}>
					<BiArrowBack />
				</div>
				{location.pathname.split("/")[1]?.replace(/[-]/g, " ")}
			</p>
		</div>
	);
};

export default DefaultHeader;
