import { BsStarFill } from "react-icons/bs";

// const star = {
//   fullStar: <BsStarFill />,
//   halfStar: <BsStarHalf />,
//   outlineStar: <BsStar />,
// };

const fiveStar = [
	//   {
	//     id: 0,
	//     name: "0",
	//   },
	{
		id: 1,
		name: "1",
	},
	{
		id: 2,
		name: "2",
	},
	{
		id: 3,
		name: "3",
	},
	{
		id: 4,
		name: "4",
	},
	{
		id: 5,
		name: "5",
	},
];
// const getStar = (num) => {
//   let starRating = [];
//   if (num === 0) {
//     starRating = starRating.push(star.outlineStar);
//     return starRating;
//   }

//   if ((num * 10) % 10 === 5) {
//     for (i = 0; i < num - 1.5; i++) {
//       starRating = starRating.push(star.fullStar);
//     }
//     starRating = starRating.push(star.halfStar);
//     return starRating;
//   } else {
//     for (i = 0; i < num; i++) {
//       starRating = starRating.push(star.fullStar);
//     }
//     return starRating;
//   }
// };
const FiveStar = ({ color, size }) => {
	return (
		<div className={`flex items-center gap-4 text-[${color}]`}>
			{fiveStar.map(star => (
				<BsStarFill size={size} />
			))}
		</div>
	);
};

export default FiveStar;
