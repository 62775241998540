import React, { useEffect, useState } from "react";
import { Buttons } from "./ModalComponents";
import { loadUser, login, setUserFail } from "../data/Reducers/UserReducer";
import { useGoogleLogin } from "@react-oauth/google";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, returnErrors } from "../data/Reducers/ErrorReducer";

const ThirdParty = () => {
	const { auth } = useSelector(state => state);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	let [loading2, setLoading2] = useState(false),
		[submit, setSubmit] = useState(false),
		navigate = useNavigate(),
		dispatch = useDispatch();

	let ThirdPartyAuth = async (response, type) => {
		if (response) if (response?.status === "unknown") return;
		console.log({ response });
		setLoading2(type);
		try {
			let res = await axios.post(`/api/v1/user/login/${type}`, { ...response });
			dispatch(clearErrors());

			if (res?.data?.data?.privilege) {
				dispatch(login(res.data));
				dispatch(loadUser());
				toast.success(res.data.msg, { autoClose: 5000 });
			} else {
				dispatch(setUserFail());
				dispatch(
					returnErrors({ error: [{ msg: "Unauthorised User", param: "auth" }] })
				);
			}
		} catch (err) {
			if (err?.response?.status === 429 || err?.response?.status === 405)
				toast.error(err?.response?.data ? err?.response?.data : err?.message);
			console.log({ err });
			let error = err.response?.data?.data;
			if (error) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			}
			dispatch(setUserFail());
		}
		setLoading2("");
	};

	const loginG = useGoogleLogin({
		onSuccess: tokenResponse => ThirdPartyAuth(tokenResponse, "google"),
	});

	// let handleFailure = e => {
	// 	console.log({ e });
	// 	alert(e.error);
	// };

	useEffect(() => {
		if (submit && auth?.isLoggedIn) {
			setSubmit(false);
			navigate("/");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [submit, auth?.isLoggedIn]);

	return (
		<div className="space-y-2">
			<Buttons
				onClick={() => loginG()}
				title={"Sign In with Google"}
				css="inline-block w-full rounded border border-main px-7 pb-2.5 pt-3 text-sm font-medium uppercase leading-normal text-main shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-main hover:text-white hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-main focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-main active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
				bg="border border-[#fff]-300"
				color="bg-[#fff]"
				width={"w-100"}
				loading={loading2 === "google"}
				loadCss="#5080fa"
				type="button"
			/>
		</div>
	);
};

export default ThirdParty;
