/* eslint-disable react/style-prop-object */
import { useContext, useEffect, useState } from "react";
import Button from "../../components/button/button";
import { MdFilterList, MdOutlineWidgets } from "react-icons/md";
import CustomerReview from "../../components/customer-review/customer-review";
import Input from "../../components/input/input";
import bgImage from "../../assets/bg-img.png";
// import ContactForm from "../../components/contact-form/contact-form";
import SocialMediaColored from "../../components/social-icons/social-media-colored";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Buttons } from "../../components/ModalComponents";
import { loadCustomizedPage } from "../../data/Reducers/CustomizeReducer";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { returnErrors } from "../../data/Reducers/ErrorReducer";
import axios from "axios";
import { PendingReview } from "../home";
import FiveStar from "../../components/widgets/five-star";
// import { Rating } from "react-simple-star-rating";
import Background from "../../components/widgets/background";
import ReviewButton from "../../components/widgets/button";
import { BiCopy } from "react-icons/bi";
import { loadWidgetPage } from "../../data/Reducers/WidgetReducer";
import { GlobalState } from "../../data/Context";

const Customization = () => {
	let tabViews = [
		"General",
		"Widget builder",
		"Customized Business Page",
		"Feedback",
	];
	let [active, setActive] = useState("General"),
		{ customize } = useSelector(state => state);
	let {t} = useContext(GlobalState);

	return (
		<div>
			<nav className="flex items-center border-b gap-10 px-1 overflow-x-auto  scrollbar-hide">
				{tabViews?.map((item, i) => (
					<div
						key={i}
						onClick={() => setActive(item)}
						className={`py-2 font-body capitalize whitespace-nowrap text-sm w-fit pr-2 cursor-pointer border-b-2 ${
							active === item ? "s border-main" : "border-transparent"
						}`}>
						{t(item?.replace("-", " "))}
					</div>
				))}
			</nav>
			<div className="mt-8">
				{active === "General" && <General />}
				{active === "Widget builder" && <WidgetBuilder />}
				{active === "Features" && <Features />}
				{active === "Customized Business Page" && <CustomizedBusinessPage />}
				{active === "Feedback" && (
					<PendingReview
						state={
							customize?.isFound ? customize?.mainSearch : customize?.contact
						}
						paginate={
							customize?.isFound
								? customize?.search_paginate
								: customize?.contact_paginate
						}
						title={t("Feedback")}
						type={"feedback"}
					/>
				)}
				{active === "Email" && <Email />}
				{active === "Imports and Exports" && <ImportAndExports />}
				{active === "Automations" && <Automations />}
			</div>
		</div>
	);
};

export default Customization;

const General = () => {
	let { auth, customize } = useSelector(state => state);
	let { t } = useContext(GlobalState);

	return (
		<div>
			<div className="grid md:grid-cols-2 gap-4 mb-8">
				<div>
					<p className="text-xl font-bold">{t(`Review ID`)}</p>
					<p className="text-md">{t(`public review`)}</p>
				</div>
				<div className="self-end">
					<p>{auth?.user?.organisation?.popCode}</p>
				</div>
			</div>
			<div className="grid md:grid-cols-2 gap-4 mb-8">
				<div>
					<p className="text-xl font-bold">{t(`custom url`)}</p>
					{/* <p className="text-md">
						If you're using a Review no-code widget or the API, then write the
						exact URL where you will host your Review. Leave it blank if you're
						going to use a hosted page from us.
					</p> */}
				</div>
				<div className="overflow-hidden">
					<span className="text-md font-semibold flex items-center gap-4 p-2 border rounded-md border-black overflow-scroll scrollbar-hide">
						{window.origin}/businesses/{auth?.user?.organisation?.customizedId}
						<span
							className="mt-auto myCursor force-d-flex px-2 border-l border-black"
							onClick={
								auth?.user?.organisation?.customizedId
									? () => {
											navigator.clipboard
												.writeText(
													`${window.origin}/businesses/${auth?.user?.organisation?.customizedId}`
												)
												.then(
													() => {
														toast.info(t("Copied"), { autoClose: 2000 });
													},
													err => {
														toast.warn(`Could not copy: ${err}`, {
															autoClose: 2000,
														});
													}
												);
									  }
									: null
							}>
							<BiCopy />
						</span>
					</span>
				</div>
			</div>
			<div className="grid md:grid-cols-2 gap-4 mb-8">
				<div>
					<p className="text-xl font-bold">{t(`review url`)}</p>
					<p className="text-md">
						{t(
							`review url text`
						)}
					</p>
				</div>
				<div className="self-end">
					<span className="text-md font-semibold flex items-center gap-4 p-2 border rounded-md w-fit border-black">
						{process.env.REACT_APP_REVIEW_URL}/reviewId=
						<span className="overflow-hidden w-20">
							{auth?.user?.organisation?.popCode}
						</span>
						<span
							className="mt-auto myCursor force-d-flex px-2 border-l border-black"
							onClick={
								auth?.user?.organisation?.popCode
									? () => {
											navigator.clipboard
												.writeText(
													`${process.env.REACT_APP_REVIEW_URL}/reviewId=${auth?.user?.organisation?.popCode}`
												)
												.then(
													() => {
														toast.info("Copied", { autoClose: 2000 });
													},
													err => {
														toast.warn(`Could not copy: ${err}`, {
															autoClose: 2000,
														});
													}
												);
									  }
									: null
							}>
							<BiCopy />
						</span>
					</span>
				</div>
			</div>
			<div className="grid md:grid-cols-2 gap-4 mb-8">
				<div>
					<p className="text-xl font-bold">{t(`review name`)}</p>
					<p className="text-md">
						{t(
							`review name text`
						)}
					</p>
				</div>
				<div className="self-end">
					<input
						type="email"
						placeholder="flashreview"
						className="w-[270px] bg-transparent border border-black rounded-md h-10 px-4"
						value={auth?.user?.organisation?.businessName}
						readOnly
					/>
				</div>
			</div>
			<div className="grid md:grid-cols-2 gap-4 mb-8">
				<div>
					<p className="text-xl font-bold">{t(`review created`)}</p>
					<p className="text-md">{t(`review created text`)}</p>
				</div>
				<div className="self-end">
					<p>
						{moment(customize?.data?.createdAt).format("MMMM DD, YYYY hh:mm A")}
					</p>
				</div>
			</div>
			<div className="grid md:grid-cols-2 gap-4 mb-8">
				<div>
					<p className="text-xl font-bold">{t(`delete review`)}</p>
					<p className="text-md">
						{t(
							`delete review text`
						)}
					</p>
				</div>
				<div className="self-end">
					<Button buttonType={"primary"} children={"create review"} />
				</div>
			</div>
		</div>
	);
};

const WidgetBuilder = () => {
	let settings = {
			textColor: "#ffffff",
			backgroundColor: "#ffffff",
			transparent: "transparent",
			fontColor: "#65D133",
			buttonFontColor: "#ffffff",
			starSize: 28,
			bgRadius: 8,
			submitButtonColor: "#65D133",
			submitButtonFontColor: "#ffffff",
			submitButtonFontSize: 12,
			submitButtonBorderRadius: 8,
		},
		{ widget, auth } = useSelector(state => state),
		init = {
			headline: "",
			subText: "",
			submitText: "",
			submitColor: "",
			starColor: "",
			fontColor: "",
			frequency: 2,
			time: 5,
		},
		[state, setState] = useState(init),
		[loading, setLoading] = useState(false),
		textChange = e => {
			let { value, name } = e.target;
			setState({ ...state, [name]: value });
		},
		dispatch = useDispatch(),
		handleSubmit = async e => {
			e?.preventDefault();
			setLoading(true);
			await dispatch(loadWidgetPage(state));
			setLoading(false);
		};

	useEffect(() => {
		if (widget?.data) setState(widget?.data);
	}, [widget?.data]);

	let { t } = useContext(GlobalState);

	return (
		<div>
			<div className="grid md:grid-cols-2 gap-8">
				<div>
					<div className="flex gap-8 flex-wrap sm:flex-nowrap">
						<div>
							<div className="bg-main text-white flex flex-col items-center p-2 rounded-md">
								<span className="mx-auto">
									<MdOutlineWidgets size={40} />
								</span>
								<p className="text-md font-extralight">{t(`Customize`)}</p>
							</div>
						</div>
						<div>
							{/* Coustom settings giver */}
							<div className="mb-4">
								<p className="text-xl font-bold mb-2">
									{t(`Landing Page Text`)}
								</p>
								<Input
									placeholder={"Welcome here"}
									label={t("Landing Page Text")}
									type={"text"}
									name="headline"
									onChange={textChange}
									value={state?.headline}
									style="h-10 border rounded-md bg-transparent px-3 w-full"
								/>
							</div>
							<div className="mb-4">
								<p className="text-xl font-bold mb-2">
									{t(`Landing Page Subtext`)}
								</p>
								<Input
									placeholder={
										"Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cupiditate non excepturi fugit inventore reprehenderit, natus iusto aspernatur ex modi dolore."
									}
									label={"Landing Page Subtext"}
									type={"text"}
									name="subText"
									onChange={textChange}
									value={state?.subText}
									style="h-10 border rounded-md bg-transparent px-3 w-full"
								/>
							</div>
							<div className="mb-4">
								<p className="text-xl font-bold mb-2">
									{t(`Submit button text`)}
								</p>
								<Input
									placeholder={
										"Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cupiditate non excepturi fugit inventore reprehenderit, natus iusto aspernatur ex modi dolore."
									}
									label={t("Submit button text")}
									type={"text"}
									name="submitText"
									onChange={textChange}
									value={state?.submitText}
									style="h-10 border rounded-md bg-transparent px-3 w-full"
								/>
							</div>
							<div className="mb-4">
								<label className="font-bold text-xl">
									{t(`Submit Button Color`)}
								</label>
								<div className="flex items-center gap-4 mt-2">
									<div className="h-10 w-10 rounded-md overflow-hidden">
										<input
											type="color"
											className="h-20 w-20 -mt-8 -ml-8"
											value={state.submitColor}
											name="submitColor"
											onChange={textChange}
										/>
									</div>
									<input
										type="text"
										className="h-10 bg-transparent border rounded-md w-40 px-4"
										value={state.submitColor}
										name="submitColor"
										onChange={textChange}
										readOnly
									/>
								</div>
							</div>
							<div className="mb-4">
								<label className="font-bold text-xl">{t(`Text Color`)}</label>
								<div className="flex items-center gap-4 mt-2">
									<div className="h-10 w-10 rounded-md overflow-hidden">
										<input
											type="color"
											className="h-20 w-20 -mt-8 -ml-8"
											value={state.fontColor}
											name="fontColor"
											onChange={textChange}
										/>
									</div>
									<input
										type="text"
										className="h-10 bg-transparent border rounded-md w-40 px-4"
										value={state.fontColor}
										name="fontColor"
										onChange={textChange}
										readOnly
									/>
								</div>
							</div>
							<div className="mb-4">
								<label className="font-bold text-xl">{t(`Star Color`)}</label>
								<div className="flex items-center gap-4 mt-2">
									<div className="h-10 w-10 rounded-md overflow-hidden">
										<input
											type="color"
											className="h-20 w-20 -mt-8 -ml-8"
											value={state.starColor}
											name="starColor"
											onChange={textChange}
										/>
									</div>
									<input
										type="text"
										className="h-10 bg-transparent border rounded-md w-40 px-4"
										value={state.starColor}
										name="starColor"
										onChange={textChange}
										readOnly
									/>
								</div>
							</div>
							<div className="mb-4">
								<p className="text-xl font-bold mb-2">
									{t(`Time to popup`)}({t(`in secs`)})
								</p>
								<Input
									placeholder={
										"Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cupiditate non excepturi fugit inventore reprehenderit, natus iusto aspernatur ex modi dolore."
									}
									label={"Time to pop up(in secs)"}
									type={"tel"}
									name="time"
									onChange={textChange}
									value={state?.time}
									style="h-10 border rounded-md bg-transparent px-3 w-full"
								/>
							</div>
							<div className="mb-4">
								<p className="text-xl font-bold mb-2">
									{t(`Number of popup`)} ({t(`per day`)})
								</p>
								<Input
									placeholder={
										"Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cupiditate non excepturi fugit inventore reprehenderit, natus iusto aspernatur ex modi dolore."
									}
									label={`${t('Number of popup')} (${t('per day')})`}
									type={"tel"}
									name="frequency"
									onChange={textChange}
									value={state?.frequency}
									style="h-10 border rounded-md bg-transparent px-3 w-full"
								/>
							</div>
							{/* <div className="mb-4">
								<label className="font-bold text-xl">Submit Button Color</label>
								<div className="flex items-center gap-4 mt-2">
									<div className="h-10 w-10 rounded-md overflow-hidden">
										<input
											type="color"
											className="h-20 w-20 -mt-8 -ml-8"
											value={settings.textColor}
											name="textColor"
											onChange={handleSettingsChange}
										/>
									</div>
									<input
										type="text"
										className="h-10 bg-transparent border rounded-md w-40 px-4"
										value={settings.textColor}
										name="textColor"
										onChange={handleSettingsChange}
									/>
								</div>
							</div>
							<div className="mb-4">
								<label className="font-bold text-xl">Background Color</label>
								<div className="flex items-center gap-4 mt-2">
									<div className="h-10 w-10 rounded-md overflow-hidden">
										<input
											type="color"
											className="h-20 w-20 -mt-8 -ml-8"
											value={settings.backgroundColor}
											name="backgroundColor"
											onChange={handleSettingsChange}
										/>
									</div>
									<input
										type="text"
										className="h-10 bg-transparent border rounded-md w-40 px-4"
										value={settings.backgroundColor}
										name="backgroundColor"
										onChange={handleSettingsChange}
									/>
								</div>
							</div>
							<div className="mb-4">
								<label className="font-bold text-xl">Font Color</label>
								<div className="flex items-center gap-4 mt-2">
									<div className="h-10 w-10 rounded-md overflow-hidden">
										<input
											type="color"
											className="h-20 w-20 -mt-8 -ml-8"
											value={settings.fontColor}
											name="fontColor"
											onChange={handleSettingsChange}
										/>
									</div>
									<input
										type="text"
										className="h-10 bg-transparent border rounded-md w-40 px-4"
										value={settings.fontColor}
										name="fontColor"
										onChange={handleSettingsChange}
									/>
								</div>
							</div>
							<div className="mb-4">
								<label className="font-bold text-xl">Button Font Color</label>
								<div className="flex items-center gap-4 mt-2">
									<div className="h-10 w-10 rounded-md overflow-hidden">
										<input
											type="color"
											className="h-20 w-20 -mt-8 -ml-8"
											value={settings.buttonFontColor}
											name="buttonFontColor"
											onChange={handleSettingsChange}
										/>
									</div>
									<input
										type="text"
										className="h-10 bg-transparent border rounded-md w-40 px-4"
										value={settings.buttonFontColor}
										name="buttonFontColor"
										onChange={handleSettingsChange}
									/>
								</div>
							</div>
							<div className="mb-4">
								<label className="font-bold text-xl">Star Size</label>
								<div className="flex items-center gap-4 mt-2">
									<div className="h-10 w-10 rounded-md overflow-hidden">
										<input
											type="color"
											className="h-20 w-20 -mt-8 -ml-8"
											value={settings.starSize}
											name="starSize"
											onChange={handleSettingsChange}
										/>
									</div>
									<input
										type="number"
										className="h-10 bg-transparent border rounded-md w-24 px-4"
										value={settings.starSize}
										name="starSize"
										onChange={handleSettingsChange}
									/>
								</div>
							</div> */}
							{/* <div className="mb-4">
								<label className="font-bold text-xl">Background Radius</label>
								<div className="flex items-center gap-4 mt-2">
									<div className="h-10 w-10 rounded-md overflow-hidden">
										<input
											type="color"
											className="h-20 w-20 -mt-8 -ml-8"
											value={settings.bgRadius}
											name="bgRadius"
											onChange={handleSettingsChange}
										/>
									</div>
									<input
										type="number"
										className="h-10 bg-transparent border rounded-md w-24 px-4"
										value={settings.bgRadius}
										name="bgRadius"
										onChange={handleSettingsChange}
									/>
								</div>
							</div> */}
							<div className="mb-6">
								{/* <p className="text-xl font-bold">Add Button</p> */}
								<Buttons
									title={t("Submit")}
									buttonType={"primary"}
									width={"w-full"}
									onClick={handleSubmit}
									loading={loading}
								/>
							</div>
						</div>
					</div>
				</div>
				<div>
					{/* widget receiver */}
					<div className="grid grid-cols-2 gap-8">
						<div></div>
						<div></div>
						<div className="col-span-2">
							<Button
								width={"w-full"}
								buttonType={"primary"}
								onClick={() =>
									auth?.user?.popCode
										? navigator.clipboard
												.writeText(
													`<iframe src="${process.env.REACT_APP_REVIEW_URL}/reviewId=${auth?.user?.popCode}" style="position: fixed; top: 0; left: 0; bottom: 0; right: 0; height: 100%; width: 100%; z-index: 99999999;" title="${auth?.user?.organisation?.businessName} Flash Review Widget" />`
												)
												.then(
													() => {
														toast.info("Copied", { autoClose: 2000 });
													},
													err => {
														toast.warn(`Could not copy: ${err}`, {
															autoClose: 2000,
														});
													}
												)
										: null
								}
								children={t("Get Embed Code")}
							/>
						</div>
						<div className="col-span-2">
							<Background
								bgColor={settings.backgroundColor}
								radius={settings.bgRadius}>
								<div>
									<div className="title-area flex justify-between items-center mb-6">
										<p
											className={`font-semibold text-2xl text-[${
												state?.fontColor || settings.fontColor
											}]`}>
											{state?.headline || t(`Review our services`)}
										</p>
										{/* <div
											className={`w-8 h-8 rounded-md bg-[${settings.fontColor}] text-white flex items-center justify-center`}>
											X
										</div> */}
									</div>
									<div className="body">
										<p
											className={`text-md text-[${
												state?.fontColor || settings.fontColor
											}] mb-2`}>
											{state?.subText || `Rate us`}
										</p>
										<div className="stars flex items-center gap-4 mb-4">
											<FiveStar
												color={state?.starColor || settings.fontColor}
												size={settings.starSize}
											/>
										</div>
										<form className="space-y-4">
											{/* <div>
												<textarea
													type="text"
													placeholder="Message here"
													rows={1}
													className="border rounded-md w-full p-4"
												/>
											</div>
											<div className="flex items-center gap-x-12">
												<span className="flex items-center gap-2">
													<input type="radio" name="review" />
													<label className="text-xs font-medium">
														set as private review
													</label>
												</span>
												<span className="flex items-center gap-2">
													<input type="radio" name="review" />
													<label className="text-xs font-medium">
														set as public review
													</label>
												</span>
											</div> */}
											<div className="flex justify-center gap-8 mt-2">
												<ReviewButton
													bgColor={state?.submitColor || settings.fontColor}
													fontColor={settings.buttonFontColor}
													fontSize={settings.submitButtonFontSize}
													borderRadius={settings.submitButtonBorderRadius}
													title={state?.submitText}
												/>
											</div>
										</form>
									</div>
									<div className="foot mt-4">
										<p className="text-[#0E2D00] text-center">
											widget by <strong>FlashReview</strong>
										</p>
									</div>
								</div>
							</Background>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const Features = () => {
	const {t} = useContext(GlobalState)
	return (
		<div>
			<p className="text-4xl font-light mb-4">{t("Collect Info")}</p>
			<div className="grid md:grid-cols-2 gap-4 mb-3">
				<div>
					<p className="text-xl font-bold">{t("reviewer contact")}</p>
					<p className="text-md">
						{t(`reviewer contact text`)}
					</p>
				</div>
				<div className="">
					<div className="flex items-center gap-x-6">
						<div class="flex items-center mb-4">
							<input
								id="default-checkbox"
								type="checkbox"
								value=""
								className="w-4 h-4 text-main bg-gray-100 border-main rounded focus:ring-main focus:ring-2"
							/>
							<label
								for="default-checkbox"
								class="ml-2 text-sm font-medium text-gray-900">
								{t("Name")}
							</label>
						</div>
						<div class="flex items-center mb-4">
							<input
								id="default-checkbox"
								type="checkbox"
								value=""
								className="w-4 h-4 text-main bg-gray-100 border-main rounded focus:ring-main focus:ring-2"
							/>
							<label
								for="default-checkbox"
								class="ml-2 text-sm font-medium text-gray-900">
								{t("Phone Number")}
							</label>
						</div>
						<div class="flex items-center mb-4">
							<input
								id="default-checkbox"
								type="checkbox"
								value=""
								className="w-4 h-4 text-main bg-gray-100 border-main rounded focus:ring-main focus:ring-2"
							/>
							<label
								for="default-checkbox"
								class="ml-2 text-sm font-medium text-gray-900">
								{t("Location")}
							</label>
						</div>
						<div class="flex items-center mb-4">
							<input
								id="default-checkbox"
								type="checkbox"
								value=""
								className="w-4 h-4 text-main bg-gray-100 border-main rounded focus:ring-main focus:ring-2"
							/>
							<label
								for="default-checkbox"
								class="ml-2 text-sm font-medium text-gray-900">
								{t("Picture")}
							</label>
						</div>
					</div>
				</div>
			</div>
			<div className="grid md:grid-cols-2 gap-4 mb-8">
				<div>
					<p className="text-xl font-bold">{t("Require Video")}</p>
					<p className="text-md">
						{t("require video text")}
					</p>
				</div>
				<div className="self-center">
					<input
						id="default-checkbox"
						type="checkbox"
						value=""
						className="w-4 h-4 text-main bg-gray-100 border-main rounded focus:ring-main focus:ring-2"
					/>
				</div>
			</div>
			<p className="text-4xl font-light mb-4">{t("Email Verification")}</p>
			<div className="grid md:grid-cols-2 gap-4 mb-3">
				<div>
					<p className="text-xl font-bold">{t("Verify Reviewer by Email")}</p>
					<p className="text-md">
						{t("email verify text")}
					</p>
				</div>
				<div className="self-center">
					<input
						id="default-checkbox"
						type="checkbox"
						value=""
						className="w-4 h-4 text-main bg-gray-100 border-main rounded focus:ring-main focus:ring-2"
					/>
				</div>
			</div>
			<div className="grid md:grid-cols-2 gap-4 mb-3">
				<div>
					<p className="text-xl font-bold">{t("Permitted Domain")}</p>
					<p className="text-md">
						{t("permitted domain text")}
					</p>
				</div>
				<div className="self-center space-y-2">
					<input
						type="text"
						placeholder="Columbia.edu"
						className="w-[270px] bg-transparent border border-black rounded-md h-10 px-4"
					/>{" "}
					<br />
					<Button buttonType={"primary"} children={"Upgrade"} />
				</div>
			</div>
			<div className="grid md:grid-cols-2 gap-4 mb-8">
				<div>
					<p className="text-xl font-bold">{t("Review and feedback")}</p>
					<p className="text-md">
						{t("review and feedback text")}
					</p>
				</div>
				<div className="self-center">
					<div className="flex items-center gap-x-6">
						<div class="flex items-center mb-4">
							<input
								id="default-checkbox"
								type="checkbox"
								value=""
								className="w-4 h-4 text-main bg-gray-100 border-main rounded focus:ring-main focus:ring-2"
							/>
							<label
								for="default-checkbox"
								class="ml-2 text-sm font-medium text-gray-900">
								Review
							</label>
						</div>
						<div class="flex items-center mb-4">
							<input
								id="default-checkbox"
								type="checkbox"
								value=""
								className="w-4 h-4 text-main bg-gray-100 border-main rounded focus:ring-main focus:ring-2"
							/>
							<label
								for="default-checkbox"
								class="ml-2 text-sm font-medium text-gray-900">
								Feedback
							</label>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const CustomizedBusinessPage = () => {
	const reviews = [
		{
			id: 1,
			name: "Tiffany Smith",
			loc: "Canada",
			review: " overflow-scroll scrollbar-hide",
		},
		{
			id: 2,
			name: "Linda Collins",
			loc: "America",
			review:
				"Absolutely impressed with the user-friendly interface and clear purpose.",
		},
		{
			id: 3,
			name: "Tima Miroshnichenko",
			loc: "Germany",
			review: "Simple, efficient, and purpose-driven software.",
		},
	];
	let init = {
			landingPageText: "",
			landingPageSubText: "",
			landingPageImage: null,
			offerText: "",
			offerSubText: "",
			offerImage: null,
			reviewText: "",
			reviewSubText: "",
			contactText: "",
			contactSubText: "",
			footerSubText: "",
			linkedIn: "",
			instagram: "",
			twitter: "",
			facebook: "",
			whatsapp: "",
			logo: null,
		},
		[state, setState] = useState(init),
		[loading, setLoading] = useState(false),
		textChange = e => {
			let { value, name } = e.target;
			setState({ ...state, [name]: value });
		},
		dispatch = useDispatch(),
		handleSubmit = async e => {
			e?.preventDefault();
			setLoading(true);
			await dispatch(loadCustomizedPage("post", null, state));
			setLoading(false);
		},
		handleChangeImage = name => e => {
			const file = e.target.files[0];
			let err = "";
			if (!file) return (err = `File, ${file?.name} does not exist`);
			if (err) {
				return toast.error(err);
			} else {
				setState({ ...state, [name || "image"]: file });
			}
		},
		{ customize } = useSelector(state => state);

	useEffect(() => {
		if (customize?.data) setState(customize?.data);
	}, [customize?.data]);

	let { t } = useContext(GlobalState);

	return (
		<div>
			<div className="grid lg:grid-cols-2 gap-8 mb-20">
				<div>
					<div className="flex gap-x-4 flex-wrap">
						<div>
							<div className="bg-main text-white flex flex-col items-center p-2 rounded-md">
								<span className="mx-auto">
									<MdOutlineWidgets size={32} />
								</span>
								<p className="text-md font-extralight">{t(`Customize`)}</p>
							</div>
						</div>
						<div>
							<div className="mb-6">
								<p className="text-xl font-bold mb-2">{t(`Logo`)}</p>
								<Input
									label={t("Logo")}
									type={"file"}
									name="logo"
									onChange={handleChangeImage("logo")}
									// value={state?.offerImage}
									style="h-10 border rounded-md bg-transparent px-3 w-full"
									title="Upload file"
									id="file"
									className="myCursor"
									accept="image/*"
								/>
							</div>
							<div className="mb-3">
								<p className="text-xl font-bold mb-2">
									{t(`Landing Page Text`)}
								</p>
								<Input
									placeholder={"Welcome here"}
									label={t("Landing Page Text")}
									type={"text"}
									name="landingPageText"
									onChange={textChange}
									value={state?.landingPageText}
									style="h-10 border rounded-md bg-transparent px-3 w-full"
								/>
							</div>
							<div className="mb-3">
								<p className="text-xl font-bold mb-2">
									{t(`Landing Page Subtext`)}
								</p>
								<Input
									placeholder={
										"Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cupiditate non excepturi fugit inventore reprehenderit, natus iusto aspernatur ex modi dolore."
									}
									label={"Landing Page Sub Text"}
									type={"text"}
									name="landingPageSubText"
									onChange={textChange}
									value={state?.landingPageSubText}
									style="h-10 border rounded-md bg-transparent px-3 w-full"
								/>
							</div>
							<div className="mb-6">
								<p className="text-xl font-bold mb-2">
									{t(`Landing Page Image`)}
								</p>
								<Input
									label={"Landing Page Sub Image"}
									type={"file"}
									name="landingPageSubImage"
									onChange={handleChangeImage("landingPageImage")}
									// value={state?.landingPageImage}
									style="h-10 border rounded-md bg-transparent px-3 w-full"
									title="Upload file"
									id="file"
									className="myCursor"
									accept="image/*"
								/>
							</div>
							<div className="mb-3">
								<p className="text-xl font-bold mb-2">{t(`Offer Page Text`)}</p>
								<Input
									placeholder={"Welcome here"}
									label={"Offer Page Text"}
									type={"text"}
									name="offerText"
									onChange={textChange}
									value={state?.offerText}
									style="h-10 border rounded-md bg-transparent px-3 w-full"
								/>
							</div>
							<div className="mb-3">
								<p className="text-xl font-bold mb-2">
									{t(`Offer Page Subtext`)}
								</p>
								<Input
									placeholder={
										"Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cupiditate non excepturi fugit inventore reprehenderit, natus iusto aspernatur ex modi dolore."
									}
									label={"Offer Page Sub Text"}
									type={"text"}
									name="offerSubText"
									onChange={textChange}
									value={state?.offerSubText}
									style="h-10 border rounded-md bg-transparent px-3 w-full"
								/>
							</div>
							<div className="mb-6">
								<p className="text-xl font-bold mb-2">
									{t(`Offer Page Image`)}
								</p>
								<Input
									label={"Offer Page Sub Image"}
									type={"file"}
									name="offerImage"
									onChange={handleChangeImage("offerImage")}
									// value={state?.offerImage}
									style="h-10 border rounded-md bg-transparent px-3 w-full"
									title="Upload file"
									id="file"
									className="myCursor"
									accept="image/*"
								/>
							</div>
							{/* <div className="mb-6">
								<p className="text-xl font-bold">Add Text</p>
								<input
									type="text"
									className="h-10 border rounded-md bg-transparent px-3 w-full"
									placeholder="#31EC18"
								/>
							</div> */}
							<div className="mb-3">
								<p className="text-xl font-bold mb-2">
									{t(`Review Page Text`)}
								</p>
								<Input
									placeholder={"Welcome here"}
									label={"Review Page Text"}
									type={"text"}
									name="reviewText"
									onChange={textChange}
									value={state?.reviewText}
									style="h-10 border rounded-md bg-transparent px-3 w-full"
								/>
							</div>
							<div className="mb-6">
								<p className="text-xl font-bold mb-2">
									{t(`Review Page Subtext`)}
								</p>
								<Input
									placeholder={
										"Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cupiditate non excepturi fugit inventore reprehenderit, natus iusto aspernatur ex modi dolore."
									}
									label={"Offer Page Sub Text"}
									type={"text"}
									name="reviewSubText"
									onChange={textChange}
									value={state?.reviewSubText}
									style="h-10 border rounded-md bg-transparent px-3 w-full"
								/>
							</div>
							<div className="mb-3">
								<p className="text-xl font-bold mb-2">
									{t(`Contact Page Text`)}
								</p>
								<Input
									placeholder={"Welcome here"}
									label={"Contact Page Text"}
									type={"text"}
									name="contactText"
									onChange={textChange}
									value={state?.contactText}
									style="h-10 border rounded-md bg-transparent px-3 w-full"
								/>
							</div>
							<div className="mb-6">
								<p className="text-xl font-bold mb-2">
									{t(`Contact Page Subtext`)}
								</p>
								<Input
									placeholder={
										"Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cupiditate non excepturi fugit inventore reprehenderit, natus iusto aspernatur ex modi dolore."
									}
									label={"Contact Page Sub Text"}
									type={"text"}
									name="contactSubText"
									onChange={textChange}
									value={state?.contactSubText}
									style="h-10 border rounded-md bg-transparent px-3 w-full"
								/>
							</div>
							<div className="mb-6">
								<p className="text-xl font-bold mb-2">
									{t(`Footer Page Subtext`)}
								</p>
								<Input
									placeholder={
										"Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cupiditate non excepturi fugit inventore reprehenderit, natus iusto aspernatur ex modi dolore."
									}
									label={"Footer Page Sub Text"}
									type={"text"}
									name="footerSubText"
									onChange={textChange}
									value={state?.footerSubText}
									style="h-10 border rounded-md bg-transparent px-3 w-full"
								/>
							</div>
							<div className="mb-3">
								<p className="text-xl font-bold mb-2">{t(`Facebook link`)}</p>
								<Input
									placeholder={"https://facebook.com/flashreview"}
									label={"Facebook Link"}
									type={"url"}
									name="facebook"
									onChange={textChange}
									value={state?.facebook}
									style="h-10 border rounded-md bg-transparent px-3 w-full"
								/>
							</div>
							<div className="mb-3">
								<p className="text-xl font-bold mb-2">{t(`Twitter link`)}</p>
								<Input
									placeholder={"https://twitter.com/flashreview"}
									label={"Twitter Link"}
									type={"url"}
									name="twitter"
									onChange={textChange}
									value={state?.twitter}
									style="h-10 border rounded-md bg-transparent px-3 w-full"
								/>
							</div>
							<div className="mb-3">
								<p className="text-xl font-bold mb-2">{t(`LinkedIn link`)}</p>
								<Input
									placeholder={"https://linkedIn.com/flashreview"}
									label={"LinkedIn Link"}
									type={"url"}
									name="linkedIn"
									onChange={textChange}
									value={state?.linkedIn}
									style="h-10 border rounded-md bg-transparent px-3 w-full"
								/>
							</div>
							<div className="mb-6">
								<p className="text-xl font-bold mb-2">{t(`Youtube link`)}</p>
								<Input
									placeholder={"https://youtube.com/flashreview"}
									label={"Youtube Link"}
									type={"url"}
									name="youtube"
									onChange={textChange}
									value={state?.youtube}
									style="h-10 border rounded-md bg-transparent px-3 w-full"
								/>
							</div>
							<div className="mb-6">
								{/* <p className="text-xl font-bold">Add Button</p> */}
								<Buttons
									title={"Submit"}
									buttonType={"primary"}
									width={"w-full"}
									onClick={handleSubmit}
									loading={loading}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className=" overflow-scroll scrollbar-hide">
					<CustomizedPage state={state} reviews={reviews} />
				</div>
			</div>
		</div>
	);
};

export const Feedbacks = () => {
	let { t } = useContext(GlobalState);

	return (
		<div className="pb-10">
			<div className="flex justify-between">
				<p className="text-4xl font-light mb-4">{t(`Feedback`)}</p>
				<form className="w-2/3">
					<label
						for="default-search"
						class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">
						{t("Search")}
					</label>
					<div class="relative">
						<div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
							<svg
								aria-hidden="true"
								class="w-5 h-5 text-gray-500 dark:text-gray-400"
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg">
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
							</svg>
						</div>
						<input
							type="search"
							id="default-search"
							class="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
							// placeholder="Search Mockups, Logos..."
							required
						/>
						<button
							type="submit"
							class="absolute right-2.5 bottom-2.5  focus:ring-4 focus:outline-none focus:ring-mainShade px-4 py-2">
							<MdFilterList size={20} />
						</button>
					</div>
				</form>
			</div>
			{/*  */}
			<div className="mt-8 flex gap-8">
				<CustomerReview
					text={t("review1")}
					star
				/>
				<CustomerReview
					text={t("review1")}
					star
				/>
				<CustomerReview
					text={t("review1")}
					star
				/>
			</div>
		</div>
	);
};

export const Email = () => {
	const {t} = useContext(GlobalState)
	return (
		<div>
			<div className="grid md:grid-cols-2 gap-4 mb-3">
				<div>
					<p className="text-4xl font-light mb-4">{t("Global Configuration")}</p>
					<div className="mb-3">
						<p className="text-xl font-bold mb-2">Logo</p>
						<img
							src={require("../../assets/thumbnail.png")}
							className="h-14 mb-3"
							alt=""
						/>
						<Button children={t("Upgrade")} buttonType={"primary"} />
					</div>
					<div className="mb-3">
						<p className="text-xl font-bold mb-2">Logo</p>
						<Input
							placeholder={"ajaniben1234@gmail.com"}
							label={t("Email")}
							type={"email"}
						/>
						<Button children={t("Upgrade")} buttonType={"primary"} />
					</div>
					<div>
						<p className="text-4xl font-light mb-4">Customize Templates</p>
						<Input
							placeholder={"ajaniben1234@gmail.com"}
							label={t("Header")}
							type={"text"}
						/>
						<Input
							placeholder={"ajaniben1234@gmail.com"}
							label={t("subHeader")}
							type={"text"}
						/>
						<Input
							placeholder={"ajaniben1234@gmail.com"}
							label={t("Main Body Text")}
							type={"textarea"}
						/>
						<Input
							placeholder={"ajaniben1234@gmail.com"}
							label={t("Sub Body Text")}
							type={"textarea"}
						/>
					</div>
					<div className="mt-4 space-y-2 mb-8">
						<Button
							children={t("Save Email Customization")}
							buttonType={"primary"}
						/>{" "}
						<br />
						<Button children={t("Upgrade")} buttonType={"primary"} />
					</div>
					<div>
						<p className="text-4xl font-light mb-4">Custom Sender</p>
						<Input
							placeholder={"ajaniben1234@gmail.com"}
							label={t("Sender Email")}
							type={"email"}
						/>
						<Input
							placeholder={"ajaniben1234@gmail.com"}
							label={t("Sending Name")}
							type={"text"}
						/>
					</div>
					<div className="mt-4 space-y-2 mb-8">
						<Button
							children={t("Save Sender Customization")}
							buttonType={"primary"}
						/>{" "}
						<br />
						<Button children={t("Upgrade")} buttonType={"primary"} />
					</div>
				</div>
				<div className="">
					<p>5153</p>
				</div>
			</div>
		</div>
	);
};

const ImportAndExports = () => {
	const navigate = useNavigate();
	const {t} = useContext(GlobalState)
	return (
		<div>
			<div className="grid md:grid-cols-2 gap-4 mb-8">
				<div>
					<p className="text-xl font-bold">{t("Export Data")}</p>
					<p className="text-md">
						{t("Export Data text")}.
					</p>
				</div>
				<div className="self-end flex justify-center">
					<Button buttonType={"primary"} children={"Export"} width={"w-52"} />
				</div>
			</div>
			<div className="grid md:grid-cols-2 gap-4 mb-3">
				<div>
					<p className="text-xl font-bold">{t("Import Data")}</p>
					<p className="text-md">
						Lorem ipsum dolor sit amet consectetur. Felis quam gravida magna at.
						Morbi fringilla nullam ac dictum pretium. Luctus quis nec duis
						neque. Consequat ut vel facilisis arcu et nulla accumsan
						pellentesque
					</p>
				</div>
				<div className="self-end flex justify-center">
					<Button
						buttonType={"primary"}
						children={t("Import")}
						width={"w-52"}
						onClick={() => navigate("/exports")}
					/>
				</div>
			</div>
		</div>
	);
};

const Automations = () => {
	const {t} = useContext(GlobalState)
	return (
		<div>
			<div className="grid md:grid-cols-2 gap-4 mb-8">
				<div className="flex items-center gap-2">
					<img src={require("../../assets/slack.png")} alt="" />
					<div>
						<p className="text-xl font-bold">{t("Slack")}</p>
						<p className="text-md">
							Get a notification in Slack every time a user signs up
						</p>
					</div>
				</div>
				<div className="self-end flex justify-center">
					<Button
						buttonType={"primary"}
						children={t("Connect with slack")}
						width={"w-52"}
					/>
				</div>
			</div>
		</div>
	);
};

export const CustomizedPage = ({ state, reviews }) => {
	let { t } = useContext(GlobalState);

	return (
		<div className="bg-white">
			<header className="header relative bg-[#2B3E39] max-w-full">
				<div
					className="opacity-30 absolute inset-0"
					style={{ background: `url(${bgImage})` }}></div>
				<nav className="relative z-10 container mx-auto">
					<div className="flex items-center justify-between p-4">
						<img
							src={
								state?.logo
									? typeof state?.logo?.url === "string"
										? state?.logo?.url
										: URL.createObjectURL(state?.logo)
									: require("../../assets/logos/logo.png")
							}
							alt=""
							className={`${"h-6"}`}
						/>
						<div className="menu">
							<ul className="flex items-center gap-x-4 text-white">
								<li>
									<a href="#home">{t(`Home`)}</a>
								</li>
								<li>
									<a href="#services">{t(`Services`)}</a>
								</li>
								<li>
									<a href="#review">{t("Review")}</a>
								</li>
								<li>
									<a href="#contact">{t(`Contact`)}</a>
								</li>
							</ul>
						</div>
					</div>
				</nav>
				<div className="pb-32 pt-10 text-center max-w-xl mx-auto relative z-10 text-white">
					<p className="text-4xl font-bold mb-2">
						{state?.landingPageText ||
							"we create Digital product And solve your Problem"}
					</p>
					<p className="text-sm font-thin">
						{state?.landingPageSubText ||
							`Lorem ipsum dolor sit amet consectetur. Penatibus netus massa felis
						at varius nec cursus. Ut diam malesuada sed odio nam justo.`}{" "}
					</p>
				</div>
			</header>
			<section className="mb-10">
				<img
					src={
						state?.landingPageImage
							? typeof state?.landingPageImage?.url === "string"
								? state?.landingPageImage?.url
								: URL.createObjectURL(state?.landingPageImage)
							: require("../../assets/rectangle.png")
					}
					alt={state?.landingPageText || "Banner"}
					className="max-w-xl mx-auto -mt-20 relative z-20"
				/>
			</section>
			<section className="px-4 py-10" id="services">
				<div className="grid md:grid-cols-2">
					<div className="self-center">
						<p className="text[##2B3E39] text-2xl font-bold mb-2 capitalize">
							{state?.offerText || "What we offer"}
						</p>
						<p className="text-sm font-thin">
							{state?.offerSubText ||
								`Lorem ipsum dolor sit amet consectetur. Elit non aenean risus
							velit dui cras ac. Enim sit nulla nisl egestas aliquet lectus.
							Pellentesque enim bibendum quam ullamcorper. Dictum arcu risus
							proin sagittis. Tellus porttitor morbi ac non maecenas.`}
						</p>
					</div>
					<div>
						<img
							src={
								state?.offerImage
									? typeof state?.offerImage?.url === "string"
										? state?.offerImage?.url
										: URL.createObjectURL(state?.offerImage)
									: require("../../assets/offer.png")
							}
							alt=""
							className="h-72 mx-auto"
						/>
					</div>
				</div>
			</section>
			<section className="px-4 py-10" id="review">
				<p className="text-[#2B3E39] text-2xl font-bold mb-2 text-center capitalize">
					{state?.reviewText || `Here’s what our customers say`}
				</p>
				<p className="text-xs font-thin text-center">
					{state?.reviewSubText || `This widget is made with our app.`}
				</p>
				{reviews && Array.isArray(reviews) ? (
					<>
						<div className="grid sm:grid-cols-1 md:grid-cols lg:grid-cols-3 gap-4 mt-12">
							{reviews?.map((item, i) => (
								<CustomerReview
									text={item.review}
									star
									key={i}
									item={item}
									type={"display"}
								/>
							))}
						</div>
					</>
				) : (
					<div className="grid sm:grid-cols-1 md:grid-cols lg:grid-cols-3 gap-4 mt-12">
						<CustomerReview
							star
							text={t('review1')}
						/>
						<CustomerReview
							star
							text={t('review1')}
						/>
						<CustomerReview
							star
							text={t('review1')}
						/>
					</div>
				)}
			</section>
			<section className="mb-10" id="contact">
				<div className="mt-28 container mx-auto px-4">
					<p className="text-center text-2xl md:text-4xl text-[#2B3E39] text-shadow max-w-2xl font-bold mx-auto mb-6 capitalize">
						{state?.contactText || `  `}
					</p>
					<p className="text-center text-sm font-light mb-8 max-w-xl mx-auto">
						{state?.contactSubText ||
							`Lorem ipsum dolor sit amet consectetur. In donec suspendisse
						ultricies id tortor purus mauris. Ligula molestie sed vivamus odio`}
					</p>
					<ContactFormCustom />
				</div>
			</section>
			<footer className="p-8 text-center bg-[#29292B] text-white">
				<img
					src={
						state?.logo
							? typeof state?.logo?.url === "string"
								? state?.logo?.url
								: URL.createObjectURL(state?.logo)
							: require("../../assets/logos/logo.png")
					}
					alt=""
					className={`${"h-6 mx-auto"}`}
				/>
				<p className="text-sm font-thin mt-6">
					{state?.footerSubText ||
						`Lorem ipsum dolor sit amet consectetur. Faucibus scelerisque porttitor
					nunc pharetra tempus venenatis. Magnis tortor a massa arcu viverra
					fermentum. Lorem ipsum dolor sit amet consectetur. Faucibus
					scelerisque porttitor nunc pharetra tempus venenatis. Magnis tortor a
					massa arcu viverra fermentum. Lorem ipsum dolor sit amet consectetur.
					Faucibus scelerisque porttitor nunc pharetra tempus venenatis. Magnis
					tortor a massa arcu viverra fermentum.`}{" "}
				</p>
				<div className="flex justify-center">
					<SocialMediaColored state={state} />
				</div>
			</footer>
		</div>
	);
};

let ContactFormCustom = () => {
	let init = { lastName: "", firstName: "", email: "", message: "" },
		[state, setState] = useState(init),
		[loading, setLoading] = useState(false),
		{ page, id } = useParams(),
		textChange = e => {
			let { value, name } = e.target;
			setState({ ...state, [name]: value });
		},
		dispatch = useDispatch(),
		handleSubmit = async e => {
			e?.preventDefault();
			if (!["businesses", "reviews"]?.includes(page)) return;
			if (
				!state?.email ||
				!state?.email ||
				!state?.firstName ||
				!state?.message
			)
				return toast.info("Please fill out all fields");

			setLoading(true);
			try {
				let res = await axios.post(
					`/api/v1/customize/feedback?customizedId=${id}`,
					{
						...state,
					}
				);
				toast.success(res?.data?.msg);
				setState(init);
			} catch (err) {
				if (err) console.log(err.response?.data?.data, { err });
				if (err?.response?.status === 429) toast.error(err?.response?.data);
				let error = err.response?.data?.data;
				if (error) {
					dispatch(returnErrors({ error, status: err?.response?.status }));
				}
			}
			setLoading(false);
		};

	let { t } = useContext(GlobalState);

	return (
		<div className="max-w-xl mx-auto p-4 rounded-lg bg-[#F5F6FB]">
			<form className="max-w-lg mx-auto">
				<div className="grid grid-cols-2 gap-x-8 gap-y-4">
					<div>
						<label className="text-[#2B3E39] font-light text-sm">
							{t(`First Name`)}
						</label>
						<input
							type="text"
							className="w-full h-10 md:h-12 px-4 rounded-lg bg-[#D1E5FF]"
							placeholder="John"
							value={state?.firstName}
							onChange={textChange}
							name="firstName"
						/>
					</div>
					<div>
						<label className="text-[#2B3E39] font-light text-sm">
							{t(`Last Name`)}
						</label>
						<input
							type="text"
							className="w-full h-10 md:h-12 px-4 rounded-lg bg-[#D1E5FF]"
							placeholder="Smith"
							value={state?.lastName}
							onChange={textChange}
							name="lastName"
						/>
					</div>
					<div className="col-span-2">
						<label className="text-[#2B3E39] font-light text-sm">
							{t(`Email`)}
						</label>
						<input
							type="email"
							className="w-full h-10 md:h-12 px-4 rounded-lg bg-[#D1E5FF]"
							placeholder="johnSmith@gmail.com"
							value={state?.email}
							onChange={textChange}
							name="email"
						/>
					</div>
					<div className="col-span-2">
						<label className="text-[#2B3E39] font-light text-sm">
							{t(`Message`)}
						</label>
						<textarea
							rows={2}
							className="w-full p-4 rounded-lg bg-[#D1E5FF]"
							placeholder="Please type your message here..."
							value={state?.message}
							onChange={textChange}
							name="message"
							style={{
								resize: "none",
								height: "10rem",
							}}
						/>
					</div>
				</div>
				<div className="mt-8">
					{/* <button
						className={`h-12 px-4 rounded-md font-semibold text-sm whitespace-nowrap bg-[#2B3E39] text-white`}>
						Contact Us
					</button> */}
					<Buttons
						bg="bg-[#2B3E39]"
						css={`h-12 px-4 rounded-md font-semibold text-sm whitespace-nowrap bg-[#2B3E39] text-white`}
						onClick={handleSubmit}
						loading={loading}
						title={"Contact Us"}
						width={"auto"}
					/>
				</div>
			</form>
		</div>
	);
};

// customization feedback
