import React from "react";

const Profilerounded = ({ sm, md, lg, img, title }) => {
	return (
		<div>
			<img
				src={img || require("../../assets/avatar3.png")}
				alt={title || ""}
				className={`${sm && "h-10 w-10"} ${md && "h-12 w-12"} ${
					lg && "h-14 w-14"
				} rounded-full`}
			/>
		</div>
	);
};

export default Profilerounded;
