import { useState } from "react";

const CountdownList = () => {
  const [day, setDay] = useState();
  const [hour, setHour] = useState();
  const [mins, setMins] = useState();
  const [sec, setSec] = useState();

  const countDownDate = new Date("Feb 5, 2024 21:59:00");

  // Update the count down every 1 second
  var x = setInterval(function () {
    // Get today's date and time
    var now = new Date().getTime();

    // Find the distance between now and the count down date
    var distance = countDownDate - now;

    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    // Display the result in the element with id="demo"
    // setTimer(timer.days = days)
    // setTimer(timer.hour = hours)
    // setTimer(timer.mins = minutes)
    // setTimer(timer.sec = seconds)
    setDay(days);
    setHour(hours);
    setMins(minutes);
    setSec(seconds);

    // If the count down is finished, write some text
    if (distance < 0) {
      clearInterval(x);
      setDay(0);
      setHour(0);
      setMins(0);
      setSec(0);
    }
  }, 1000);

  return (
    <div className="flex gap-x-2 justify-around items-start">
      <div className="text-center flex  flex-col">
        <p className="f font-medium text-xl">{day}</p>
        <p className="text-xs font-extralight -mt-1">days</p>
      </div>
      <span>:</span>
      <div className="text-center flex  flex-col">
        <p className="f font-medium text-xl">{hour}</p>
        <p className="text-xs font-extralight -mt-1">hours</p>
      </div>
      <span>:</span>
      <div className="text-center flex  flex-col">
        <p className="f font-medium text-xl">{mins}</p>
        <p className="text-xs font-extralight -mt-1">minutes</p>
      </div>
      <span>:</span>
      <div className="text-center flex  flex-col">
        <p className="f font-medium text-xl">{sec}</p>
        <p className="text-xs font-extralight -mt-1">seconds</p>
      </div>
    </div>
  );
};

export default CountdownList;
