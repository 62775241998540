import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/button/button";
import { MdAdd } from "react-icons/md";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { returnErrors } from "../../data/Reducers/ErrorReducer";
import {
  imageUpload,
  logout,
  setPassword,
  setUser,
  setUserFail,
} from "../../data/Reducers/UserReducer";
import ModalComponents, { Buttons } from "../../components/ModalComponents";
import Input from "../../components/input/input";
import { useNavigate } from "react-router-dom";
import { addAdmin } from "../../data/Reducers/DetailsReducer";
import { actionFail } from "../../data/Reducers/OrgReducer";
import { GlobalState } from "../../data/Context";
import { useTranslation } from "react-i18next";

const Profile = () => {
  let { auth, details } = useSelector((state) => state),
    init = { oldPassword: "", newPassword: "" },
    [state, setState] = useState(init),
    textChange = (e) => {
      let { name, value } = e?.target;
      setState({ ...state, [name]: value });
    },
    [loading, setLoading] = useState(false),
    [submit, setSubmit] = useState(false),
    dispatch = useDispatch(),
    navigate = useNavigate(),
    [isOpen, setIsOpen] = useState(false),
    handleNext = async (e) => {
      e?.preventDefault();
      console.log({ state });
      if (!state?.oldPassword || !state?.newPassword) return;

      setLoading(true);
      try {
        let res = await axios.put(`/api/v1/user/update-password`, {
          ...state,
        });
        toast.success(res?.data?.msg);
        dispatch(setPassword());
      } catch (err) {
        if (err?.response?.status === 429 || err?.response?.status === 405)
          toast.error(err?.response?.data ? err?.response?.data : err?.message);
        console.log({ err });
        let error = err.response?.data?.data;
        if (error) {
          dispatch(returnErrors({ error, status: err?.response?.status }));
        }
        dispatch(setUserFail());
      }
      setLoading(false);
      setSubmit(true);
    };
  // console.log(auth.user?.privilege);
  useEffect(() => {
    if (submit && auth?.isPassword) {
      setState(init);
      setSubmit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.isPassword, submit]);

  let { lang, setLang, t, i18n } = useContext(GlobalState);

  const handleLanguageChange = (e) => {
    i18n.changeLanguage(e.target.value);
    setLang(e.target.value);
    // return;
  };

  return (
    <div>
      {auth.user?.isAdmin && (
        <div className="grid md:grid-cols-2 gap-4 mb-8">
          <div>
            <p className="text-xl text-main">{t(`Display image`)}</p>
          </div>
          <div>
            <UpdateProfilePicture />
          </div>
        </div>
      )}
      <div className="grid md:grid-cols-2 gap-4 mb-8">
        <div>
          <p className="text-xl">{t("Email")}</p>
          <p className="text-sm">
            {t(`Used to sign into and receive notifications for this account.`)}
          </p>
        </div>
        <div className="self-end">
          <Input
            type={"email"}
            label={t("Email")}
            onChange={textChange}
            name="email"
            readOnly
            value={auth?.user?.email}
          />
        </div>
      </div>
      <div className="grid md:grid-cols-2 gap-4 mb-8">
        <div>
          <p className="text-xl">{t(`Language`)}</p>
        </div>
        <div className="self-end">
          <select
            name="lang"
            value={lang}
            className="rounded-md text-main"
            onChange={handleLanguageChange}
          >
            <option value="en">En</option>
            <option value="fr">Fr</option>
          </select>
        </div>
      </div>
      <div className="grid md:grid-cols-2 gap-4 mb-8">
        <div>
          <p className="text-xl">{t(`Password`)}</p>
          <p className="text-sm">{t(`Edit password`)}</p>
        </div>
        <div className="self-end">
          <form>
            <Input
              type={"password"}
              label={t("Old Password")}
              onChange={textChange}
              name="oldPassword"
              value={state?.oldPassword}
            />
            <Input
              type={"password"}
              label={t("New Password")}
              onChange={textChange}
              name="newPassword"
              value={state?.newPassword}
            />
            {/* <!-- Submit button --> */}
            <div className="space-y-4">
              <Buttons
                type="submit"
                css="inline-block w-60 rounded bg-[#65D133] px-7 pb-2.5 pt-3 text-sm font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-main hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-main focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-main active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                data-te-ripple-init
                loading={loading}
                data-te-ripple-color="light"
                onClick={handleNext}
                title={t("Update")}
              />
            </div>
          </form>
        </div>
      </div>
      {auth.user?.privilege === "superadmin" && (
        <div>
          <div className="grid md:grid-cols-2 gap-4 mb-8">
            <div>
              <p className="text-xl">{t(`Add other admin`)}</p>
              <p className="text-sm">{t(`Enter their email to invite them`)}</p>
            </div>
            <div className="self-end">
              {/* <!-- Submit button --> */}
              <div className="space-y-4">
                <Button
                  children={t("Add Admin")}
                  buttonType={"primary"}
                  onClick={() => setIsOpen(true)}
                />
              </div>
              {/* </form> */}
            </div>
          </div>
          <div className="mb-10">
            <p className="text-2xl capitalize mb-4">{t(`Active Admin`)}</p>
            {details?.admin
              ?.filter((item) => item?.isEmailVerified)
              ?.map((item, i) => (
                <AdminUserData key={i} item={item} />
              ))}
          </div>

          <div className="mb-10">
            <p className="text-2xl capitalize mb-4">
              {t(`Outstanding Invitations`)}
            </p>
            {details?.admin
              ?.filter((item) => !item?.isEmailVerified)
              ?.map((item, i) => (
                <AdminUserData key={i} item={item} />
              ))}
          </div>
        </div>
      )}
      {!auth.user?.isAdmin && (
        <div>
          <div className="grid md:grid-cols-2 gap-4 mb-8">
            <div>
              <p className="text-xl text-main">{t("Display image")}</p>
            </div>
            <UpdateProfilePicture />
          </div>
          <div>
            <div>
              <p className="text-xl">
                {t(`Invitations to Join Organizations`)}
              </p>
              <p className="text-sm">{t(`No invitations currently.`)}</p>
            </div>
            <div className="mt-8">
              <Button
                children={t("logout")}
                buttonType={"primary"}
                title="Logout"
                onClick={() => {
                  dispatch(logout());
                  navigate("/");
                }}
              />
            </div>
          </div>
        </div>
      )}

      <AddNewAdmin isOpen={isOpen} toggle={() => setIsOpen(false)} />
    </div>
  );
};

export default Profile;

export const UpdateProfilePicture = () => {
  let { auth } = useSelector((state) => state),
    [logo, setLogo] = useState(false),
    [state, setState] = useState(auth?.user),
    { t } = useContext(GlobalState),
    [loading, setLoading] = useState(false),
    [submit, setSubmit] = useState(false),
    dispatch = useDispatch(),
    handleNext = async (e) => {
      e?.preventDefault();
      if (!logo) return;
      setLoading(true);
      try {
        let media = await imageUpload([logo]);
        let res = await axios.put(`/api/v1/user/update-avatar`, {
          ...state,
          avatar: media[0],
        });
        toast.success(res?.data?.msg);
        dispatch(setUser(res?.data));
      } catch (err) {
        if (err?.response?.status === 429 || err?.response?.status === 405)
          toast.error(err?.response?.data ? err?.response?.data : err?.message);
        console.log({ err });
        let error = err.response?.data?.data;
        if (error) {
          dispatch(returnErrors({ error, status: err?.response?.status }));
        }
        dispatch(setUserFail());
      }
      setLoading(false);
      setSubmit(true);
    };

  useEffect(() => {
    if (submit && auth?.isUpdated) {
      setSubmit(false);
      setLogo(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.isUpdated, submit]);

  useEffect(() => {
    if (auth?.user) setState(auth?.user);
  }, [auth?.user]);

  let handleChangeImage = (e) => {
    const file = e.target.files[0];
    let err = "";

    if (!file) return (err = `File, ${file?.name} does not exist`);
    if (!file.type.includes("image"))
      return (err = `File, ${file?.name} format not supported`);

    if (err) {
      return toast.error(err);
    } else {
      setLogo(file);
    }
  };

  return (
    <div>
      <div className="self-">
        <div class="relative w-fit myCursor file_upload">
          <img
            className="w-32 h-32 rounded-full"
            src={
              logo
                ? URL.createObjectURL(logo)
                : state?.avatar?.url
                ? state?.avatar.url
                : require("../../assets/avatar3.png")
            }
            alt={state?.firstName}
          />
          <span class="-bottom-2 right-4 absolute text-white flex items-center justify-center cursor-pointer w-10 h-10 hover:bg-green-400 bg-[#65D133] border-2 border-white rounded-xl">
            <MdAdd size={20} />
          </span>
          <input
            title="Upload file"
            type="file"
            name="file"
            id="file"
            className="myCursor"
            accept="image/*"
            onChange={handleChangeImage}
          />
        </div>
      </div>
      {logo && (
        <Buttons
          onClick={handleNext}
          loading={logo && loading}
          css={
            "rounded-xl text-capitalize hover:bg-main bg-[#65D133] text-sm text-white hover:text-white text-white p-3 font-bold my-2"
          }
          title={t("Update profile image")}
          loadCss={"#fff"}
        />
      )}
    </div>
  );
};

let AdminUserData = ({ item }) => {
  const {t} = useContext(GlobalState)
  return (
    <div className="grid md:grid-cols-2 gap-4 mb-8 border-b pb-4">
      <div>
        <p className="text-md font-medium">{t("Email")}</p>
        <p className="text-md font-medium">{item?.email}</p>
      </div>

      <div className="self-end">
        <p className="text-sm font-medium">{t("Role")}</p>
        <p className="text-sm font-medium capitalize">{item?.privilege}</p>
        <div className="space-y-4"></div>
      </div>
    </div>
  );
};

let AddNewAdmin = ({ isOpen, toggle }) => {
  let { details } = useSelector((state) => state),
    init = { firstName: "", lastName: "", email: "" },
    [state, setState] = useState(init),
    textChange = (e) => {
      let { name, value } = e?.target;
      setState({ ...state, [name]: value });
    },
    { t } = useContext(GlobalState),
    [loading, setLoading] = useState(false),
    [submit, setSubmit] = useState(false),
    dispatch = useDispatch(),
    handleNext = async (e) => {
      e?.preventDefault();
      console.log({ state });
      if (!state?.lastName || !state?.email || !state?.firstName) return;

      setLoading(true);
      try {
        let res = await axios.post(`/api/v1/user/manage-users`, {
          ...state,
        });
        toast.success(res?.data?.msg);
        dispatch(addAdmin(res?.data));
      } catch (err) {
        if (err?.response?.status === 429 || err?.response?.status === 405)
          toast.error(err?.response?.data ? err?.response?.data : err?.message);
        console.log({ err });
        let error = err.response?.data?.data;
        if (error) {
          dispatch(returnErrors({ error, status: err?.response?.status }));
        }
        dispatch(actionFail());
      }
      setLoading(false);
      setSubmit(true);
    };

  useEffect(() => {
    if (submit && details?.isAdded) {
      setState(init);
      toggle();
      setSubmit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details?.isAdded, submit]);
  return (
    <>
      <ModalComponents
        isOpen={isOpen}
        toggle={() => {
          setState(init);
          toggle();
        }}
        title={t("Add new Admin")}
      >
        <form>
          <Input
            type={"text"}
            label={t("First Name")}
            onChange={textChange}
            name="firstName"
            value={state?.firstName}
          />
          <Input
            type={"text"}
            label={t("Last Name")}
            onChange={textChange}
            name="lastName"
            value={state?.lastName}
          />
          <Input
            value={state?.email}
            type={"email"}
            label={t("Email")}
            onChange={textChange}
            name="email"
          />
          {/* <!-- Submit button --> */}
          <div className="space-y-4">
            <Buttons
              type="submit"
              css="inline-block w-full rounded bg-[#65D133] px-7 pb-2.5 pt-3 text-sm font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-main hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-main focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-main active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              data-te-ripple-init
              loading={loading}
              data-te-ripple-color="light"
              onClick={handleNext}
              title={"Next"}
            />
          </div>
        </form>
      </ModalComponents>
    </>
  );
};
