/* eslint-disable react/prop-types */
import { createContext, useEffect } from "react";
import { BiCategoryAlt, BiBook, BiBookReader } from "react-icons/bi";
import { RxPencil2 } from "react-icons/rx";
import { BsPlugin, BsEnvelopeCheck } from "react-icons/bs";
import { ImProfile } from "react-icons/im";
import { SiSubstack } from "react-icons/si";
import { useSelector } from "react-redux";
import { useState } from "react";
import { detectLanguage, fetchMachineTranslation } from "../i18n";
import { useTranslation } from "react-i18next";

export const GlobalState = createContext();

const DataProvider = ({ children }) => {
	const [nav, setNav] = useState(false);
	let [lang, setLang] = useState("");
	let handleCapitalize = word => {
		let splitter = word.trim().split(" ");
		let firstCap = splitter[0].split("");
		let remaining = splitter.slice(1, splitter.length).join(" ");

		let firstCapOne = firstCap[0].toUpperCase();
		let firstCapTwo = firstCap.slice(1, firstCap.length).join("");

		let joinFirst = `${firstCapOne}${firstCapTwo}`;

		return `${joinFirst} ${remaining}`;
	};

	let { t, i18n } = useTranslation();
	useEffect(() => {
		if (!nav && document.body.clientWidth > 992) {
			setNav(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let numberWithCommas = x => {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	};

	const toggleNav = () => {
		setNav(!nav);
	};

	let { auth } = useSelector(state => state);

	let sidebarList = [
		{
			name: "Home",
			url: "/home",
			icon: <BiCategoryAlt className="icon" size={24} />,
			permission: ["admin", "user", "organisation", "superadmin"],
		},
		{
			name: "Customization",
			url: "/customization",
			icon: <BiBook className="icon" size={24} />,
			permission: ["user", "organisation"],
		},
		{
			name: "My Organization",
			url: "/my-organization",
			icon: <RxPencil2 className="icon" size={24} />,
			permission: ["organisation"],
		},
		{
			name: "User",
			url: "/user",
			icon: <BiBookReader className="icon" size={24} />,
			permission: ["admin", "superadmin"],
		},
		{
			name: "Source Platform",
			url: "/exports",
			icon: <BsPlugin className="icon" size={24} />,
			permission: ["admin", "superadmin", "organisation"],
		},
		{
			name: "Subscriber",
			url: "/subscriber",
			icon: <SiSubstack className="icon" size={24} />,
			permission: ["admin", "superadmin"],
		},
		{
			name: "Configuration",
			url: "/configuration",
			icon: <BsEnvelopeCheck className="icon" size={24} />,
			permission: ["admin", "superadmin"],
		},
		{
			name: "Profile",
			url: "/profile",
			icon: <ImProfile className="icon" size={24} />,
			permission: ["admin", "user", "organisation", "superadmin"],
		},
	];

	const state = {
		handleCapitalize,

		numberWithCommas,

		sidebarList,
		auth,
		nav,
		toggleNav,
		lang,
		setLang,
		t,
		i18n
	};

	return <GlobalState.Provider value={state}>{children}</GlobalState.Provider>;
};

export default DataProvider;
