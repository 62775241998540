import { useNavigate } from "react-router-dom";
import Input from "../components/input/input";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { useState, useEffect, useContext } from "react";
import { ReuseBox } from "./otp";
import { Buttons } from "../components/ModalComponents";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { returnErrors } from "../data/Reducers/ErrorReducer";
import { register, setUserFail } from "../data/Reducers/UserReducer";
import { GlobalState } from "../data/Context";

const Register = () => {
	const navigate = useNavigate(),
		init = {
			lastName: "",
			firstName: "",
			email: "",
			password: "",
			businessName: "",
			confirmPassword: "",
		},
		[state, setState] = useState(init),
		textChange = e => {
			let { name, value } = e.target;
			setState({ ...state, [name]: value });
		},
		[loading, setLoading] = useState(false),
		[submit, setSubmit] = useState(false),
		{ auth } = useSelector(state => state),
		dispatch = useDispatch();

	let { t } = useContext(GlobalState);

	useEffect(() => {
		if (auth?.isRegistered && submit) {
			navigate("/otp", { state: { email: state?.email } });
		}
	}, [auth, submit, navigate, state?.email]);

	const handleNext = async e => {
		e?.preventDefault();
		console.log({ state });
		if (
			!state?.lastName ||
			!state?.email ||
			!state?.firstName ||
			!state?.password ||
			!state?.businessName
		)
			return;

		if (state?.password !== state?.confirmPassword)
			return toast.warn("Password do not match");
		setLoading(true);
		try {
			let res = await axios.post(`/api/v1/user`, { ...state });
			console.log({ resp: res?.data });
			toast.success(res?.data?.msg);
			dispatch(register(res?.data));
		} catch (err) {
			if (err?.response?.status === 429 || err?.response?.status === 405)
				toast.error(err?.response?.data ? err?.response?.data : err?.message);
			console.log({ err });
			let error = err.response?.data?.data;
			if (error) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			}
			dispatch(setUserFail());
		}
		setLoading(false);
		setSubmit(true);
	};

	return (
		<ReuseBox>
			<div className="max-w-md px-10 mt-16">
				<div className="bg-main text-white p-2 py-4 mb-4 rounded-md flex items-center justify-between">
					<p className="flex items-center gap-2 text-xs">
						<span>
							<BsFillCheckCircleFill />
						</span>{" "}
						{t(`Password Creation`)}
					</p>
					<HiOutlineArrowNarrowRight width={"50px"} />
					<p className={`flex items-center gap-2 text-xs opacity-50`}>
						<span>
							<BsFillCheckCircleFill />
						</span>{" "}
						{t(`Card connection`)}
					</p>
				</div>
				<p className="capitalize text-4xl font-thin mb-4">{t(`hello!`)}</p>
				<div>
					<p className="text-4xl font-extrabold mb-4">
						{t(`Create an account`)}
					</p>
					<form>
						<Input
							type={"text"}
							label={t("First Name")}
							onChange={textChange}
							name="firstName"
							value={state?.firstName}
						/>
						<Input
							type={"text"}
							label={t("Last Name")}
							onChange={textChange}
							name="lastName"
							value={state?.lastName}
						/>
						<Input
							value={state?.email}
							type={t("Email")}
							label={"E-mail"}
							onChange={textChange}
							name="email"
						/>
						<Input
							type={"text"}
							label={t("Business Name")}
							onChange={textChange}
							name="businessName"
							value={state?.businessName}
						/>

						<Input
							type={"password"}
							onChange={textChange}
							name="password"
							label={t("Password")}
							password
							value={state?.password}
						/>
						<Input
							onChange={textChange}
							name="confirmPassword"
							type={"password"}
							label={t("Confirm Password")}
							password
							value={state?.confirmPassword}
						/>

						{/* <!-- Submit button --> */}
						<div className="space-y-4">
							<Buttons
								type="submit"
								css="inline-block w-full rounded bg-[#65D133] px-7 pb-2.5 pt-3 text-sm font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-main hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-main focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-main active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
								data-te-ripple-init
								loading={loading}
								data-te-ripple-color="light"
								onClick={handleNext}
								title={"Next"}
							/>
							<button
								type="submit"
								class="inline-block w-full rounded border border-main px-7 pb-2.5 pt-3 text-sm font-medium uppercase leading-normal text-main shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-main hover:text-white hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-main focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-main active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
								data-te-ripple-init
								data-te-ripple-color="light"
								onClick={() => navigate("/login")}>
								{t("Login")}
							</button>
						</div>
					</form>
				</div>
			</div>
		</ReuseBox>
	);
};

export default Register;
