import Button from "../../components/button/button";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useContext } from "react";
import ModalComponents, {
	Buttons,
	LoadMore,
	MainPaginate,
	MainRanger,
} from "../../components/ModalComponents";
import moment from "moment";
import {
	detailsFail,
	loadDetailsUsers,
	updateUserData,
} from "../../data/Reducers/DetailsReducer";
import axios from "axios";
import { toast } from "react-toastify";
import { returnErrors } from "../../data/Reducers/ErrorReducer";
import { GlobalState } from "../../data/Context";

const Subscriber = () => {
	let { details } = useSelector(state => state),
		[page, setPage] = useState(1),
		[range, setRange] = useState(50),
		[loading, setLoading] = useState(false),
		[next, setNext] = useState(false),
		dispatch = useDispatch(),
		[isUpdate, setIsUpdate] = useState(null);

	const [itemOffset, setItemOffset] = useState(0);
	const endOffset = itemOffset + range;

	useEffect(() => {
		if (details?.subscriber_paginate?.next) {
			setNext(true);
		} else setNext(false);
	}, [details?.subscriber_paginate]);

	const handleSubmit = async e => {
		e?.preventDefault();
		if (!isUpdate) return;
		setLoading("update");
		try {
			let res = await axios.post(
				`/api/v1/subscription/cancel-subscription?id=${isUpdate?._id}`
			);
			console.log({ resp: res?.data });
			toast.success(res?.data?.msg);
			dispatch(updateUserData(res?.data));
			setIsUpdate(null);
		} catch (err) {
			if (err?.response?.status === 429 || err?.response?.status === 405)
				toast.error(err?.response?.data ? err?.response?.data : err?.message);
			console.log({ err });
			let error = err.response?.data?.data;
			if (error) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			}
			dispatch(detailsFail());
		}
		setLoading(false);
	};

	let { t } = useContext(GlobalState);

	if (!details?.subscriber) return;

	const currentItems = details?.subscriber?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(details?.subscriber?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % details?.subscriber?.length;
		setItemOffset(newOffset);
		setPage(1 + event?.selected);
	};

	let handleLoadMore = async () => {
		setLoading("loadmore");

		await dispatch(
			loadDetailsUsers({
				limit: Number(
					details?.subscriber_paginate?.nextPage *
						details?.subscriber_paginate?.limit
				),
				type: "organisation",
				subscriber: "organisation",
			})
		);

		setLoading(false);
	};

	return (
		<div>
			<div className="relative overflow-x-auto shadow-md sm:rounded-lg py-5">
				<MainRanger range={range} setRange={setRange} />
				<table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
					<thead className="text-lg text-white capitalize bg-[#65D133]">
						<tr>
							<th scope="col" className="px-6 py-3">
								{t(`Name`)}
							</th>
							<th scope="col" className="px-6 py-3">
								{t`Email`}
							</th>
							<th scope="col" className="px-6 py-3">
								{t("Company")}
							</th>
							<th scope="col" className="px-6 py-3">
								{t(`Registered Date`)}
							</th>
							<th scope="col" className="px-6 py-3">
								{t(`Subscription Date`)}
							</th>
							<th scope="col" className="px-6 py-3">
								{t(`Status`)}
							</th>
						</tr>
					</thead>
					<tbody>
						{currentItems?.map((item, i) => (
							<tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
								<th
									scope="row"
									className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
									{item?.lastName} {item?.firstName}
								</th>
								<td className="px-6 py-4">{item?.email}</td>
								<td className="px-6 py-4 capitalize">{item?.businessName}</td>
								<td className="px-6 py-4">
									{moment(item?.createdAt).format("DD/MM/YYYY hh:mm A")}
								</td>
								<td className="px-6 py-4">
									{moment(item?.subscription?.createdAt).format(
										"DD/MM/YYYY hh:mm A"
									)}
								</td>
								<td className="px-6 py-4 text-right">
									<Button
										buttonType={
											item?.subscription?.status ? "primary" : "suspended"
										}
										children={item?.subscription?.statusText || "Active"}
										width={"w-32 capitalize"}
										onClick={
											["active", "trialing", "past_due"]?.includes(
												item?.subscription?.statusText
											)
												? () => setIsUpdate(item)
												: null
										}
									/>
								</td>
							</tr>
						))}
					</tbody>
				</table>
				<MainPaginate handlePageClick={handlePageClick} pageCount={pageCount} />
				{page === pageCount && (
					<>
						<LoadMore
							loading={loading === "loadmore"}
							handleLoadMore={handleLoadMore}
							next={next}
						/>
					</>
				)}
				<ModalComponents
					isOpen={isUpdate ? true : false}
					title={`Cancel Subscription`}
					toggle={() => setIsUpdate(null)}>
					<div className="mx-20">
						<form className="space-y-4">
							<div className="my-auto w-100">
								<p className="text2 Lexend text-center">
									{t(`Do you want to cancel`)} {isUpdate?.businessName}{" "}
									{t(`subscription`)}?
								</p>
								<div className="pt-4 flex justify-center">
									<Buttons
										buttonType={"primary"}
										title={"Yes"}
										type="button"
										width={"w-fit me-2"}
										loading={loading === "update"}
										onClick={handleSubmit}
									/>
									<Button
										buttonType={"suspended"}
										children={"No"}
										type="button"
										width={"w-fit ms-2"}
										onClick={() => setIsUpdate(null)}
									/>
								</div>
							</div>
						</form>
					</div>
				</ModalComponents>
			</div>
		</div>
	);
};

export default Subscriber;
