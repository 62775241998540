import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { Buttons } from "../../../components/ModalComponents";
import Input from "../../../components/input/input";
import { ReuseBox, VerifyMail } from "../../otp";
import { returnErrors } from "../../../data/Reducers/ErrorReducer";
import { GlobalState } from "../../../data/Context";

const Verify = () => {
	let location = useLocation(),
		dispatch = useDispatch(),
		navigate = useNavigate(),
		[formValue, setFormValue] = useState(""),
		[code, setCode] = useState(""),
		[loading, setLoading] = useState(false);

	const handleChange = e => {
		const { name, value } = e.target;
		setFormValue({ ...formValue, [name]: value });
	};

	let { t } = useContext(GlobalState);

	const handleSubmit = async e => {
		e?.preventDefault();
		if (!formValue?.password || !code) return;
		setLoading(true);
		try {
			let res = await axios.post(`/api/v1/user/reset-password`, {
				...formValue,
				otp: code,
			});

			setLoading(false);
			toast.success(res?.data?.msg);
			navigate(`/login`, {
				state: { email: location?.state?.email },
			});
		} catch (err) {
			if (err) console.log(err.response?.data?.data, { err });
			if (err?.response?.status === 429) toast.error(err?.response?.data);
			let error = err.response?.data?.data;
			dispatch(returnErrors({ error, status: err?.response?.status }));
			setLoading(false);
		}
		setLoading(false);
	};

	return (
		<ReuseBox>
			<div className="max-w-md mx-auto px-4 self-center">
				<div className="text-center mb-8 max-w-md mx-auto">
					<p className="text-2xl font-semibold">
						{t(`Verify Your Email Address`)}
					</p>
					<p className="text-sm text-main">
						{t(`We've sent a reset mail to`)} {location?.state?.email}.{" "}
						{t(`Please check your inbox or promition folder`)}
					</p>
				</div>
				<form onSubmit={handleSubmit} className="max-w-md mx-auto">
					<VerifyMail
						code={code}
						setCode={setCode}
						text="confirm OTP"
						numInputs={5}
						loading2={loading}
					/>
					<Input
						type={"password"}
						label={"New Password"}
						onChange={handleChange}
						name="password"
						value={formValue.password}
						password
					/>
					{/* <!-- Submit button --> */}
					<div className="space-y-4">
						<Buttons
							onClick={handleSubmit}
							title={"Reset My Password"}
							type={"submit"}
							loading={loading}
							css="inline-block w-full rounded bg-main px-7 pb-2.5 pt-3 text-sm font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-main hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-main focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-main active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
						/>
					</div>
					<p className="text-xs text-center mt-4">
						{t(`Wrong email address?`)}
						<Link to="/forgot-password" className="">
							{t(`click here`)}
						</Link>
					</p>
				</form>
			</div>
		</ReuseBox>
	);
};

export default Verify;
