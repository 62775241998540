import { createSlice } from "@reduxjs/toolkit";
import { clearErrors, getErrorText } from "./ErrorReducer";
import { SetAuthToken } from "../Config";
import axios from "axios";
import { toast } from "react-toastify";
import { loadOrgUser } from "./OrgReducer";
import { loadDetailsUsers } from "./DetailsReducer";
import { addNewPlatform, addNewUserPlatform } from "./PlatformReducer";
import { loadContactForm, loadCustomizedPage } from "./CustomizeReducer";
import { getReviews } from "./ReviewsReducer";
import { loadWidgetPage } from "./WidgetReducer";

export const TOKEN = "POPUP_LOGIN";

let initialState = {
	user: null,
	token: localStorage.getItem(TOKEN),
	isAuth: false,
	loading: false,
	isRegistered: false,
	isLoggedIn: false,
	isUpdated: false,
	isPassword: null,
};

export const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		login: (state, { payload }) => {
			localStorage.setItem(TOKEN, payload?.token);
			state.isLoggedIn = true;
			state.token = payload?.token;
			state.user = payload?.data;
		},
		register: state => {
			state.isRegistered = true;
		},
		setUser: (state, { payload }) => {
			state.isUpdated = true;
			state.user = payload?.data;
		},
		getUser: (state, { payload }) => {
			if (payload?.token) {
				localStorage.setItem(TOKEN, payload?.token);
			}
			state.user = payload?.data ? payload?.data : null;
			state.isAuth = payload?.data ? true : false;
			state.loading = false;
		},
		getUserFail: state => {
			state.isAuth = false;
			state.loading = false;
		},
		getUserLoading: state => {
			state.loading = true;
		},
		setPassword: state => {
			state.isPassword = true;
		},
		setUserFail: state => {
			state.isUpdated = false;
			state.isLoggedIn = false;
			state.isRegistered = false;
			state.isPassword = false;
		},
		logout: state => {
			localStorage.removeItem(TOKEN);
			state.isAuth = false;
			state.user = null;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	login,
	logout,
	getUser,
	setPassword,
	setUser,
	setUserFail,
	getUserFail,
	getUserLoading,
	register,
} = userSlice.actions;

export default userSlice.reducer;

export const MergedData = (data, payload) => {
	let ids = new Set(payload.map(d => d._id));
	let updatateData = [...payload, ...data.filter(d => !ids.has(d._id))];
	return updatateData?.sort((a, b) => a?.createdAt - b?.createdAt);
};

export const EditData = (data, payload) => {
	let updatateData =
		data?.length > 0
			? data.map(item => (item._id !== payload._id ? item : payload))
			: data;
	return updatateData;
};

export const DeleteData = (data, payload) => {
	let filterItem =
		data?.length > 0 ? [...data.filter(item => item._id !== payload._id)] : [];
	return filterItem;
};

// GET USER INFO
export const loadUser = () => async dispatch => {
	let token = localStorage.getItem(TOKEN);
	if (token) SetAuthToken(token);

	dispatch(getUserLoading());
	dispatch(clearErrors());
	try {
		let res = await axios.get(`/api/v1/user`);
		if (res?.data?.data?.privilege) {
			dispatch(getUser(res.data));
			dispatch(addNewPlatform("get"));
			dispatch(getReviews("pending"));
			dispatch(getReviews("hide"));
			dispatch(getReviews("display"));
			if (res?.data?.data?.privilege === "organisation") {
				dispatch(loadOrgUser());
			}
			if (!res?.data?.data?.isAdmin) {
				dispatch(loadCustomizedPage("get"));
				dispatch(addNewUserPlatform("get"));
				dispatch(loadContactForm());
				dispatch(loadWidgetPage());
			}
			if (res?.data?.data?.isAdmin) {
				dispatch(loadDetailsUsers({ type: "superadmin" }));
				dispatch(loadDetailsUsers({ type: "user" }));
				dispatch(loadDetailsUsers({ type: "organisation" }));
				dispatch(
					loadDetailsUsers({ type: "organisation", subscriber: "organisation" })
				);
			}
		} else {
			dispatch(getUserFail());
		}
	} catch (err) {
		if (err) console.log(err.response?.data?.data, { err });
		if (err?.response?.status === 429) toast.error(err?.response?.data);
		dispatch(getUserFail());
		dispatch(
			getErrorText(
				err?.response?.data?.data ? err?.response?.data?.data?.[0]?.msg : ""
			)
		);
	}
};

export const imageUpload = async images => {
	let imgArr = [];
	for (const item of images) {
		// console.log({ item });
		let post = new FormData();
		post.append(`file`, item);

		let res = await axios.post(`/api/v1/file`, post, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
		const data = await res?.data?.data;
		// console.log({ data });
		Array.isArray(data) ? (imgArr = [...imgArr, ...data]) : imgArr.push(data);
	}
	return imgArr;
};
