import { createSlice } from "@reduxjs/toolkit";
import { clearErrors, returnErrors } from "./ErrorReducer";
import axios from "axios";
import { toast } from "react-toastify";
import { imageUpload } from "./UserReducer";

let initialState = {
	data: null,
	isUpdated: false,
	page: null,
	reviews: null,
	paginate: null,
	contact: null,
	contact_paginate: null,
	isFound: false,
	mainSearch: [],
	search_paginate: null,
	search: "",
};

export const customizeSlice = createSlice({
	name: "customize",
	initialState,
	reducers: {
		getOrgDetails: (state, { payload }) => {
			state.data = payload?.data || payload;
		},
		getOrgDetailsBusiness: (state, { payload }) => {
			state.page = payload?.data || payload;
			state.reviews = payload?.reviews || [];
			state.paginate = payload?.paginate || null;
		},
		getOrgContactForm: (state, { payload }) => {
			state.contact = payload?.data || payload;
			state.contact_paginate = payload?.paginate || null;
		},
		getOrgSearchContactForm: (state, { payload }) => {
			state.mainSearch =
				payload?.search === state?.search
					? payload?.data || payload
					: state?.mainSearch;
			state.search_paginate =
				payload?.search === state?.search
					? payload?.paginate || null
					: state?.search_paginate;
			state.isFound = true;
		},
		getOrgSearch: (state, { payload }) => {
			state.search = payload?.search || payload;
		},
		resetContactSearch: state => {
			state.search = "";
			state.mainSearch = [];
			state.search_paginate = null;
			state.isFound = false;
		},
		updateOrgDetails: (state, { payload }) => {
			state.isUpdated = true;
			state.data = payload?.data || payload || state?.data;
		},
		actionFail: state => {
			state.isDeleted = false;
			state.isUpdated = false;
		},
	},
});
export const {
	getOrgDetails,
	getOrgDetailsBusiness,
	updateOrgDetails,
	actionFail,
	getOrgContactForm,
	resetContactSearch,
	getOrgSearch,
	getOrgSearchContactForm,
} = customizeSlice.actions;

export const loadCustomizedPage = (type, param, data) => async dispatch => {
	dispatch(clearErrors());
	try {
		let res;
		if (type === "get") {
			if (!param) {
				res = await axios.get(`/api/v1/customize`);
				dispatch(getOrgDetails(res.data));
			} else {
				res = await axios.get(`/api/v1/customize/${param}`);
				dispatch(getOrgDetailsBusiness(res.data));
			}
		} else {
			let logo = data?.logo;
			let landingPageImage = data?.landingPageImage;
			let offerImage = data?.offerImage;
			if (type !== "get" && data?.logo && !data?.logo?._id) {
				let media = await imageUpload([data.logo]);
				logo = media[0];
			}
			if (type !== "get" && data?.offerImage && !data?.offerImage?._id) {
				let media = await imageUpload([data.offerImage]);
				offerImage = media[0];
			}
			if (
				type !== "get" &&
				data?.landingPageImage &&
				!data?.landingPageImage?._id
			) {
				let media = await imageUpload([data.landingPageImage]);
				landingPageImage = media[0];
			}
			res = await axios.post(`/api/v1/customize`, {
				...data,
				logo,
				offerImage,
				landingPageImage,
			});
			dispatch(updateOrgDetails(res.data));
		}
		if (type && type !== "get") {
			toast.success(res?.data?.msg);
		}
	} catch (err) {
		if (err) console.log(err.response?.data?.data, { err });
		if (err?.response?.status === 429) toast.error(err?.response?.data);
		dispatch(actionFail());
		if (type && type !== "get") {
			let error = err.response?.data?.data;
			if (error) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			}
		}
	}
};

export const loadContactForm = data => async dispatch => {
	dispatch(clearErrors());
	try {
		if (data?.search) dispatch(getOrgSearch({search:data?.search}));
		let res = await axios.get(
			`/api/v1/customize/feedback?type=all${
				data?.limit ? `&limit=${data?.limit}` : ""
			}${data?.search ? `&search=${data?.search}` : ""}`
		);
		dispatch(
			data?.search
				? getOrgSearchContactForm(res?.data)
				: getOrgContactForm(res.data)
		);
	} catch (err) {
		if (err) console.log(err.response?.data?.data, { err }, "contact-feedback");
		if (err?.response?.status === 429) toast.error(err?.response?.data);
		dispatch(actionFail());
	}
};
