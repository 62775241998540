import { useContext, useEffect, useState } from "react";
import Button from "../../components/button/button";
import { useDispatch, useSelector } from "react-redux";
import ModalComponents, { Buttons } from "../../components/ModalComponents";
import Input from "../../components/input/input";
import { toast } from "react-toastify";
import axios from "axios";
import { actionFail, addOrgUser } from "../../data/Reducers/OrgReducer";
import { returnErrors } from "../../data/Reducers/ErrorReducer";
import { useNavigate } from "react-router-dom";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { OrgUserAll } from "../user/[id]";
import { GlobalState } from "../../data/Context";

const MyOrganization = () => {
	let 
		navigate = useNavigate(),
		{ auth } = useSelector(state => state);

	useEffect(() => {
		if (auth?.user?.privilege !== "organisation") navigate(-1);
	}, [auth?.user, navigate]);

	return (
		<div>
			<div className="my-8">
				<Members />
			</div>
		</div>
	);
};

export default MyOrganization;

export const Billing = () => {
	// eslint-disable-next-line no-unused-vars
	const [hover, setHover] = useState(false),
		navigate = useNavigate(),
		{t} = useContext(GlobalState);
	
		return (
			<div
				className="bg-white rounded-md text-center p-4 h-[450px] relative w-80"
				//   style={{ width: "300px !important" }}
			>
				{hover && (
					<div className="absolute inset-0 bg-[#65D133] opacity-5 rounded-md"></div>
				)}
				<div className="flex flex-col justify-between h-full relative z-5">
					<div>
						<p className="text-sm md:text-lg font-semibold text-main">
							{t("Elevate Your Business with the Premium FlashReview Plan:")}
						</p>
					</div>
					<div className="space-y-1">
						<div className="flex gap-2 items-center">
							<span className="text-main">
								<IoCheckmarkCircleOutline />
							</span>
							<span className="text-md text-left">{t(`Total Review Control`)}</span>
						</div>
						<div className="flex gap-2 items-center">
							<span className="text-main">
								<IoCheckmarkCircleOutline />
							</span>
							<span className="text-md text-left">
								{t("In-depth Review Analytics")}
							</span>
						</div>
						<div className="flex gap-2 items-center">
							<span className="text-main">
								<IoCheckmarkCircleOutline />
							</span>
							<span className="text-md text-left">
								{t(`Reach Across Multiple Platforms`)}
							</span>
						</div>
						<div className="flex gap-2 items-center">
							<span className="text-main">
								<IoCheckmarkCircleOutline />
							</span>
							<span className="text-md text-left">
								{t(`Personalized Business Page`)}
							</span>
						</div>
						<div className="flex gap-2 items-center">
							<span className="text-main">
								<IoCheckmarkCircleOutline />
							</span>
							<span className="text-md text-left">
								{t(`QR Code Promotions Made Easy`)}
							</span>
						</div>
						<div className="flex gap-2 items-center">
							<span className="text-main">
								<IoCheckmarkCircleOutline />
							</span>
							<span className="text-md text-left">
								{t(`Priority Customer Support`)}
							</span>
						</div>
						<div className="flex gap-2 items-center">
							<span className="text-main">
								<IoCheckmarkCircleOutline />
							</span>
							<span className="text-md text-left">{t(`Unlimited Sub-Users`)}</span>
						</div>
						<div className="flex gap-2 items-center">
							<span className="text-main">
								<IoCheckmarkCircleOutline />
							</span>
							<span className="text-md text-left">
								{t(`Expert Negative Review Handling`)}
							</span>
						</div>
					</div>
					<div>
						<p className="text-2xl font-medium mb-2">$39.99/month</p>
						{/* {["price"]?.includes(page) && ( */}
						<Button
							children={"Get Started"}
							buttonType={`${hover ? "main" : "primary"}`}
							width={"w-3/4"}
							onClick={() => navigate("/checkout")}
						/>
						{/* )} */}
					</div>
				</div>
			</div>
		);
};

const Members = () => {
	let { organisation } = useSelector(state => state),
		[isOpen, setIsOpen] = useState(false);

	let { t } = useContext(GlobalState);

	return (
		<div>
			<div className="mb-8">
				<p className="text-2xl capitalize mb-4">{t(`Add new member`)}</p>
				<Button
					children={t("Add")}
					buttonType={"primary"}
					onClick={() => setIsOpen(true)}
				/>
			</div>
			<div className="mb-10">
				<p className="text-2xl capitalize mb-4">{t(`organization Members`)}</p>

				<OrgUserAll
					state={organisation?.data?.filter(item => item?.isEmailVerified)}
				/>
			</div>

			<div className="mb-10">
				<p className="text-2xl capitalize mb-4">
					{t(`Outstanding Invitations`)}
				</p>
				<OrgUserAll
					state={organisation?.data?.filter(item => !item?.isEmailVerified)}
				/>
			</div>
			<AddNewMembers isOpen={isOpen} toggle={() => setIsOpen(false)} />
		</div>
	);
};

export let OrgUserData = ({ item }) => {
	const {t} = useContext(GlobalState)
	return (
		<div className="flex items-center gap-12">
			<div>
				<p className="text-xl font-medium text-main">{t("Email")}</p>
				<p className="text-xl font-medium">{item?.email}</p>
			</div>
			<div>
				<p className="text-md font-medium text-main">{t("Role")}</p>
				<p className="text-md font-medium">
					{item?.privilege === "organisation" ? "Admin" : "User"}
				</p>
			</div>
		</div>
	);
};

let AddNewMembers = ({ isOpen, toggle }) => {
	let { organisation } = useSelector(state => state),
		init = { firstName: "", lastName: "", email: "" },
		[state, setState] = useState(init),
		{t} = useContext(GlobalState),
		textChange = e => {
			let { name, value } = e?.target;
			setState({ ...state, [name]: value });
		},
		[loading, setLoading] = useState(false),
		[submit, setSubmit] = useState(false),
		dispatch = useDispatch(),
		handleNext = async e => {
			e?.preventDefault();
			console.log({ state });
			if (!state?.lastName || !state?.email || !state?.firstName) return;

			setLoading(true);
			try {
				let res = await axios.post(`/api/v1/user/manage-organization`, {
					...state,
				});
				toast.success(res?.data?.msg);
				dispatch(addOrgUser(res?.data));
			} catch (err) {
				if (err?.response?.status === 429 || err?.response?.status === 405)
					toast.error(err?.response?.data ? err?.response?.data : err?.message);
				console.log({ err });
				let error = err.response?.data?.data;
				if (error) {
					dispatch(returnErrors({ error, status: err?.response?.status }));
				}
				dispatch(actionFail());
			}
			setLoading(false);
			setSubmit(true);
		};

	useEffect(() => {
		if (submit && organisation?.isAdded) {
			setState(init);
			toggle();
			setSubmit(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [organisation?.isAdded, submit]);
	return (
		<>
			<ModalComponents
				isOpen={isOpen}
				toggle={() => {
					setState(init);
					toggle();
				}}
				title={t("Add new member")}>
				<form>
					<Input
						type={"text"}
						label={"First Name"}
						onChange={textChange}
						name="firstName"
						value={state?.firstName}
					/>
					<Input
						type={"text"}
						label={"Last Name"}
						onChange={textChange}
						name="lastName"
						value={state?.lastName}
					/>
					<Input
						value={state?.email}
						type={"email"}
						label={"E-mail"}
						onChange={textChange}
						name="email"
					/>
					{/* <!-- Submit button --> */}
					<div className="space-y-4">
						<Buttons
							type="submit"
							css="inline-block w-full rounded bg-[#65D133] px-7 pb-2.5 pt-3 text-sm font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-main hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-main focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-main active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
							data-te-ripple-init
							loading={loading}
							data-te-ripple-color="light"
							onClick={handleNext}
							title={"Next"}
						/>
					</div>
				</form>
			</ModalComponents>
		</>
	);
};
