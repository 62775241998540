import { useState, useEffect, useContext } from "react";
import Button from "../../components/button/button";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  LoadMore,
  MainPaginate,
  MainRanger,
} from "../../components/ModalComponents";
import { loadDetailsUsers } from "../../data/Reducers/DetailsReducer";
import { GlobalState } from "../../data/Context";

const User = () => {
  const navigate = useNavigate();
  let { details } = useSelector((state) => state),
    [page, setPage] = useState(1),
    [range, setRange] = useState(50),
    [loading, setLoading] = useState(false),
    [next, setNext] = useState(false),
    dispatch = useDispatch();

  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + range;

  useEffect(() => {
    if (details?.organisation_paginate?.next) {
      setNext(true);
    } else setNext(false);
  }, [details?.organisation_paginate]);

  let { t } = useContext(GlobalState);

  if (!details?.organisation) return;

  const currentItems = details?.organisation?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(details?.organisation?.length / range);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * range) % details?.organisation?.length;
    setItemOffset(newOffset);
    setPage(1 + event?.selected);
  };

  let handleLoadMore = async () => {
    setLoading(true);

    await dispatch(
      loadDetailsUsers({
        limit: Number(
          details?.organisation_paginate?.nextPage *
            details?.organisation_paginate?.limit
        ),
        type: "organisation",
      })
    );

    setLoading(false);
  };
  return (
    <div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg py-5">
        <MainRanger range={range} setRange={setRange} />
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-lg text-white capitalize bg-[#65D133]">
            <tr>
              <th scope="col" className="px-6 py-3">
                {t(`Name`)}
              </th>
              <th scope="col" className="px-6 py-3">
                {t(`Email`)}
              </th>
              <th scope="col" className="px-6 py-3">
                {t(`Role`)}
              </th>
              <th scope="col" className="px-6 py-3">
                {t(`Company`)}
              </th>
              <th scope="col" className="px-6 py-3">
                {t(`Status`)}
              </th>
            </tr>
          </thead>
          <tbody>
            {currentItems?.map((item, i) => (
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {item?.lastName} {item?.firstName}
                </th>
                <td className="px-6 py-4">{item?.email}</td>
                <td className="px-6 py-4 capitalize">{item?.privilege}</td>
                <td className="px-6 py-4">{item?.businessName}</td>
                <td className="px-6 py-4 text-right">
                  <Button
                    buttonType={item?.status ? "primary" : "suspended"}
                    children={
                      item?.status ? "Active" : item?.statusText || "inactive"
                    }
                    onClick={() => {
                      navigate(`/user/${item?.businessName}`, {
                        state: item?._id,
                      });
                    }}
                    width={"capitalize"}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <MainPaginate handlePageClick={handlePageClick} pageCount={pageCount} />
        {page === pageCount && (
          <>
            <LoadMore
              loading={loading}
              handleLoadMore={handleLoadMore}
              next={next}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default User;
