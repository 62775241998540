import { useContext, useState } from "react";
import Button from "../button/button";
import { useNavigate } from "react-router-dom";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { fetchMachineTranslation } from "../../i18n";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { GlobalState } from "../../data/Context";

const SubscriptionCard = ({ recomended, text, plan, price }) => {
  const { t } = useContext(GlobalState);
  const [hover, setHover] = useState(false);
  const navigate = useNavigate()
  return (
    <div
      className="bg-white rounded-md text-center p-4 h-[450px] relative"
      style={{ width: "300px !important" }}
    >
      {hover && (
        <div className="absolute inset-0 bg-[#65D133] opacity-5 rounded-md"></div>
      )}
      <div className="flex flex-col justify-between h-full relative z-5">
        <div>
          <p className="text-xl md:text-2xl mb-1 font-medium text-main">
            {t(`FlashReview`)}
          </p>
          <p className="text-sm md:text-lg font-semibold">{text}</p>
        </div>
        <div className="space-y-1">
          <div className="flex gap-2 items-center">
            <span className="text-main">
              <IoCheckmarkCircleOutline />
            </span>
            <span className="text-sm md:text-lg text-left">{t(`p one`)}</span>
          </div>
          <div className="flex gap-2 items-center">
            <span className="text-main">
              <IoCheckmarkCircleOutline />
            </span>
            <span className="text-sm md:text-lg text-left">{t(`p two`)}</span>
          </div>
          <div className="flex gap-2 items-center">
            <span className="text-main">
              <IoCheckmarkCircleOutline />
            </span>
            <span className="text-sm md:text-lg text-left">{t(`p three`)}</span>
          </div>
          <div className="flex gap-2 items-center">
            <span className="text-main">
              <IoCheckmarkCircleOutline />
            </span>
            <span className="text-sm md:text-lg text-left">{t(`p four`)}</span>
          </div>
          <div className="flex gap-2 items-center">
            <span className="text-main">
              <IoCheckmarkCircleOutline />
            </span>
            <span className="text-sm md:text-lg text-left">{t(`p five`)}</span>
          </div>
          <div className="flex gap-2 items-center">
            <span className="text-main">
              <IoCheckmarkCircleOutline />
            </span>
            <span className="text-sm md:text-lg text-left">{t(`p six`)}</span>
          </div>
          <div className="flex gap-2 items-center">
            <span className="text-main">
              <IoCheckmarkCircleOutline />
            </span>
            <span className="text-sm md:text-lg text-left">{t(`p seven`)}</span>
          </div>
        </div>
        <div>
          <p className="text-2xl font-medium mb-2 flex flex-col gap-0.5"><span className="text-gray-400 line-through text-xl font-light">{price}</span> <span>$9/month</span></p>
          {/* {["price"]?.includes(page) && ( */}
          <Button
            children={t("Get Started")}
            buttonType={`${hover ? "main" : "primary"}`}
            width={"w-3/4"}
            onClick={() => navigate("/checkout")}
          />
          {/* )} */}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionCard;
