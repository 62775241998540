import cardBg from "../../assets/card-bg.png";

const ExportCard = ({ image, title, toggle, logo, item }) => {
	return (
		<div
			style={{ backgroundImage: `url(${cardBg})`, backgroundSize: "contain" }}
			className="max-w-sm p-6 bg-[#F7F8F4] border border-gray-200 rounded-lg shadow text-white cursor-pointer"
			onClick={toggle}>
			<img
				src={item?.image?.url || logo || require(`../../assets/${image}`)}
				alt={item?.name || title}
				className="mx-auto h-20"
			/>
			<p className="mt-2 text-xl text-black text-center capitalize">
				{item?.name || title}
			</p>
		</div>
	);
};

export default ExportCard;
