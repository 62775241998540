import { combineReducers } from "redux";
import UserReducer from "./UserReducer";
import { errorSlice, settingsSlice } from "./ErrorReducer";
import { orgSlice } from "./OrgReducer";
import { detailsSlice } from "./DetailsReducer";
import { platformSlice } from "./PlatformReducer";
import { customizeSlice } from "./CustomizeReducer";
import { reviewSlice } from "./ReviewsReducer";
import { widgetSlice } from "./WidgetReducer";

const rootReducer = combineReducers({
	auth: UserReducer,
	error: errorSlice.reducer,
	organisation: orgSlice.reducer,
	details: detailsSlice.reducer,
	platform: platformSlice.reducer,
	customize: customizeSlice.reducer,
	reviews: reviewSlice.reducer,
	widget: widgetSlice.reducer,
	settings: settingsSlice.reducer,
});

export default rootReducer;
