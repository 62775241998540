import { useContext, useState } from "react";
// import Button from "../button/button";
import Profilerounded from "../profile-rounded/profile-rounded";
import { Rating } from "react-simple-star-rating";
import { useParams } from "react-router-dom";
import { Buttons } from "../ModalComponents";
import { useDispatch } from "react-redux";
import { updateReviewStates } from "../../data/Reducers/ReviewsReducer";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetchMachineTranslation } from "../../i18n";
import { useEffect } from "react";
import { GlobalState } from "../../data/Context";

const CustomerReview = ({ text, star, item, type }) => {
	const [hover, setHover] = useState(false),
		{ page } = useParams(),
		[loading, setLoading] = useState(""),
		dispatch = useDispatch(),
		{ auth } = useSelector(state => state),
		handleSubmit = load => async e => {
			e?.preventDefault();
			setLoading(load);
			await dispatch(updateReviewStates("update", item, type, load));
			setLoading("");
		};
	let { lang, t } = useContext(GlobalState);
	return (
		<div
			className="relative bg-white shadow-md w-72 rounded-2xl p-4 h-80 mx-auto"
			style={{ width: "200px !important" }}
			onMouseEnter={
				["businesses", "reviews", "customization"]?.includes(page) &&
				type !== "feedback"
					? () => {}
					: auth?.user?.isAdmin
					? () => {}
					: () => setHover(true)
			}
			onMouseLeave={() => setHover(false)}>
			{hover && (
				<>
					<div className="rounded-2xl absolute inset-0 opacity-10 bg-black"></div>
					<div className="rounded-2xl absolute inset-0 flex items-center justify-center flex-col gap-4">
						{type && ["pending", "hide"]?.includes(type) && (
							<Buttons
								onClick={handleSubmit("display")}
								css={"primary"}
								title={"Display Review"}
								loading={loading === "display"}
							/>
						)}
						{type && ["pending", "display"]?.includes(type) && (
							<Buttons
								css={"second"}
								title={"Hide Review"}
								loading={loading === "hide"}
								onClick={handleSubmit("hide")}
							/>
						)}
						{type && ["feedback"]?.includes(type) && (
							<a
								href={`mailto:${item.email}`}
								className="h-10 px-4 rounded-md font-semibold text-sm whitespace-nowrap border border-main text-main hover:bg-main hover:text-white">
								{t(`Send Mail`)}
							</a>
						)}
					</div>
				</>
			)}
			<div className="flex flex-col justify-between h-full">
				<div>
					{type !== "feedback" && (
						<>
							{star && !item?.rating && (
								<div className="flex justify-between items-center mb-4">
									<div class="flex items-center mb-5">
										<svg
											aria-hidden="true"
											class="text-yellow-400 w-7 h-7"
											fill="currentColor"
											viewBox="0 0 20 20"
											xmlns="http://www.w3.org/2000/svg">
											<title>First star</title>
											<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
										</svg>
										<svg
											aria-hidden="true"
											class="text-yellow-400 w-7 h-7"
											fill="currentColor"
											viewBox="0 0 20 20"
											xmlns="http://www.w3.org/2000/svg">
											<title>Second star</title>
											<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
										</svg>
										<svg
											aria-hidden="true"
											class="text-yellow-400 w-7 h-7"
											fill="currentColor"
											viewBox="0 0 20 20"
											xmlns="http://www.w3.org/2000/svg">
											<title>Third star</title>
											<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
										</svg>
										<svg
											aria-hidden="true"
											class="text-yellow-400 w-7 h-7"
											fill="currentColor"
											viewBox="0 0 20 20"
											xmlns="http://www.w3.org/2000/svg">
											<title>Fourth star</title>
											<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
										</svg>
										<svg
											aria-hidden="true"
											class="text-gray-300 w-7 h-7 dark:text-gray-500"
											fill="currentColor"
											viewBox="0 0 20 20"
											xmlns="http://www.w3.org/2000/svg">
											<title>Fifth star</title>
											<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
										</svg>
									</div>
									<span className="icon">
										<Profilerounded sm />
									</span>
								</div>
							)}
							{item?.rating && (
								<div className="flex justify-between items-center mb-4">
									<Rating
										initialValue={item?.rating}
										onClick={() => null}
										// fillColor="text-yellow-400"
										// fillColor="#fdcb53"
										className="text-yellow-400"
										size={30}
										readonly
									/>
									<span className="icon">
										<Profilerounded
											sm
											img={item?.platform?.image?.url}
											title={item?.platform?.name}
										/>
									</span>
								</div>
							)}
						</>
					)}
					<p className="text-xs text-text">
						{t(item?.content) || t(item?.review) || t(text)}
					</p>
				</div>
				<div className="flex gap-4">
					{type !== "feedback" && (
						<img
							src={item?.properties?.avatar || ""}
							alt={item?.properties?.name || item?.lastName || "customer"}
							className="h-8 w-8 rounded-full bg-[#65D133]"
						/>
					)}
					<div className="text-main">
						<p className="text-sm font-bold">
							{type === "feedback" ? (
								<>
									{item?.lastName} {item?.firstName}
								</>
							) : (
								item?.properties?.name || item?.name || `Ajani Ben Dara`
							)}
						</p>
						<p className="text-xs font-thin italic">
							{item?.rating
								? ""
								: type === "feedback"
								? item?.email
								: item?.loc}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CustomerReview;
