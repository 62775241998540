import React from "react";
import {
	// FaFacebook, FaTwitter,
	FaInstagram,
} from "react-icons/fa";

const SocialMediaIcons = () => {
	return (
		<div className="flex space-x-2">
			{/* <a
				href="#top"
				className="flex items-center justify-center w-8 h-8 rounded-full bg-[#65D133] hover:bg-green-500 transition-colors">
				<FaFacebook className="text-black" />
			</a>
			<a
				href="#top"
				className="flex items-center justify-center w-8 h-8 rounded-full bg-[#65D133] hover:bg-green-500 transition-colors">
				<FaTwitter className="text-black" />
			</a> */}
			<a
				href="https://instagram.com/flash.reviews?igshid=MjEwN2IyYWYwYw"
				target="_blank"
				rel="noreferrer"
				className="flex items-center justify-center w-8 h-8 rounded-full bg-[#65D133] hover:bg-green-500 transition-colors">
				<FaInstagram className="text-black" />
			</a>
		</div>
	);
};

export default SocialMediaIcons;
