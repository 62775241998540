import { createSlice } from "@reduxjs/toolkit";
import { DeleteData } from "./UserReducer";
import { clearErrors } from "./ErrorReducer";
import axios from "axios";
import { toast } from "react-toastify";

let initialState = {
	data: [],
	isAdded: false,
	isDeleted: null,
};

export const orgSlice = createSlice({
	name: "organisation",
	initialState,
	reducers: {
		getOrgUser: (state, { payload }) => {
			state.data = payload?.data || payload;
		},
		addOrgUser: (state, { payload }) => {
			let data = payload?.data || payload;
			state.isAdded = true;
			state.data = [data, ...state?.data];
		},
		deleteOrgUser: (state, { payload }) => {
			let data = payload?.data || payload;
			state.isDeleted = true;
			state.data = DeleteData(state?.data, data);
		},
		actionFail: state => {
			state.isDeleted = false;
			state.isAdded = false;
		},
	},
});
export const { getOrgUser, addOrgUser, deleteOrgUser, actionFail } =
	orgSlice.actions;

export const loadOrgUser = () => async dispatch => {
	dispatch(clearErrors());
	try {
		let res = await axios.get(`/api/v1/user/manage-organization`);
		dispatch(getOrgUser(res.data));
	} catch (err) {
		if (err) console.log(err.response?.data?.data, { err });
		if (err?.response?.status === 429) toast.error(err?.response?.data);
		dispatch(actionFail());
	}
};
