import React, { useContext, useEffect, useState } from "react";
import Input from "../../components/input/input";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { returnErrors, setSettings } from "../../data/Reducers/ErrorReducer";
import { Buttons } from "../../components/ModalComponents";
import { GlobalState } from "../../data/Context";

const Config = () => {
	let { t } = useContext(GlobalState);

	let [loading, setLoading] = useState(false),
		{ settings } = useSelector(state => state),
		init = { senderEmail: "", contactEmail: "", sendgridKey: "" },
		[state, setState] = useState(init),
		dispatch = useDispatch(),
		textChange = e => {
			let { name, value } = e.target;
			setState({ ...state, [name]: value });
		},
		handleSubmit = async e => {
			e?.preventDefault();
			if (!state?.senderEmail) return;
			setLoading("update");
			try {
				let res = await axios.post(`/api/v1/settings`, { ...state });
				toast.success(res?.data?.msg);
				dispatch(setSettings(res?.data?.data));
			} catch (err) {
				if (err?.response?.status === 429 || err?.response?.status === 405)
					toast.error(err?.response?.data ? err?.response?.data : err?.message);
				console.log({ err });
				let error = err.response?.data?.data;
				if (error) {
					dispatch(returnErrors({ error, status: err?.response?.status }));
				}
			}
			setLoading(false);
		};

	useEffect(() => {
		setState(settings?.data);
	}, [settings?.data]);

	return (
		<div>
			<div className="grid md:grid-cols-2 gap-4 mb-3">
				<div>
					<p className="text-4xl font-light mb-4">{t(`Email Configuration`)}</p>
					<div>
						<Input
							placeholder={"ajaniben1234@gmail.com"}
							label={t("Sender Email")}
							type={"email"}
							value={state?.senderEmail}
							name="senderEmail"
							onChange={textChange}
						/>
						<Input
							placeholder={"ajaniben1234@gmail.com"}
							label={t("SendGrid API KEY")}
							type={"text"}
							value={state?.sendgridKey}
							name="sendgridKey"
							onChange={textChange}
						/>
						<Input
							placeholder={"ajaniben1234@gmail.com"}
							label={t("Contact Email")}
							type={"email"}
							value={state?.contactEmail}
							name="contactEmail"
							onChange={textChange}
						/>
					</div>
					<div className="mt-4 space-y-2 mb-8">
						<Buttons
							title={t("Save Configuration")}
							buttonType={"primary"}
							type="button"
							width={"w-fit me-2"}
							loading={loading === "update"}
							onClick={handleSubmit}
						/>{" "}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Config;
