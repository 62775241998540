const Brand = ({ height }) => {
  return (
    <img
      src={require("../../assets/logos/logo.png")}
      alt=""
      className={`${height || "h-16"}`}
    />
  );
};

export default Brand;
