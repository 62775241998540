import { useContext } from "react";
// import pat from "../assets/pattern.png";
import { GlobalState } from "../data/Context";

const TermsAndCondition = () => {
	let { t } = useContext(GlobalState);

	return (
		<div className="px-4">
			<header id="top" className="relative py-20 mb-10">
				{/* <div
          className="absolute inset-0 -top-20"
          style={{
            background: `url(${pat}) no-repeat`,
            backgroundSize: "cover",
          }}
        ></div> */}
				<div className="md:container mx-auto">
					<p className="text-4xl font-bold">{t(`Terms and Conditions`)}</p>
					<p className="text-lg">
						{t(
							`Welcome to FlashReview. Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using the FlashReview website ("Service") operated by FlashReview Inc ("us", "we", or "our").`
						)}
					</p>
				</div>
			</header>
			<div className="md:container mx-auto">
				<article className="mb-10">
					<h1 className="text-2xl font-semibold">{t(`Agreement to Terms`)}</h1>
					<p className="text-md">
						{t(
							`By accessing and using our Service, you agree to be bound by these Terms. If you disagree with any part of the terms, you may not access the Service.`
						)}
					</p>
				</article>
				<article className="mb-10">
					<h1 className="text-2xl font-semibold">{t(`Service Conditions`)}</h1>
					<p className="text-md">
						{t(
							`We reserve the right to withdraw or amend our Service, and any service or material we provide via the Service, in our sole discretion without notice. We will not be liable if for any reason all or any part of the Service is unavailable at any time or for any period.`
						)}
					</p>
				</article>
				<article className="mb-10">
					<h1 className="text-2xl font-semibold">{t(`User Accounts`)}</h1>
					<p className="text-md">
						{t(
							`When you create an account with us, you guarantee that you are above the age of 18 and that the information you provide us is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account.`
						)}
					</p>
				</article>
				<article className="mb-10">
					<h1 className="text-2xl font-semibold">
						{t(`Intellectual Property Rights`)}
					</h1>
					<p className="text-md">
						{t(
							`The Service and its original content, features, and functionality are and will remain the exclusive property of FlashReview Inc. The Service is protected by copyright, trademark, and other laws of both the United States and foreign countries.`
						)}
					</p>
				</article>
				<article className="mb-10">
					<h1 className="text-2xl font-semibold">{t(`Reviews`)}</h1>
					<p className="text-md">
						{t(
							`FlashReview provides a platform for businesses and their customers to gather and view reviews. FlashReview does not endorse, support, represent, or guarantee the completeness, truthfulness, accuracy, or reliability of any reviews posted via the Service. You understand that by using the Service, you may be exposed to content that might be offensive, harmful, inaccurate, or otherwise inappropriate.`
						)}
					</p>
				</article>
				<article className="mb-10">
					<h1 className="text-2xl font-semibold">
						{t(`Links to Other Websites`)}
					</h1>
					<p className="text-md">
						{t(
							`Our Service may contain links to third-party web sites or services that are not owned or controlled by FlashReview Inc. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party websites or services.`
						)}
					</p>
				</article>
				<article className="mb-10">
					<h1 className="text-2xl font-semibold">
						{" "}
						{t(`Changes to the Terms`)}
					</h1>
					<p className="text-md">
						{t(
							`We reserve the right to modify or replace these Terms at any time. We will try to provide at least 30 days' notice prior to any new terms taking effect.`
						)}
					</p>
				</article>
				<article className="mb-10">
					<h1 className="text-2xl font-semibold">
						{t(`Online Privacy Policy Only`)}
					</h1>
					<p className="text-md">
						{t(
							`This privacy policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in FlashReview. This policy is not applicable to any information collected offline or via channels other than this website.`
						)}
					</p>
				</article>
				<article className="mb-10">
					<h1 className="text-2xl font-semibold">{t(`Refund`)}</h1>
					<p className="text-md">
						{t(
							`All sales are final, and the Company does not offer any money-back guarantees. You recognize and agree that you shall not be entitled to a refund for any purchase under any circumstances.`
						)}
					</p>
				</article>
				<article className="mb-20">
					<h1 className="text-2xl font-semibold">{t(`Contact Us`)}</h1>
					<p className="text-md">
						{t(
							`If you have any questions about these Terms, please contact us at`
						)}{" "}
						flashreview@outlook.com.
					</p>
				</article>
				<article className="mb-10">
					<h1 className="text-xl font-semibold">{t(`Disclaimer`)}</h1>
					<p className="text-md">
						{t(
							`Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE" basis.`
						)}
					</p>
				</article>
				<article className="mb-10">
					<h1 className="text-xl font-semibold">{t(`Governing Law`)}</h1>
					<p className="text-md">
						{t(
							`These Terms shall be governed and construed in accordance with the laws of Canada, without regard to its conflict of law provisions.`
						)}
					</p>
				</article>
				<p className="text-md">FlashReview Inc.</p>
			</div>
		</div>
	);
};

export default TermsAndCondition;
