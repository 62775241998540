import { useNavigate, useLocation, Link } from "react-router-dom";
import pat from "../assets/pattern.png";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { returnErrors } from "../data/Reducers/ErrorReducer";
import { Buttons, OtpComponent } from "../components/ModalComponents";
import Brand from "../components/brand/brand";
import { GlobalState } from "../data/Context";

const Register = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [formValue, setFormValue] = useState(""),
		[loading, setLoading] = useState(false),
		location = useLocation();

	let { t } = useContext(GlobalState);

	const handleSubmit = async e => {
		e?.preventDefault();
		if (!formValue) return;
		setLoading(true);
		try {
			let res = await axios.post(`/api/v1/user/otp`, { otp: formValue });

			setLoading(false);
			toast.success(res?.data?.msg);
			navigate(location?.state?.email ? `/checkout` : "/login", {
				state: location?.state,
			});
		} catch (err) {
			if (err) console.log(err.response?.data?.data, { err });
			if (err?.response?.status === 429) toast.error(err?.response?.data);
			let error = err.response?.data?.data;
			dispatch(returnErrors({ error, status: err?.response?.status }));
			setLoading(false);
		}
		setLoading(false);
	};

	useEffect(() => {
		if (formValue && formValue?.length === 5) handleSubmit();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formValue]);

	return (
		<ReuseBox>
			<div className="max-w-md px-10 mt-16">
				<div className="bg-main text-white p-2 py-4 mb-4 rounded-md flex items-center justify-between">
					{location?.state?.email && (
						<p className="flex items-center gap-2 text-xs opacity-50">
							<span>
								<BsFillCheckCircleFill />
							</span>{" "}
							{t(`Password Creation`)}
						</p>
					)}
					<HiOutlineArrowNarrowRight width={"50px"} />
					<p className={`flex items-center gap-2 text-xs`}>
						<span>
							<BsFillCheckCircleFill />
						</span>{" "}
						{t("Otp Verification")}
					</p>
				</div>
				<p className="capitalize text-4xl font-thin mb-4">{t(`hello!`)}</p>
				<div>
					<p className="text-4xl font-extrabold mb-4">{t(`Verify mail`)}</p>
					{location?.state?.email && (
						<p className="text-sm text-main">
							{t(`We've sent a verification mail to`)} {location?.state?.email}.
							<span>{t(`Please check your inbox or promotion folder`)}</span>
						</p>
					)}
					<form>
						<VerifyMail
							code={formValue}
							setCode={setFormValue}
							handleSubmit={handleSubmit}
							text={t("confirm OTP")}
							numInputs={5}
							loading2={loading}
						/>
						{/* <!-- Submit button --> */}
						<div className="space-y-4">
							<Buttons
								type="submit"
								css="inline-block w-full rounded bg-[#65D133] px-7 pb-2.5 pt-3 text-sm font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-main hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-main focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-main active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
								data-te-ripple-init
								data-te-ripple-color="light"
								title={"Next"}
								onClick={handleSubmit}
								loading={loading}
							/>
							<button
								type="button"
								class="inline-block w-full rounded border border-main px-7 pb-2.5 pt-3 text-sm font-medium uppercase leading-normal text-main shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-main hover:text-white hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-main focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-main active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
								data-te-ripple-init
								data-te-ripple-color="light"
								onClick={() => navigate("/login", { state: location?.state })}>
								{t(`Login`)}
							</button>
						</div>
					</form>
				</div>
			</div>
		</ReuseBox>
	);
};

export default Register;

export const ReuseBox = ({ children }) => {
	return (
		<div className="relative gradient">
			<img
				src={require("../assets/top-circle.png")}
				alt=""
				className="absolute left-0 top-0 h-20"
			/>
			<img
				src={require("../assets/bottom-circles.png")}
				alt=""
				className="absolute left-0 bottom-0 h-20"
			/>
			<div
				className="absolute inset-0 -top-20"
				style={{
					background: `url(${pat}) no-repeat`,
					backgroundSize: "cover",
				}}></div>
			<div className="grid md:grid-cols-2 h-screen relative z-10">
				<div className="justify-center p-8">
					<Link to={"/"}>
						<Brand />
					</Link>
					<img
						src={require("../assets/login.png")}
						alt=""
						className="hidden md:inline-block md:h-[400px] mx-auto mt-8"
					/>
				</div>
				<div className="md:h-full md:w-full bg-white shadow-lg rounded-t-2xl  md:rounded-tr-none md:rounded-l-2xl flex items-center py-8 overflow-auto">
					{children}
				</div>
			</div>
		</div>
	);
};

export const VerifyMail = ({ code, setCode, loading2, numInputs }) => {
	let { t } = useContext(GlobalState);

	return (
		<>
			<div className="flex flex-col my-5">
				<small className="mb-4 d-block">
					{t(`Enter the OTP sent to your email`)}
				</small>
				<OtpComponent
					stateData={code}
					textChange={data => {
						setCode(data);
					}}
					css="borderColor"
					loading={loading2}
					numInputs={numInputs}
				/>
			</div>
		</>
	);
};
