import { Link, useLocation, useNavigate } from "react-router-dom";
import Brand from "../brand/brand";

import { FaAlignLeft } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";
import { useContext, useState } from "react";

import navBg from "../../assets/nav-bg.png";
import pattern from "../../assets/pattern.png";
import Button from "../button/button";

import ScrollIntoView from "react-scroll-into-view";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { fetchMachineTranslation } from "../../i18n";
import { GlobalState } from "../../data/Context";
import CountdownList from "../countdown/countdownList";

const routes = [
  { name: "Home", url: "#home" },
  { name: "Our Services", url: "#our-services" },
  { name: "How it works", url: "#how-it-works" },
  { name: "Contact Us", url: "#contact" },
];

const Nav = () => {
  let { lang, setLang, t, i18n } = useContext(GlobalState);
  const location = useLocation();
  const navigate = useNavigate();
  const [nav, setNav] = useState(false);
  const [promo, setPromo] = useState(true);

  const toggleNav = () => {
    setNav(!nav);
  };

  const handleLanguageChange = (e) => {
    i18n.changeLanguage(e.target.value);
    setLang(e.target.value);
  };

  return (
    <div>
      {/* {promo && (
        <div
          className="relative flex items-center p-2 text-white justify-center flex-col gap-1  bg-[#416132]"
          onClick={() => setPromo(false)}
        >
          <p className="text-center mx-6">
            <span className="font-bold">{t("promo")}</span>{" "}
            <span>{t("promo2")}</span>
          </p>
          <p className="flex items-center gap-2">
            <span className="ml-4 text-4xl">⏰</span> <CountdownList />
          </p>
          <span className="absolute top-2 right-4 cursor-pointer">
            <IoCloseSharp size={"20px"} />
          </span>
        </div>
      )} */}
      <nav className="absolute inset-x-0 z-50">
        {location?.pathname !== "/home" && (
          <div className="absolute inset-0"></div>
        )}
        <div className="md:container px-4 md:px-0 mx-auto flex justify-between items-center py-4 relative z-10">
          <div className="logo">
            <Link to={"/"}>
              <Brand />
            </Link>
          </div>
          <div className="links text-white lg:flex gap-x-8 hidden items-center justify-center flex-wrap ">
            {routes.map((route) => (
              <ScrollIntoView
                selector={route.url}
                onClick={() => {
                  location.pathname === "/terms-and-condition" && navigate("/");
                  toggleNav();
                }}
              >
                <span className="cursor-pointer">{t(route.name)}</span>
              </ScrollIntoView>
            ))}
          </div>
          <div className="flex items-center gap-8">
            <div className="action md:flex gap-4 hidden">
              <Button
                buttonType={"secondary"}
                children={"Login"}
                onClick={() => {
                  navigate("/login");
                }}
              />
              <Button
                buttonType={"white"}
                children={"Register"}
                onClick={() => {
                  navigate("/register");
                }}
              />
            </div>
            <div className="flex gap-4 items-center">
              {/* <div className="flex center flex-row-revers text-main font-medium overflow-y-hidden gap-x-2 px-4 rounded-lg py-1 bg-white">
                <p>lang</p>
                <div id="google_translate_element"></div>
              </div> */}
              <select
                name="lang"
                value={lang}
                className="rounded-md text-main"
                onChange={handleLanguageChange}
              >
                <option value="en">En</option>
                <option value="fr">Fr</option>
              </select>
              <span
                className={`lg:hidden ${nav && "hidden"} text-white`}
                onClick={toggleNav}
              >
                <FaAlignLeft size={"20px"} />
              </span>
            </div>
          </div>
        </div>
      </nav>
      {/* mobile nav */}
      <aside
        id="logo-sidebar"
        className={`fixed top-0 left-0 lg:hidden z-50 w-full h-screen transition-transform bg-main  ${
          nav ? "translate-x-0" : "-translate-x-full lg:translate-x-0"
        }`}
        aria-label="Sidebar"
      >
        <div class="h-full px-3 py-4 overflow-y-auto">
          <ul className="space-y-2 font-medium">
            <li className="mb-8 lg:hidden flex items-center justify-between">
              <Link to={"/"}>
                <Brand />
              </Link>
              <div className="flex items-center gap-4">
                <select
                  name="lang"
                  value={lang}
                  className="rounded-md text-main"
                  onChange={handleLanguageChange}
                >
                  <option value="en">En</option>
                  <option value="fr">Fr</option>
                </select>
                <div className="text-white mr-8" onClick={toggleNav}>
                  <FaAlignLeft size={"20px"} />
                </div>
              </div>
            </li>
            {routes.map((link) => (
              <ScrollIntoView
                selector={link.url}
                onClick={() => {
                  location.pathname === "/terms-and-condition" && navigate("/");
                  toggleNav();
                }}
              >
                <li className="block py-8 pl-3 pr-4 mb-8 text-white text-4xl rounded md:bg-transparent decoration-transparent md:p-0 ">
                  {link.name}
                </li>
              </ScrollIntoView>
            ))}
            <li>
              <div className="action mt-10 grid gap-4 md:hidden w-52">
                <Button
                  buttonType={"secondary"}
                  children={"Login"}
                  onClick={() => {
                    navigate("/login");
                  }}
                />
                <Button
                  buttonType={"white"}
                  children={"Register"}
                  onClick={() => {
                    navigate("/register");
                  }}
                />
              </div>
            </li>
          </ul>
        </div>
      </aside>
    </div>
  );
};

export default Nav;
