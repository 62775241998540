import AnimaionLayout from "../animeLayout/animeLayout";

import { BsArrowDownCircle, BsArrowUpCircle } from "react-icons/bs";

import { useContext, useState } from "react";
import { GlobalState } from "../../data/Context";

const Questions = [
  {
    question: "q1",
    answer:
      "a1",
    open: false,
  },
  {
    question: "q2",
    answer:
      "a2",
    open: false,
  },
  {
    question: "q3",
    answer:
      "a3",
    open: false,
  },
  {
    question: "q4",
    answer:
      "a4",
    open: false,
  },
  {
    question: "q5",
    answer:
      "a5",
    open: false,
  },
  {
    question: "q6",
    // answer:
    //   "a6",
    answer:
      "a7",
    open: false,
  },
];
const FAQ = () => {
  let { lang, t } = useContext(GlobalState);

  const FAQItem = ({ question, answer, toggleOpen, isOpen }) => {
    return (
      <div id="contact" className="border-b-2-border-[#12155A] my-2 tw-p-4">
        <div className="flex items-center justify-between">
          <h2 className="my-2 font-semibold text-white text-2xl">{question}</h2>
          <span
            onClick={() => {
              // setIsOpen(false);
              toggleOpen();
            }}
            className="cursor-pointer text-white"
          >
            {isOpen ? <BsArrowUpCircle /> : <BsArrowDownCircle />}
          </span>
        </div>
        {isOpen && (
          <p className=" my-3 font-light text-white text-lg">{answer}</p>
        )}
      </div>
    );
  };

  let [quest, setQuest] = useState(Questions);
  const toggleOpen = (index) => {
    let newArray = quest.map((question, i) => {
      if (index === i) {
        return { ...question, open: !question.open };
      } else {
        return { ...question, open: false };
      }
    });
    setQuest(newArray);
  };

  return (
    <AnimaionLayout className="">
      <p className="text-5xl font-bold text-center text-white">
        {t(`faq`)}
        {/*<span className="text-5xl">{t(`Questions`)}</span>*/}
      </p>
      <p className="text-center text-xl my-3 font-light text-white">
        {t(`faq subtext`)}
      </p>
      <section className="flex  relative max-w-4xl r mx-auto flex-col">
        {/* <span className="block bg-[#211F63] top-48  -translate-y-1/2  -left-10 border-0  rounded-full absolute -z-10 h-64 w-64"></span> */}
        {quest.map((faq, index) => (
          <FAQItem
            key={index}
            question={t(faq.question)}
            answer={t(faq.answer)}
            toggleOpen={() => toggleOpen(index)}
            isOpen={faq.open}
          />
        ))}
      </section>
    </AnimaionLayout>
  );
};

export default FAQ;
